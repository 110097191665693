import { createAsyncThunk } from '@reduxjs/toolkit';

export const verifyReservation = createAsyncThunk(
    'public/reservations',
    async (payload, thunkAPI,) => {

        const body = JSON.stringify(payload);
        console.log("contact asdf=====>" + body);

        try {
            const res = await fetch('/api/public/reservations', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                return data;

            } else {
                console.log("public/reservations != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("public/reservations Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);