import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const ReservationTimeSelectorFromTo = ({timeFrame, setTimeFrame, okClicked, bckButton}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const internalData = {
      time_from:"12:00",
      time_to:"13:00"
    };

    const [timeData, setTimeData] = useState(internalData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
      setTimeFrame(internalData);

  }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTimeData((prevData) => ({
            ...prevData,
            [name]: value}));
    };

    const validateForm = () => {
      let tempErrors = {};
      if (!timeData.time_to.trim()) tempErrors.time_to = `${t('error_time_to_is_obligatory')}`;
      if (!timeData.time_from.trim()) tempErrors.time_from = `${t('error_time_from_is_obligatory')}`;
  
      // Check if time_to is less than time_from
      //if (timeData.time_from && timeData.time_to && timeData.time_to <= timeData.time_from) {
      //    tempErrors.time_to = `${t('error_time_to_less_than_time_from')}`;
      //}
  
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
  };
  

    const handleSelectedTime = () => {
      if(!validateForm()) return;

      setTimeFrame(timeData);
      console.log(JSON.stringify(timeData));
      okClicked();
    }


    return (<>
      <div className="display">{t('time_of_reservation')}</div>
        <div className="number-pad" style={{marginLeft: "130px"}}> 
            <span style={{ display: 'block', width: '260px' }}></span>
            <div style={{maxWidth: "250px", marginLeft: "-175px"}}>
                <div>
                    <span style={{ display: 'block', height: '30px' }}></span>
                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="time_from">{t('vrijeme_od')}</label>
                        <input
                            className="contact-input"
                            type="time"
                            id="time_from"
                            name="time_from"
                            value={timeData.time_from}
                            onChange={handleChange}
                        />
                        {errors.time_from && <div className="contact-validation-message">{errors.time_from}</div>}
                    </div>
                    <span style={{ display: 'block', height: '10px' }}></span>
                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="time_to">{t('vrijeme_do')}</label>
                        <input
                            className="contact-input"
                            type="time"
                            id="time_to"
                            name="time_to"
                            value={timeData.time_to}
                            onChange={handleChange}
                        />
                        {errors.time_to && <div className="contact-validation-message">{errors.time_to}</div>}
                    </div>  
                    <p style={{marginLeft: "30px", textAlign: "center"}}>{t('timespan_text')}</p>                  
                </div>
                <div>
                    <span style={{ display: 'block', height: '10px' }}></span>
                    <div className="number-pad" style={{maxWidth: "250px", marginLeft: "30px"}}>
                        <button
                            id="C"
                            className="number-button"
                            onClick={bckButton}
                        >
                            {'<'}
                        </button>   

                        <button
                            id="OK"
                            className="number-button-ok"
                            onClick={handleSelectedTime}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>);
};

export default ReservationTimeSelectorFromTo;
