import { createAsyncThunk } from '@reduxjs/toolkit';

export const roomsPatch = createAsyncThunk(
    'reservations/rooms/patch/',
    async (payload, { rejectWithValue },) => {

        const formData = new FormData();
        formData.append('floor_plan', payload.floor_plan); // Directly append the file object

        const { id, company_id } = payload;

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const res = await fetch(`/api/reservations/rooms/patch/${id}/${company_id}/`, {
                method: 'PATCH',
                body: formData,
            });

            if (!res.ok) throw new Error('Network response was not ok');
            return await res.json()

        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);