
import { useDispatch, useSelector } from 'react-redux';
import { ticketLogView } from "features/ticketSys/TicketLogView";

import { useTranslation } from 'react-i18next';

const TicketsDisplayTable = ({ inData, date }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { permissions } = useSelector(state => state.permissions); //permissions.user -> user id === id


  
  const handleRefresh = () =>{
    const payload = {
      company_id: permissions.company,
      startDate: date,
      endDate: date,
     };
     
     dispatch(ticketLogView(payload));
  };

  if (!inData || inData.length === 0) {
    return (
    <div>
      <p>{t('no_data_to_sohw')}</p>
      <button className="btn_matrix" onClick={() => handleRefresh()} style={{ height: '20px', paddingTop: '0px', float: 'left', fontSize: '12px', width: '200px' }}>{t('refresh_list')}</button>
  
    </div>
    )
  }
    


  return (
    <div>
      <h5>Poništene ulaznice</h5>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
      <button className="btn_matrix" onClick={() => handleRefresh()} style={{ height: '20px', paddingTop: '0px', float: 'left', fontSize: '12px', width: '200px' }}>{t('refresh_list')}</button>
      <span style={{ display: 'block', height: '20px' }}></span>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
          <thead style={{ fontSize: '13px', backgroundColor: '#f2f2f2' }}>
            <tr>
              <th>Rb.</th>
              <th>Br. karte</th>
              <th>Ime i prezime</th>
              <th>Kartu poništio</th>
              <th>Karta ostalo</th>
              <th>Karta ukupno</th>
              <th>Datum</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '13px' }}>
            {inData.map((user, index) => (
              <tr key={user.id} 
                style={{
                  cursor: 'pointer',
                  border: '1px solid #ddd',
                  backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff'
                }}
              >
                <td>{index +1}</td>
                <td>{user.id_ticket}</td>
                <td>{user.contact_name}</td>
                <td>{user.user_full_name}</td>
                <td>{Number(user.tickets_count) - Number(user.tickets_verified)}</td>
                <td>{user.tickets_count}</td>
                <td>{user.formatted_timestamp}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <span style={{ display: 'block', height: '20px' }}></span>
    </div>
  );
};

export default TicketsDisplayTable;
