import { createAsyncThunk } from '@reduxjs/toolkit';

//NE KORISTI SE, KORISTIMO ONU IZ USERS
export const registerCompany = createAsyncThunk(
    'reservations/register',
    async ({ company_id, company_name }, thunkAPI,) => {
        const body = JSON.stringify({
            company_id,
            company_name,
        });

        try {
            const res = await fetch('/api/reservations/register', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                return data;
            } else {
                console.log("registerCompany != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("registerCompany Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);