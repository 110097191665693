import { createAsyncThunk } from '@reduxjs/toolkit';

export const roomsCreate = createAsyncThunk(
    'reservations/rooms/',
    async (payload, { rejectWithValue }) => {

        const formData = new FormData();
        formData.append('company_id', payload.company_id);
        formData.append('order', payload.order);
        formData.append('name', payload.name);
        formData.append('floor_plan', payload.floor_plan); // Directly append the file object

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const response = await fetch('/api/reservations/rooms/', {
                method: 'POST',
                body: formData, // Send formData directly
                // Do not set Content-Type header, let the browser set it with the correct boundary
            });

            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
