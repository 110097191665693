import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "features/user";
import logo from "./6.svg";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const Navbar = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    //U storu se zove user, pa se zato zove state.user
    const { isAuthenticated } = useSelector(state => state.user);
    const { user } = useSelector(state => state.user);
    const { company } = useSelector(state => state.user);
    const { permissions } = useSelector(state => state.permissions)

    // Construct user's name
    var name = 'Dashboard';
    // Check if user exists and has first_name and last_name
    if (user && user.first_name) {
        name = `${user.first_name}`;
    }

    const companyLinks = (
        <>{permissions ? <>
            {permissions.receptionist ? <>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/receptions">
                        {t('reception')}
                    </NavLink>
                </li></> : <></>}

            {permissions.manager ? <>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/reservations">
                        {t('reservations')}
                    </NavLink>
                </li></> : <></>}

            {permissions.gatekeeper ? <>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/tickets">
                        {t('tickets')}
                    </NavLink>
                </li></> : <></>}
        </>
            :
            <> </>
        }
        </>
    );

    const bizz = (
        <li className="nav-item">
            <NavLink className="nav-link" to="/bizz">
                {t('business')}
            </NavLink>
        </li>
    );

    const authLinks = (
        <>
            {companyLinks}
            {company && company.active ? (<>{bizz}</>) : (<></>)}
            <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard">
                    {name}
                </NavLink>
            </li>

            <li className="nav-item">
                <NavLink className="nav-link" to="/" onClick={() => dispatch(logout())}>
                    {t('log_off')}
                </NavLink>
            </li>
        </>
    );

    //inace cvili da mora imat parent tagove
    const guestLinks = (
        <>
            <li className="nav-item">
                <NavLink className="nav-link" to="/login">
                    {t('login')}
                </NavLink>
            </li>

            <li className="nav-item">
                <NavLink className="nav-link" to="/register">
                    {t('register')}
                </NavLink>
            </li>
        </>

    );

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="white" style={{ position: 'fixed', top: '0', left: '0', width: '100%', zIndex: '1000', backgroundColor: '#21254D' }}>
            <div className="container-fluid">
                <img
                    src={logo}
                    style={{
                        width: "200px",
                        height: "auto",
                        borderRadius: '10px',
                        border: "2 solid black",
                        marginLeft: "100px"
                    }}
                />
                {/*<Link className="navbar-brand" to="/">Sonex</Link>*/}
                <button className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                                {t('frontpage')}
                            </NavLink>
                        </li>
                        {isAuthenticated ? authLinks : guestLinks}

                        <LanguageSwitcher />

                    </ul>
                </div>
            </div>
        </nav>
    );

};

export default Navbar;