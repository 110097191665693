import Layout from "components/Layout";
import React from 'react';
import '../App.css';

import logo from "../2.png";
import { useTranslation } from 'react-i18next';


const Privacy = () => {
    const { t } = useTranslation();



    return (
        <Layout title="Sonex | Safety" content="Home page">
            <div className="container" style={{ paddingTop: '50px' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img src={logo}
                                        style={{
                                            maxWidth: "220px",
                                            height: "auto",
                                            borderRadius: '10px',
                                        }}
                                        alt="Logo"
                                    />
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <p>
                                        <ul>
                                            <li>{t('frontpage_text_1')}</li>

                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray", minHeight: "600px" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>Izjava o privatnosti</h3>

                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>

                                    <p style={{ marginTop: "20px" }}>
                                        Sonexapp obavezuje se pružati zaštitu osobnim podacima kupaca, na način da
                                        prikuplja samo nužne, osnovne podatke o kupcima/ korisnicima koji su nužni za ispunjenje naših
                                        obveza; informira kupce o načinu korištenja prikupljenih podataka, daje kupcima
                                        mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se
                                        njihovo ime ukloni s lista koje se koriste za marketinške kampanje.</p><p>
                                        Svi se podaci o korisnicima
                                        strogo čuvaju i dostupni su samo djelatnicima kojima su ti podaci nužni za obavljanje posla. Svi
                                        djelatnici Sonexapp-a i poslovni partneri odgovorni su za poštivanje načela
                                        zaštite privatnosti.
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div >
        </Layout >
    );
};

export default Privacy;
