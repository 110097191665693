import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const tableViewEvent = createAsyncThunk(
    'company/tables/show/events/',
    async (payload, thunkAPI,) => {

        const { company_id, id } = payload;

        try {
            const res = await fetch(`/api/company/tables/show/events/${company_id}/${id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("tableViewEvent != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("tableViewEvent Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);