export const sidebarConfig = [
    {
        title: "reservations",
        items: [
            { name: "reservations_overview", component: "ReservationsOverview" },
            { name: "place_table_for_reservations", component: "AuthorizeReservations" },
            { name: "confirm_reservations", component: "ConfirmReservations" },
            { name: "send_reservation_request", component: "ConfirmAddReservations" },
            //{ name: "Pregled gostiju", component: "GuestOverview" },
        ],
    },
    {
        title: "events_",
        items: [
            { name: "events_overview", component: "EventsOverview" },
            //{ name: "Dodaj događaj", component: "AddEvent" },
        ],
    },
    //{
    //    title: "Stolovi",
    //    items: [
    //        { name: "Pregled rasporeda stolova", component: "TablesLayoutOverview" },
    //        { name: "Uredi raspored stolova", component: "EditTablesLayout" },
    //        { name: "Uredi stolove", component: "EditTables" },
    //    ],
    //},
    {
        //title: "menus",
        title: "products_and_services",
        items: [
            { name: "menu_overview", component: "MenusOverview" },
            { name: "create_menu", component: "EditMenu" },
        ],
    },
    //{
    //    title: "Javna stranica",
    //    items: [
    //        { name: "Pregled", component: "PublicPageOverview" },
    //        { name: "Uredi javnu stranicu", component: "EditPublicPage" },
    //    ],
    //},
    {
        title: "settings",
        items: [
            { name: "reservations_settings", component: "EditDailySetup" },
            { name: "public_card_settings", component: "PublicCardSetup" },
            { name: "kpmg_tests", component: "KPMGTests" },
        ],
    },

    {
        title: "employees_and_members",
        items: [
            { name: "schedule", component: "EmployeeScheduler" },
            { name: "employee_list", component: "EmployeeManagmet" },
            { name: "members", component: "MembersManagment" },
        ],
    }
];