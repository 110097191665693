import Layout from "components/Layout";
import React, { useState } from 'react';
import NumberPad from "../NumberPad";
import { useTranslation } from 'react-i18next';


const GlassPanelWithChild = ({ formData,  child}) => {
  const { t } = useTranslation();
    return (    
        <>
        <div className="gp_container">
              <div className="gp_box">
                <h1 className="gp_title">{formData?.name ?? ""}</h1>
                <h1 className="gp_sub_title">{formData?.about_us ?? ""}</h1>
                <h1 className="gp_sub_title">{t('reservation_uppercase')}</h1>
                <div className="gp_content" >
                  {/*<NumberPad okClicked={okClicked}/>*/}
                  {child}
                </div>
                <div>
                  <strong>{formData?.selected_date ?? ""}</strong>
                  <p>{formData?.name ?? ""} - {formData?.about_us ?? ""}</p>
                  <span>{t('address_uppercase')}:</span> <span>{formData?.address ?? ""}</span>
                </div>
              </div>
            </div>
        </>
    );
};

export default GlassPanelWithChild;