import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { permissionsUserRemove } from 'features/permissionsSys/PermissionsUserRemove';
import { permissionsUsersPatch } from 'features/permissionsSys/PermissionsUsersPatch';
import { useTranslation } from 'react-i18next';


const PermissionsDisplayTable = ({ inData, outData, setOutData }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showSaveButton, setShowSaveButton] = useState(false);
  const { permissions } = useSelector(state => state.permissions); //permissions.user -> user id === id

  // Function to check differences between inData and outData
  const checkDifferences = () => {
    if (!outData) {
      return false;
    }

    return outData.some((item, index) => {
      const original = inData[index];
      return item.is_manager !== original.is_manager || 
             item.is_receptionist !== original.is_receptionist || 
             item.is_gatekeeper !== original.is_gatekeeper;
    });
  };

  useEffect(() => {
    // Update the visibility of the save button based on differences
    setShowSaveButton(checkDifferences());
  }, [outData, inData]);

  const handleChange = (id, field) => event => {
    const updatedOutData = outData.map(item => {
      if (item.id === id) {
        return { ...item, [field]: event.target.checked };
      }
      return item;
    });
    setOutData(updatedOutData);
  };

  const handleDelete = id => {

    if(permissions.user === id) return;

    const item = outData.find(item => item.id === id);

    const payload = {
      id: item.whitelist_id,
      company_id: permissions.company,
    };

    dispatch(permissionsUserRemove(payload));

    const updatedOutData = outData.filter(item => item.id !== id);
    setOutData(updatedOutData);
  };

  const handleSave = () => {
    outData.forEach((item, index) => {
      const original = inData[index];
      if (item.is_manager !== original.is_manager || 
          item.is_receptionist !== original.is_receptionist || 
          item.is_gatekeeper !== original.is_gatekeeper) {
            
        // Prepare payload for patching
        const payload = {
          id: item.whitelist_id,
          company: permissions.company,
          receptionist: item.is_receptionist,
          gatekeeper: item.is_gatekeeper,
        };

        console.log("item: " + JSON.stringify(payload))
        // Dispatch the patch action
        dispatch(permissionsUsersPatch(payload));
      }
    });
  };

  if (!outData || outData.length === 0) {
    return <p>No data available.</p>;
  }

  return (
    <div>
      <h5>{t('users_and_permissions_label')}</h5>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
          <thead style={{ fontSize: '13px' }}>
            <tr>
              <th>{t('id')}</th>
              <th>{t('name_and_surname')}</th>
              <th>{t('manager')}</th>
              <th>{t('reception')}</th>
              <th>{t('reception')}</th>
              <th>{t('delete')}</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '13px' }}>
            {outData.map((user, index) => (
              <tr key={user.id} 
                style={{
                  cursor: 'pointer',
                  borderTop: '1px solid #ddd',
                  backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff'
                }}
              >
                <td>{user.unique_name}</td>
                <td>{user.first_name} {user.last_name}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.is_manager}
                    disabled={true}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.is_receptionist}
                    onChange={handleChange(user.id, 'is_receptionist')}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.is_gatekeeper}
                    onChange={handleChange(user.id, 'is_gatekeeper')}
                  />
                </td>
                <td>
                  <button className="delete-button" onClick={() => handleDelete(user.id)} style={{ color: 'red', cursor: 'pointer' }}>{t('delete')}</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <span style={{ display: 'block', height: '20px' }}></span>
      {showSaveButton && (
        <button className="btn_matrix" onClick={() => handleSave()} style={{ height: '20px', paddingTop: '0px', float: 'left', fontSize: '12px', width: '200px' }}>{t('save_changes')}</button>
      )}
      <span style={{ display: 'block', height: '20px' }}></span>
    </div>
  );
};

export default PermissionsDisplayTable;
