import { createAsyncThunk } from '@reduxjs/toolkit';

export const userRequestViewForDate = createAsyncThunk(
    'company/userrequest/fordate/',
    async (payload, thunkAPI) => {

        const { company_id, date } = payload;
        console.log("payload=====>" + JSON.stringify(payload));


        try {
            const res = await fetch(`/api/company/userrequest/fordate/${company_id}/${date}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("userRequestViewForDate != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("userRequestViewForDate Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);