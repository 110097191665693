import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const ReservationUserVerify = ({info, sendReservationRequest, showButton=true, showCancelTicket=false, ticketText, cancelTicketMethod}) => {

  const { t } = useTranslation();
  
  const finalInfo = {
    nameAndSurname: "",
    gsm: "",
    peopleCount: "",
    menu: "",
    timeSpan: "",
    onDate: "",
  };

  const [inputValue, setInputValue] = useState(info? info : finalInfo);


    // Update inputValue when selectedMenu changes
    //useEffect(() => {
    //  setInputValue(selectedMenu ? selectedMenu.subtitle2 : "Odaberite Menu");
    //}, [selectedMenu]);

    const showButtonFnk = () =>{

      if (showButton()){
        return (<>
                <button
                  id="show_menus"
                  className="time-frame-button"
                  onClick={sendReservationRequest}
                >
                 {t('confirm_reservation')}
                </button>
        </>)

      }else{
        
        return (<>{ showCancelTicket ? 
          (<>
              <button
                  id="show_menus"
                  className="time-frame-button"
                  onClick={cancelTicketMethod}
                >
                {t('cancel_ticket')}
              </button>
          </>) 
          :(<></>)}
          </>);
      }

    };


  return (
    <div className="number-pad">
      <span style={{ display: 'block', width: '100%' }}></span>
      <div className="display" style={{ textAlign: "left", fontSize: "18px", marginLeft: "-160px", fontSize: "15px", width: '100%' }}>
        <ul>
          <li>{inputValue.nameAndSurname}</li>
          <li>GSM: {inputValue.gsm}</li>
          <li>{t('person_count')}: {inputValue.peopleCount}</li>
          <li>Menu: {inputValue.menu}</li>
          <li>{t('on_date_label')} {inputValue.onDate}</li>
          <li>{inputValue.timeSpan}</li>
          <li>{ticketText}</li>
        </ul>
      </div>
      <span style={{ display: 'block', width: '260px' }}></span>
      <div className="row number-pad" style={{ display: "flex", alignItems:"center", justifyContent: "center", flexWrap: "nowrap", marginLeft: "-80px"}}>
        {showButtonFnk()}
      </div>
    </div>
  );
};

export default ReservationUserVerify;
