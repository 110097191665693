import Layout from "components/Layout";

const NoPage = () => {
    return (
        <Layout title='Sonex | 404' content='Not found'>
            <h1>404 Not found</h1>
        </Layout>
    );
};

export default NoPage;