import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetRegistered, login } from "features/user";
import { Navigate, Link } from "react-router-dom";
import Layout from "components/Layout";
import './App.css';
import { useTranslation } from 'react-i18next';


const LoginPage = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading, isAuthenticated, registered, userFound, loadingUser, invalid } = useSelector(state => state.user);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        if (registered && !invalid) {
            dispatch(resetRegistered());
        }

    }, [registered, invalid]);



    const { email, password } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

    };

    console.log("invalid: ", invalid)

    const onSubmit = e => {
        e.preventDefault();
        dispatch(login({ email, password }));

    };

    if (isAuthenticated && userFound && !invalid) {
        return <Navigate to='/dashboard' />;
    }

    return (
        <Layout title='Sonex | Login' content='Login page'>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '50px' }} >
                <div className="row col-lg-4 col-md-6 col-sm-12" >

                    <form className="form-control" style={{ boxShadow: "0px 0px 0px 0px" }}
                        action=""
                        onSubmit={onSubmit}>
                        <p className="title">{t('login')}</p>
                        <div className="input-field">
                            <input className="input"
                                type="email"
                                name="email"
                                onChange={onChange}
                                value={email}
                                required
                            />
                            <label className="label" htmlFor="input">{t('email_label')}</label>
                        </div>
                        <div className="input-field">
                            <input className="input"
                                type="password"
                                name="password"
                                autoComplete="current-password"
                                onChange={onChange}
                                value={password}
                                required
                            />
                            <label className="label" htmlFor="input">{t('password_label')}</label>
                        </div>
                        <Link to="/recovery" className="link" style={{ fontSize: "12px" }}>{t('forgotten_password_qm')}</Link>
                        {loading || loadingUser ?
                            (
                                <div className="spinner-border text-primary mt-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <button className="edit-button"> Log in </button>
                            )
                        }
                    </form>
                </div>
            </div>

            <div className="container" style={{ marginTop: "100px", marginBottom: "20px" }}>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        {/* This 'container' div centers the content and sets a max-width for large screens */}
                        <div className="row">
                            {/* Each column now takes up 4 of 12 possible columns on large screens, centered within the '.col-lg-8' container */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>{t('login_text_1')}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>{t('why_sonex_qm')}</h2>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2a')}</p>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2b')}</p>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2c')}</p>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2d')}</p>


                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>{t('login_text_3')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >
    );
};

export default LoginPage;