// src/App.js
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkAuth } from "features/user";

import HomePage from "containers/HomePage"; //Zbog realativne rute
import DashboardPage from "containers/DashboardPage";
import LoginPage from "containers/LoginPage";
import RegisterPage from "containers/RegisterPage";
import NoPage from "containers/NoPage";
import OverviewPage from "containers/OverviewPage";
import Reservations from "containers/Reservations";
import Reception from "containers/Reception";
import RequestReservationPage from "containers/RequestReservationPage";
import RequestReservationPageAlt from "containers/RequestReservationPageAlt";
import VerifyReservationPage from "containers/VerifyReservationPage";
import PasswordRecoveryRequest from "containers/PasswordRecoveryRequest";
import PasswordReset from "containers/PasswordReset";
import Tickets from "containers/Tickets";
import BizzPage from "containers/BizzPage";
import Safety from "containers/legals/Safety";
import Privacy from "containers/legals/Privacy";
import Terms from "containers/legals/Terms";
import Footer from './components/Footer';
import VerifyReservationPagePayments from "containers/VerifyReservationPagePayments";
import KpmgTestRegistration from "containers/KpmgTestRegistration";


import './containers/App.css'; // Import the CSS file
import './i18n'; // Import the i18n configuration

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkAuth());
    }, [dispatch]);

    return (
        <BrowserRouter>
            <div className="App" style={{ marginRight: "0px", paddingRight: "0px" }}>
                <main>
                    <Routes>
                        <Route path='/terms' element={<Terms />} />
                        <Route path='/payments' element={<Terms />} />
                        <Route path='/privacy' element={<Privacy />} />
                        <Route path='/safety' element={<Safety />} />
                        <Route path='/' element={<HomePage />} />
                        <Route path='/dashboard' element={<DashboardPage />} />
                        <Route path='/login' element={<LoginPage />} />
                        <Route path='/recovery' element={<PasswordRecoveryRequest />} />
                        <Route path='/recovery/:recovery_id/update' element={<PasswordReset />} />
                        <Route path='/register' element={<RegisterPage />} />
                        <Route path='/overview/:company_id' element={<OverviewPage />} />
                        <Route path='/*' element={<NoPage />} />
                        <Route path='/reservations' element={<Reservations />} />
                        <Route path='/receptions' element={<Reception />} />
                        <Route path='/request/:yyyymmdd/:companyName/:companyId' element={<RequestReservationPageAlt />} />
                        <Route path='/reservation-payment/verify/:cs/:companyName/:companyId/:resId' element={<VerifyReservationPagePayments />} />
                        <Route path='/reservation/verify/:cs/:companyName/:companyId/:resId' element={<VerifyReservationPage />} />
                        <Route path='/tickets' element={<Tickets />} />
                        <Route path='/bizz' element={<BizzPage />} />
                        <Route path='/kpmg/:companyId' element={<KpmgTestRegistration />} />
                    </Routes>
                </main>
                <Footer />
            </div>
        </BrowserRouter>
    );
};

export default App;
