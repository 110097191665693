import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const updateEvents = createAsyncThunk(
    'reservations/company/event/update/',
    async (payload, thunkAPI,) => {

        const { id, company_id, eventName, eventDescription, selectedDate, listOfTables } = payload;
        const body = JSON.stringify({
            "company_id": company_id,
            "eventName": eventName,
            "eventDescription": eventDescription,
            "selectedDate": selectedDate,
            "listOfTables": listOfTables,
        });


        try {
            const res = await fetch(`/api/reservations/company/event/update/${id}/`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("company/event/update != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("company/event/update Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);