import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateEventsPhotos = createAsyncThunk(
    'reservations/company/event/photo/update/',
    async (payload, { rejectWithValue }) => {

        const { id } = payload;

        const formData = new FormData();
        formData.append('company_id', payload.company_id);
        formData.append('eventName', payload.eventName);
        formData.append('eventDescription', payload.eventDescription);
        formData.append('selectedDate', payload.selectedDate);
        formData.append('listOfTables', payload.listOfTables);
        formData.append('eventImage', payload.eventImage); // Directly append the file object


        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const response = await fetch(`/api/reservations/company/event/photo/update/${id}/`, {
                method: 'PUT',
                body: formData, // Send formData directly
                // Do not set Content-Type header, let the browser set it with the correct boundary
            });

            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
