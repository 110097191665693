import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import corvus from '../../../src/components/logotypes/3_Logo_CorvusPay/corvus-logo-1x.png';


const ReservationUserVerifyPaymentsStep2 = ({info, sendReservationRequest, showButton=true, showCancelTicket=false, ticketText, cancelTicketMethod}) => {

  const { t } = useTranslation();
  
  const finalInfo = {
    nameAndSurname: "",
    gsm: "",
    peopleCount: "",
    menu: "",
    timeSpan: "",
    onDate: "",
  };

  const [inputValue, setInputValue] = useState(info? info : finalInfo);
  const [checkbox, setCheckbox] = useState(false);


    // Update inputValue when selectedMenu changes
    //useEffect(() => {
    //  setInputValue(selectedMenu ? selectedMenu.subtitle2 : "Odaberite Menu");
    //}, [selectedMenu]);

    const dummy = () => {
      window.open("https://www.corvuspay.com/", "_blank");
    };

    const showButtonFnk = () =>{

      if (showButton()){
        return (<>
                <button
                  id="show_menus"
                  className="time-frame-button"
                  onClick={dummy}
                  disabled = {!checkbox}
                >
                 {t('pay')}
                </button>
        </>)

      }else{
        
        return (<>{ showCancelTicket ? 
          (<>
              <button
                  id="show_menus"
                  className="time-frame-button"
                  onClick={cancelTicketMethod}
                >
                {t('cancel_ticket')}
              </button>
          </>) 
          :(<></>)}
          </>);
      }

    };


  return (
    <div className="number-pad">
      <span style={{ display: 'block', width: '100%' }}></span>
      <div className="display" style={{ textAlign: "left", fontSize: "18px", marginLeft: "-80px", fontSize: "12px", width: '300px' }}>
        <ul  style={{ borderBottom: '1px solid #ddd', padding: '8px', textAlign: 'left', marginTop: '0px' }}>
        <li style={{fontSize: "16px"}}>{t('za_platiti')}:</li>
          <li>{t('akontacija')}: 50,00 €</li>
          <li>{t('naknade')}: 2.00 €</li>
          <li style={{fontSize: "16px"}}>{t('total')}: 52.00 €</li>
        </ul>
        
      </div>
      <span style={{ display: 'block', width: '260px' }}></span>
      <div className="display" style={{ textAlign: "left", fontSize: "18px", marginLeft: "-80px", fontSize: "12px", width: '300px', marginTop: "0px" }}>
      
      <label style={{ top: "290px", position:"fixed"}} >{t('card_payments_provided_by')}:</label>
      <a href="https://www.corvuspay.com" target="_blank" rel="noopener noreferrer" style={{ width: "251px", height: "52px"}}>
          <img
              src={corvus}
              alt="CorvusPay logo"
              style={{
              width: "251px",
              height: "52px",

             }}
            />
        </a>
        <label style={{fontSize:"12px"}}className="side_pull_checkbox"> {t('user_confirmation')}
        <div className="checkbox">
              <input
                  className="tgl"
                  id="checkbox"
                  type="checkbox"
                  checked={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                  />
                <label className="tgl-btn" htmlFor='checkbox'></label>
          </div></label>
      </div>
      


                    
      <div className="row number-pad" style={{ display: "flex", alignItems:"center", justifyContent: "center", flexWrap: "nowrap", marginLeft: "-80px"}}>
        {showButtonFnk()}
      </div>
    </div>
  );
};

export default ReservationUserVerifyPaymentsStep2;
