import { createSlice } from '@reduxjs/toolkit';
import { permissionsView } from './permissionsSys/PermissionsView';
import { permissionsCoworkersView } from './permissionsSys/PermissionsCoworkersView';
import { permissionsGetUserByTag } from './permissionsSys/PermissionsGetUserByTag';
import { permissionsUserAdd } from './permissionsSys/PermissionsUserAdd';
import { permissionsUserRemove } from './permissionsSys/PermissionsUserRemove';
import { permissionsUsersPatch } from './permissionsSys/PermissionsUsersPatch';
import { passwordRequestReset } from './permissionsSys/PaswordRequestReset';
import { passwordResetExecute } from './permissionsSys/PasswordResetExecute';
import { roomsCreate } from './rooms/RoomsCreate';
import { roomsDelete } from './rooms/RoomsDelete';
import { roomsView } from './rooms/RoomsView';
import { roomsPatch } from './rooms/RoomsPatch';


const initialState = {
    permissions: null,
    coworkers: null,
    company: null,
    userByTag: null,
    notFound: false,
    loadingPermissions: false,
    changesPermissions: false,
    changesCount: 0,
    resetPasswordRequested: false,
    rooms: null,
    roomsLoad: false,
    roomsChange: false,
};

const permissionsSlice = createSlice({
    name: 'permissions', // Adjusted the name for clarity, change as needed
    initialState,
    reducers: {

        resetLoading: state => {
            state.loadingPermissions = false;
        },

        resetUserByTag: state => {
            state.userByTag = null;
        },
    },

    extraReducers: builder => {
        builder
            .addCase(permissionsView.pending, (state) => {
                state.loadingPermissions = true;
            })
            .addCase(permissionsView.fulfilled, (state, action) => {
                state.loadingPermissions = false;
                state.permissions = action.payload;
            })
            .addCase(permissionsView.rejected, (state) => {
                state.loadingPermissions = false;
            })
            .addCase(permissionsCoworkersView.pending, (state) => {
                state.loadingPermissions = true;
                state.changesPermissions = false; //dohvaca posljednje  izmjene za pregled
            })
            .addCase(permissionsCoworkersView.fulfilled, (state, action) => {
                state.loadingPermissions = false;
                state.coworkers = action.payload;
            })
            .addCase(permissionsCoworkersView.rejected, (state) => {
                state.loadingPermissions = false;
            })
            .addCase(permissionsGetUserByTag.pending, (state) => {
                state.loadingPermissions = true;
                state.notFound = false;
                state.userByTag = null
            })
            .addCase(permissionsGetUserByTag.fulfilled, (state, action) => {
                state.loadingPermissions = false;
                state.userByTag = action.payload;
            })
            .addCase(permissionsGetUserByTag.rejected, (state) => {
                state.loadingPermissions = false;
                state.notFound = true;
            })
            .addCase(permissionsUserAdd.pending, (state) => {
                state.loadingPermissions = true;
            })
            .addCase(permissionsUserAdd.fulfilled, (state, action) => {
                state.loadingPermissions = false;
                state.changesPermissions = true;
            })
            .addCase(permissionsUserAdd.rejected, (state) => {
                state.loadingPermissions = false;
            })
            .addCase(permissionsUserRemove.fulfilled, (state) => {
                state.changesPermissions = true;
            })
            .addCase(permissionsUsersPatch.pending, (state) => {
                state.changesCount = state.changesCount + 1;

            })
            .addCase(permissionsUsersPatch.fulfilled, (state) => {
                state.changesCount = state.changesCount - 1;
                if (state.changesCount === 0) state.changesPermissions = true;

            })
            .addCase(permissionsUsersPatch.rejected, (state) => {
                state.changesCount = state.changesCount - 1;
                if (state.changesCount === 0) state.changesPermissions = true;

            })
            .addCase(passwordRequestReset.pending, (state) => {
                state.loadingPermissions = true;

            })
            .addCase(passwordRequestReset.fulfilled, (state) => {
                state.loadingPermissions = false;
                state.resetPasswordRequested = true;
            })
            .addCase(passwordRequestReset.rejected, (state) => {
                state.loadingPermissions = false;
            })
            .addCase(passwordResetExecute.pending, (state) => {
                state.loadingPermissions = true;

            })
            .addCase(passwordResetExecute.fulfilled, (state) => {
                state.loadingPermissions = false;
                state.resetPasswordRequested = true;
            })
            .addCase(passwordResetExecute.rejected, (state) => {
                state.loadingPermissions = false;
            })


            .addCase(roomsView.pending, (state) => {
                state.roomsLoad = true;
                state.roomsChange = false;
            })
            .addCase(roomsView.fulfilled, (state, action) => {
                state.roomsLoad = false;
                state.rooms = action.payload;
            })
            .addCase(roomsView.rejected, (state) => {
                state.roomsLoad = false;
            })


            .addCase(roomsCreate.pending, (state) => {
                state.roomsLoad = true;
            })
            .addCase(roomsCreate.fulfilled, (state, action) => {
                state.roomsLoad = false;
                state.roomsChange = true;
            })
            .addCase(roomsCreate.rejected, (state) => {
                state.roomsLoad = false;
            })


            .addCase(roomsDelete.pending, (state) => {
                state.roomsLoad = true;
            })
            .addCase(roomsDelete.fulfilled, (state, action) => {
                state.roomsLoad = false;
                state.roomsChange = true;
            })
            .addCase(roomsDelete.rejected, (state) => {
                state.roomsLoad = false;
            })


            .addCase(roomsPatch.pending, (state) => {
                state.roomsLoad = true;
            })
            .addCase(roomsPatch.fulfilled, (state, action) => {
                state.roomsLoad = false;
                state.roomsChange = true;
            })
            .addCase(roomsPatch.rejected, (state) => {
                state.roomsLoad = false;
            })
    }
});

export const { resetLoading, resetUserByTag } = permissionsSlice.actions;
export default permissionsSlice.reducer;