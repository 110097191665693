import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { frontpageView as fpv } from 'features/frontpage/FrontpageView';
import { companyPublicData } from "features/publicSys/CompanyPublicData";
import { showEventsList } from "features/eventSys/ShowEventsList";
import Layout from "components/Layout";
import Carousel from './reservationsSidebar/forms/Carousel';
import SVGDisplay from './reservationsSidebar/forms/SVGDisplay';
import ContactFormPublic from './reservationsSidebar/forms/ContactFormPublic';
import CompanyInfoFormDisplay from 'containers/components/CompanyInfoFormDisplay';

import availableBeadSVG from './images/vector_orange/available_bead.svg';
import billsSVG from './images/vector_orange/bills.svg';
import bootleOfCampignSVG from './images/vector_orange/bootle_of_campign.svg';
import capacityOfPeopleSVG from './images/vector_orange/capcacity_of_people.svg';
import creditCardSVG from './images/vector_orange/credit_card.svg';
import fireworksSVG from './images/vector_orange/fireworks.svg';
import gardenSVG from './images/vector_orange/garden.svg';
import haircutSVG from './images/vector_orange/haircut.svg';
import musicSVG from './images/vector_orange/music.svg';
import openSpaceSVG from './images/vector_orange/open_space.svg';
import parkingSVG from './images/vector_orange/parking.svg';
import plateWithDrumstickSVG from './images/vector_orange/plate_with_drumstick.svg';
import smokingAreaSVG from './images/vector_orange/smoking_area.svg';
import speakingEnglishSVG from './images/vector_orange/speaking_english.svg';
import tableSVG from './images/vector_orange/table.svg';
import { useTranslation } from 'react-i18next';

const svgList = [
    { name: 'bed', src: availableBeadSVG },
    { name: 'bills', src: billsSVG },
    { name: 'bottle', src: bootleOfCampignSVG },
    { name: 'capacity', src: capacityOfPeopleSVG },
    { name: 'card', src: creditCardSVG },
    { name: 'firework', src: fireworksSVG },
    { name: 'garden', src: gardenSVG },
    { name: 'haircut', src: haircutSVG },
    { name: 'music', src: musicSVG },
    { name: 'open', src: openSpaceSVG },
    { name: 'parking', src: parkingSVG },
    { name: 'plate', src: plateWithDrumstickSVG },
    { name: 'smoking', src: smokingAreaSVG },
    { name: 'speak', src: speakingEnglishSVG },
    { name: 'table', src: tableSVG }
];


const OverviewPage = () => {
    const { company_id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const today = new Date().toISOString().split('T')[0];
    const { frontpageView, frontpageViewNotExist, user_request } = useSelector(state => state.frontpage);
    const { companyData } = useSelector(state => state.public);
    const { eventList } = useSelector(state => state.reservation);

    const [company, setCompany] = useState(null);
    const [invalid, setInvalid] = useState(false);
    const [photos, setPhotos] = useState(null);
    const [companyForm, setCompanyForm] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [companyAbout, setCompanyAbout] = useState("");

    const [eventListLocal, setEventListLocal] = useState(eventList || []);





    useEffect(() => {
        if (company_id) {

            const payload = {
                company_id: company_id,
            };

            dispatch(fpv(payload));
            dispatch(companyPublicData(payload));
            dispatch(showEventsList(payload));
        } else {
            setInvalid(true);
        }
    }, [dispatch]);

    useEffect(() => {
        if (companyData) {
            setCompany(companyData);
        }
    }, [dispatch, companyData]);

    useEffect(() => {
        if (frontpageView) {
            setCompanyName(frontpageView.name);
            setCompanyAbout(frontpageView.about);
        }
    }, [dispatch, frontpageView]);

    useEffect(() => {
        if (eventList) {
            setEventListLocal(eventList || []);
        }

    }, [dispatch, eventList]);


    useEffect(() => {
        if (frontpageView) {
            setPhotos(frontpageView.page_gallery || []);
            console.log("photos: " + JSON.stringify(photos));
            console.log("frontpageView.page_gallery: " + JSON.stringify(frontpageView.page_gallery));
        }

    }, [dispatch, frontpageView]);







    const handleFormChange = (updatedFormData) => {
        setCompanyForm(updatedFormData);
    };

    const proxyPath = (url) => {
        if (url) {
            const newRelativePath = url.split('/media/')[1];
            return (`${process.env.REACT_APP_MEDIA}/media/${newRelativePath}`);
        } else {
            return "";
        }

    };

    const initializeSelections = (frontPageData) => {
        return svgList.map(svg => ({
            ...svg,
            isSelected: frontPageData ? frontPageData[svg.name] : false,
            description: frontPageData ? frontPageData[`text_${svg.name}`] : '',
            color: '#000000'
        }));
    };

    return (
        <Layout title='Sonex | Pregled  događaja' content='Not found'>
            {frontpageViewNotExist || invalid ? (<><h1>No data</h1></>)
                : (
                    <div>
                        <div style={{ backgroundColor: "white", marginBottom: "20px", marginTop: "75px", borderRadius: '15px 15px 15px 15px', }}>
                            {photos ? (<Carousel photos={photos} companyName={companyName} about={companyAbout} />) : (<></>)}
                            {photos ? (<></>) : (<></>)}
                            <SVGDisplay selections={initializeSelections(frontpageView)} />
                        </div>

                        <div style={{ backgroundColor: "white", marginBottom: "20px", padding: "15px", borderRadius: '15px 15px 15px 15px', }}>

                            <div className=" row col-lg-12 col-md-12 col-sm-12" >
                                <div className=" row col-lg-6 col-md- col-sm-12" >
                                    <div>

                                        {user_request ? (<><p style={{ marginTop: "20px" }}>Zahtjev uspješno poslan! Nakon obrade zahtjeva povratna informacija će Vam biti poslana na email ili na SMS</p></>) : (<></>)}
                                        <ContactFormPublic disabled={user_request} />


                                    </div>
                                </div>
                                <div className=" row col-lg-6 col-md-12 col-sm-12" style={{ marginRight: "10px" }}>

                                    {frontpageView ? (<CompanyInfoFormDisplay company={null} onFormChange={handleFormChange} frontPageView={frontpageView} />
                                    ) : (<></>)}

                                </div>

                            </div>
                        </div>

                        <div className=" row col-lg-12 col-md-12 col-sm-12" style={{ marginTop: "20px" }}>
                            {/**<MenuManager />**/}
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '20px', backgroundColor: '#21254D', borderRadius: '8px', height: "100px", marginBottom: "20px" }}>
                            <h1 style={{ color: "white" }}>{t('events')}</h1>
                        </div>

                        <div className="event-cards-container">
                            {eventListLocal.map((event, index) => (
                                <div key={index} className="card_event">
                                    <img src={proxyPath(event.eventImage)} alt="Event Image" />
                                    <div className="event_card_content">
                                        <h2>{event.eventName}</h2>
                                        <p>{event.eventDescription}</p>
                                        <p className="date">Datum: <strong>{new Date(event.selectedDate).toLocaleDateString()}</strong></p>

                                        <div className="actions">
                                            <button className="edit-button">Pregledaj</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <span style={{ display: 'block', height: '100px' }}></span>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <span style={{ display: 'block', height: '10px' }}></span>
                        </div>

                    </div>
                )}
        </Layout >
    );



};

export default OverviewPage;

