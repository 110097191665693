import React, { useState, useEffect, useRef } from 'react';
import './Carousel.css';

const CarouselCard = ({ photos, companyName, about, random = true }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visiblePhotos, setVisiblePhotos] = useState([]);
  const timeoutRef = useRef(null);

  useEffect(() => {
    const updatedVisiblePhotos = photos.filter(photo => photo.show);
    setVisiblePhotos(updatedVisiblePhotos);

    // Reset the current index if it exceeds the length of visiblePhotos
    if (currentIndex >= updatedVisiblePhotos.length) {
      setCurrentIndex(0);
    }
  }, [photos, currentIndex]);

  useEffect(() => {
    const getNextDuration = () => (random ? 3000 + Math.floor(Math.random() * 7000) : 3000);

    const changeSlide = () => {
      setCurrentIndex(prevIndex =>
        prevIndex === visiblePhotos.length - 1 ? 0 : prevIndex + 1
      );
    };

    const startTimeout = () => {
      const duration = getNextDuration();
      timeoutRef.current = setTimeout(() => {
        changeSlide();
        startTimeout();
      }, duration);
    };

    startTimeout();

    return () => clearTimeout(timeoutRef.current); // Clear timeout on unmount
  }, [visiblePhotos, random]);

  const goToNext = () => {
    clearTimeout(timeoutRef.current);
    setCurrentIndex(prevIndex =>
      prevIndex === visiblePhotos.length - 1 ? 0 : prevIndex + 1
    );
    startTimeout(); // Restart timeout after manual change
  };

  const goToPrevious = () => {
    clearTimeout(timeoutRef.current);
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? visiblePhotos.length - 1 : prevIndex - 1
    );
    startTimeout(); // Restart timeout after manual change
  };

  const startTimeout = () => {
    const getNextDuration = () => (random ? 3000 + Math.floor(Math.random() * 7000) : 3000);
    const duration = getNextDuration();
    timeoutRef.current = setTimeout(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === visiblePhotos.length - 1 ? 0 : prevIndex + 1
      );
      startTimeout();
    }, duration);
  };

  const transformUrl = url => {
    return url.replace('http://localhost:8000', `${process.env.REACT_APP_MEDIA}`);
  };

  return (
    <div className="carousel" style={{borderRadius: "10px 10px 0px 0px", height: "300px"}}>

      <div className="carousel-overlay">
        <p className="carousel-text" style={{ fontSize: '30px' }}>{companyName}</p>
        <p className="carousel-text">{about}</p>
      </div>
      <div className="carousel-images" style={{height: "300px"}}>
        {visiblePhotos.map((photo, index) => (
          <img
            key={index}
            src={transformUrl(photo.image)}
            alt={`Slide ${index}`}
            className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>

    </div>
  );
};

export default CarouselCard;
