import { createAsyncThunk } from '@reduxjs/toolkit';

export const verifyContactA = createAsyncThunk(
    'public/contacts',
    async (payload, thunkAPI,) => {

        const body = JSON.stringify(payload);

        try {
            const res = await fetch('/api/public/contacts', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                console.log("SUKCESS=====>" + JSON.stringify(data));
                return data;

            } if (res.status === 200) {
                console.log("SUKCESS=====>" + JSON.stringify(data));
                return data;

            }

        } catch (err) {
            console.log("public/contacts Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);