// src/components/LanguageSwitcher.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'features/public';


const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { language } = useSelector(state => state.public);
    const [lan, setLan] = useState("");

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.startsWith('hr') && language === '') {
            i18n.changeLanguage('hr');
        } else {
            i18n.changeLanguage('hr');

        }
    }, [i18n]);

    useEffect(() => {
        if (language !== '' && language !== lan) {
            i18n.changeLanguage(language);
        }
    }, [dispatch, language]);

    const changeLanguageHc = (lng) => {
        i18n.changeLanguage(lng);
        dispatch(setLanguage(lng));
        setLan(lng);
    };

    return (
        <select name="switchbox" onChange={(e) => changeLanguageHc(e.target.value)} value={i18n.language} style={{ maxHeight: "25px", borderRadius: "8px", marginTop: "8px" }}>
            <option value="en">En</option>
            <option value="hr">Hr</option>
            {/* Add more languages here */}
        </select>
    );
};

export default LanguageSwitcher;
