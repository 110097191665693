import Layout from "components/Layout";
import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import { frontpageViewAll } from "features/frontpage/FrontpageViewAll";
import CarouselCard from "./reservationsSidebar/forms/CarouselCard";
import SVGDisplaySmall from "./reservationsSidebar/forms/SVGDisplaySmall";
import logo from "./2.png";

import availableBeadSVG from './images/vector/available_bead.svg';
import billsSVG from './images/vector/bills.svg';
import bootleOfCampignSVG from './images/vector/bootle_of_campign.svg';
import capacityOfPeopleSVG from './images/vector/capcacity_of_people.svg';
import creditCardSVG from './images/vector/credit_card.svg';
import fireworksSVG from './images/vector/fireworks.svg';
import gardenSVG from './images/vector/garden.svg';
import haircutSVG from './images/vector/haircut.svg';
import musicSVG from './images/vector/music.svg';
import openSpaceSVG from './images/vector/open_space.svg';
import parkingSVG from './images/vector/parking.svg';
import plateWithDrumstickSVG from './images/vector/plate_with_drumstick.svg';
import smokingAreaSVG from './images/vector/smoking_area.svg';
import speakingEnglishSVG from './images/vector/speaking_english.svg';
import tableSVG from './images/vector/table.svg';
import { useTranslation } from 'react-i18next';
import pic from '../components/mp1.png'

import { CheckedCalendarSvg, TwinSvg } from 'containers/Icons';

const svgList = [
    { name: 'bed', src: availableBeadSVG },
    { name: 'bills', src: billsSVG },
    { name: 'bottle', src: bootleOfCampignSVG },
    { name: 'capacity', src: capacityOfPeopleSVG },
    { name: 'card', src: creditCardSVG },
    { name: 'firework', src: fireworksSVG },
    { name: 'garden', src: gardenSVG },
    { name: 'haircut', src: haircutSVG },
    { name: 'music', src: musicSVG },
    { name: 'open', src: openSpaceSVG },
    { name: 'parking', src: parkingSVG },
    { name: 'plate', src: plateWithDrumstickSVG },
    { name: 'smoking', src: smokingAreaSVG },
    { name: 'speak', src: speakingEnglishSVG },
    { name: 'table', src: tableSVG }
];

const HomePage = () => {

    const initializeSelections = (frontPageData) => {
        return svgList.map(svg => ({
            ...svg,
            isSelected: frontPageData ? frontPageData[svg.name] : false,
            description: frontPageData ? frontPageData[`text_${svg.name}`] : '',
            color: '#000000'
        }));
    };

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { frontpages } = useSelector(state => state.frontpage);
    const [frontpagesLocal, setFrontpagesLocal] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dispatch(frontpageViewAll());
    }, [dispatch]);

    useEffect(() => {
        if (frontpages && frontpages.payload) {
            setFrontpagesLocal(Array.isArray(frontpages.payload) ? frontpages.payload : []);
        }
    }, [frontpages]);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        if (value) {
            const filtered = frontpages.payload.filter(fp =>
                fp.name.toLowerCase().includes(value) ||
                fp.address.toLowerCase().includes(value)
            );
            setFrontpagesLocal(filtered);
        } else {
            setFrontpagesLocal(Array.isArray(frontpages.payload) ? frontpages.payload : []);
        }
    };

    const styles = {
        container: {
            marginTop: '50px',
        },
        image: {
            borderRadius: '8px',
        },
        card: {
            padding: '20px',
            textAlign: 'center',
            marginTop: '20px',
            borderRadius: '10px',
            boxShadow: "0px 0px 0px 0px"
        },
        header: {
            color: '#ffaa00', // Warning color from Bootstrap
            fontSize: '20px'
        },
        subHeader: {
            fontWeight: 'bold',
            fontSize: '40px',
            color: "#22264B",
        },
        button: {
            backgroundColor: '#0d6efd', // Primary color from Bootstrap
            borderColor: '#0d6efd',
        },
        cardTitle: {
            fontSize: '18px',
            fontWeight: 'bold',
        },
        cardText: {
            fontSize: '14px',
        },
    };

    return (
        <Layout title="Sonex | HomePage" content="Home page">
            <div className="container" style={{ paddingTop: '50px', minHeight: "1000px" }}>
                <div className="row">
                    <div className="col-md-8">
                        <img
                            src={pic}
                            alt="Restaurant"
                            className="img-fluid"
                            style={styles.image}
                        />
                    </div>
                    <div className="col-md-4">
                        <h1 style={styles.header}>{t('your_digital_partner_for')}</h1>
                        <h2 style={styles.subHeader}>{t('managing_reservations_for_hospitality')}</h2>
                        <p>{t('optimize_your_bizz')}</p>
                        <div className="input-group mb-3">

                            <input
                                className="form-select"
                                type="text"
                                name="search"
                                placeholder={t('search_by_name_and_or_address')}
                                value={searchTerm}
                                onChange={handleSearch}
                                style={{ backgroundColor: "white" }}
                            />
                            <button className="btn" type="button" style={styles.button}>{t('search_caps')}</button>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="card" style={styles.card}>
                            <div className="card-body">
                                <label style={{ width: '35px', height: '35px', marginLeft: '-18px' }}>
                                    <CheckedCalendarSvg fill={"#22264B"} />
                                </label>
                                <h5 className="card-title" style={styles.cardTitle}><i className="bi bi-calendar3"></i>{t('effectively_manage_reservations')}</h5>
                                <p className="card-text" style={styles.cardText}>
                                    {t('fallow_all_your_reservations')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card" style={styles.card}>
                            <div className="card-body">
                                <label style={{ width: '35px', height: '35px', marginLeft: '-18px' }}>
                                    <TwinSvg fill={"#22264B"} />
                                </label>
                                <h5 className="card-title" style={styles.cardTitle}><i className="bi bi-person"></i> {t('personalization_of_reservation_request')}</h5>
                                <p className="card-text" style={styles.cardText}>
                                    {t('do_you_have_a_special_service_which_you_offer_to_your_guests')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card" style={styles.card}>
                            <div className="card-body">
                                <label style={{ width: '35px', height: '35px', marginLeft: '-18px' }}>
                                    <CheckedCalendarSvg fill={"#22264B"} />
                                </label>
                                <h5 className="card-title" style={styles.cardTitle}><i className="bi bi-bell"></i> {t('automatize_reminders_and_notifications')}</h5>
                                <p className="card-text" style={styles.cardText}>
                                    {t('forget_about_handwritten_notifications')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    {frontpagesLocal.map((frontpage, index) => (
                        <div key={index} className="col-lg-4 col-md-6 col-sm-12 mb-4" style={{ paddingRight: "11px", paddingLeft: "11px" }}>
                            <NavLink className="nav-link" to={`/overview/${frontpage.company_id}`}>
                                <div className="card" style={{ backgroundColor: 'white', boxShadow: "0px 0px 0px 0px", borderRadius: "10px" }}>
                                    <CarouselCard photos={frontpage.page_gallery} companyName={frontpage.name} about={frontpage.address} />
                                    <div className="card-body" style={{ marginBottom: "0px", marginTop: "0px" }}>
                                        <SVGDisplaySmall selections={initializeSelections(frontpage)} />

                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                </div>

            </div >
        </Layout >
    );
};

export default HomePage;
