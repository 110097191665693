import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateWorkdaysAndTime = createAsyncThunk(
    'reservations/company/workweek/update',
    async (payload, thunkAPI) => {
        const { data, company_id, user_id } = payload;
        //const body = JSON.stringify(data);
        //console.log(JSON.stringify({ data, company_id, user_id }));

        try {
            const res = await fetch(`/api/reservations/company/workweek/update`, {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ data, company_id, user_id }), // Adjust this line
            });

            const responseData = await res.json();

            if (res.status === 200) {
                return responseData;
            } else {
                console.log("UpdateWorkdaysAndTime != 200");
                return thunkAPI.rejectWithValue(responseData);
            }

        } catch (err) {
            console.log("UpdateWorkdaysAndTime Error");
            return thunkAPI.rejectWithValue(err.message);
        }
    }
);
