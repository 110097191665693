import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const ticketViewPublic = createAsyncThunk(
    'company/tickets/show/public/',
    async ({ reservation_id }, thunkAPI,) => {

        //console.log("ticket reservation_id=====>" + JSON.stringify(reservation_id));

        try {
            const res = await fetch(`/api/company/tickets/show/public/${reservation_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("tickets/show/public != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("tickets/show/public Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);