import { createAsyncThunk } from '@reduxjs/toolkit';

export const kpmgTestDelete = createAsyncThunk(
    '/api/kpmg/test/delete',
    async (payload, thunkAPI) => {
        const { id, company_id } = payload;

        try {
            const res = await fetch(`/api/kpmg/test/delete/${id}/${company_id}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });

            if (res.status === 204) {
                // Successfully deleted, no content to parse
                console.log("Contact deleted successfully");
                // You can return something specific here if needed, or null since there's no data
                return null;

            } else {
                // If the status is not 204, attempt to parse the response (assuming there might be an error or other data)
                const data = await res.json();
                console.log("Unexpected response", data);
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("kpmgTestDelete Error -----------------------", err);
            // Make sure to handle cases where err.response or err.response.data might not exist
            return thunkAPI.rejectWithValue(err.response ? err.response.data : { error: err.message });
        }
    }
);
