export const businessSidebarConfig = [
    {
        title: "business_pages",
        items: [
            { name: "overview_business_data", component: "BizzOverview" },
            //{ name: "public_page_overview", component: "PublicPageOverview" },
            { name: "edit_public_page", component: "EditPublicPage" },
        ],

    },

];