import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const CompanyInfoFormDisplay = ({ company, closeModal, onFormChange, frontPageView }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initializeFormData = (companyData, frontPageData) => ({
    name: frontPageData?.name ?? companyData?.name ?? '',
    about_us: frontPageData?.about ?? companyData?.about_us ?? '',
    address: frontPageData?.address ?? companyData?.address ?? '',
    email: frontPageData?.email ?? companyData?.email ?? '',
    gsm: frontPageData?.number ?? companyData?.gsm ?? '',
  });

  const [formData, setFormData] = useState(initializeFormData(company, frontPageView));

  useEffect(() => {
    setFormData(initializeFormData(company, frontPageView));
    onFormChange(initializeFormData(company, frontPageView));
  }, [company, frontPageView]);

  return (
    <div style={{ paddingTop: "0px", margin: "0px" }}>
      <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', marginTop: "20px" }}>{formData.name}</h2>
      <hr />
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li style={{ marginBottom: '20px' }}>
          <strong style={{ display: 'block', marginBottom: '4px' }}>{t('name_implied_label')}</strong>
          <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#333' }}>{formData.name}</span>
        </li>
        <hr />
        <li style={{ marginBottom: '20px' }}>
          <strong style={{ display: 'block', marginBottom: '4px' }}>{t('about_label')}</strong>
          <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#333', display: 'block', height: '120px' }}>{formData.about_us}</span>
        </li>
        <hr />
        <li style={{ marginBottom: '20px' }}>
          <strong style={{ display: 'block', marginBottom: '4px' }}>{t('address_label')}</strong>
          <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#333', display: 'block', height: '50px' }}>{formData.address}</span>
        </li>
        <hr />
        <li style={{ marginBottom: '20px' }}>
          <strong style={{ display: 'block', marginBottom: '4px' }}>{t('contact')}:</strong>
          <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#333' }}>{formData.email}</span>
        </li>
        <hr />
        <li style={{ marginBottom: '20px' }}>
          <span style={{ fontSize: '16px', lineHeight: '1.5', color: '#333' }}>{t('overview_disclaimer')}</span>
        </li>
      </ul>
      
    </div>
  );
};

export default CompanyInfoFormDisplay;
