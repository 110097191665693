import { createAsyncThunk } from '@reduxjs/toolkit';

export const frontpageViewAll = createAsyncThunk(
    'company/frontpage/all',
    async (_, thunkAPI) => {

        console.log(" frontpages:");


        try {
            const res = await fetch(`/api/company/frontpage/all/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("company/frontpage/all != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("company/frontpage/all Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);