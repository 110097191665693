import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetUserByTag } from 'features/permissions';
import { permissionsGetUserByTag } from 'features/permissionsSys/PermissionsGetUserByTag';
import { permissionsUserAdd } from 'features/permissionsSys/PermissionsUserAdd';
import { useTranslation } from 'react-i18next';

const UserSearch = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userByTag, coworkers, permissions, notFound, loadingPermissions, changesPermissions } = useSelector(state => state.permissions)

  const [userId, setUserId] = useState('');
  const [userData, setUserData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [exists, setExists] = useState(false);

  const handleInputChange = (event) => {
    setUserId(event.target.value);
    setUserData(null);
    setExists(false);
  };

  const fetchUserData = () => {
    const payload = { "unique_name": userId };
    dispatch(permissionsGetUserByTag(payload));

  };

  const checkExistence = () => {
    let exists = false;

    exists = coworkers.some(coworker => coworker.id === userData.id);


    return exists;

  };

  const addUserToWhitelist = () => {
    setExists(checkExistence());

    if(checkExistence()) return;

    const payload = { 
      "user": userData.id,
      "company": permissions.company,  
    };
    console.log("ls: " + JSON.stringify(payload));
    dispatch(permissionsUserAdd(payload));
  };

  useEffect(() => {
    if(userByTag != userData){
      setUserData(userByTag);
    }
   }, [userByTag]);

  return (
    <div>
      <h5 style={{ marginTop: '10px', marginBottom: '20px' }}>{t('search_user_by_id_label')}</h5>
      <div style={{ position: 'relative', marginBottom: '20px' }}>
        <input
          style={{ width: '100%', padding: '8px', border: '1px solid #ccc' }}
          type="text"
          name="userId"
          onChange={handleInputChange}
          value={userId}
          disabled={isSubmitting}
          required
        />
        <label style={{ position: 'absolute', left: '12px', top: '-8px', background: 'white', padding: '0 5px' }} htmlFor="userId">{t('user_id_label')}</label>
      </div>
      <button
        className="btn_matrix"
        onClick={fetchUserData}
        disabled={!userId || isSubmitting}
        style={{ height: '20px', paddingTop: '0px', float: 'left', fontSize: '12px', width: '200px' }}
      >
        {t('search')}
      </button>
      {loadingPermissions && 
                          (<>
                            <div style={{
                              position: 'fixed', // Use fixed to keep it in the center regardless of scrolling
                              top: '0', // Start from the top of the viewport
                              left: '0', // Start from the left of the viewport
                              right: '0', // Extend to the right of the viewport
                              bottom: '0', // Extend to the bottom of the viewport
                              display: 'flex', // Enable flexbox
                              justifyContent: 'center', // Center horizontally
                              alignItems: 'center', // Center vertically
                              backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: Adds a white semi-transparent background
                              zIndex: '9999' // Ensure it's above other content
                            }}>
                              <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                            </div>
                          </>)}

      {notFound && (<><h5 style={{ marginTop: '60px', overflowX: 'auto' }}>{t('user_not_found')}</h5></>)}
      {userData && (
        <>
      <div style={{ marginTop: '60px', overflowX: 'auto' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{t('id')}</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{t('name')}</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>{t('surname')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{userId}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{userData.first_name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{userData.last_name}</td>
            </tr>
          </tbody>
        </table>
      </div>
          <button
            className="btn_matrix"
            onClick={addUserToWhitelist}
            style={{ marginTop: '20px', height: '20px', paddingTop: '0px', float: 'left', fontSize: '12px', width: '200px' }}
          >
            {t('add_user')}
          </button>
        </>
      )}
      {changesPermissions && (<><h5 style={{ marginTop: '60px', overflowX: 'auto' }}>{t('user_successfully_added')}</h5></>)}
      {exists && (<><h5 style={{ marginTop: '60px', overflowX: 'auto' }}>{t('user_allready_exist')}</h5></>)}
       <span style={{ display: 'block', height: '60px' }}></span>
    </div>
  );
};

export default UserSearch;
