import { createAsyncThunk } from '@reduxjs/toolkit';

export const getWorkdaysAndTimeId = createAsyncThunk(
    'reservations/company/workweekmd/get_id',
    async (data, thunkAPI,) => {
        const body = JSON.stringify({
            data
        });

        try {
            const res = await fetch('/api/reservations/company/workweekmd/get_id', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;
            } else {
                console.log("GetWorkdaysAndTimeID != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("GetWorkdaysAndTimeID Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);