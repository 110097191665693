// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Optional: Create a CSS file for styling
import zicer from './zicer_logo.png';
import corvus from './logotypes/3_Logo_CorvusPay/corvus-logo-1x.png';
import master from './logotypes/1_Logo_prihvat/Mastercard/ma_symbol_opt_45_2x.png';
import maestro from './logotypes/1_Logo_prihvat/Maestro/ms_vrt_opt_pos_45_2x.png';
import visa from './logotypes/1_Logo_prihvat/Visa/visa_90_30.png';
import mc_idC from './logotypes/2_Logo_sigurnost/Mastercard Identity Check/Horizontalni/mc_idcheck_hrz_pos_97px.png';
import visa_secure from './logotypes/2_Logo_sigurnost/Visa Secure Logo/VisaSecureLogo-x075.png'
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const { t } = useTranslation();
    return (
        <footer className="footer" style={{ width: "100%" }}>
            <div className="row col-lg-12 col-md-12 col-sm-12" style={{ marginTop: "20px", marginBottom: "0px" }}>
                <div className="row col-lg-4 col-md-6 col-sm-12" style={{ textAlign: 'left' }}>
                    <ul style={{ marginLeft: "20px" }}>
                        <li>InnoLink d.o.o.</li>
                        <li>Adresa: Dobriše Cesarića 7, 51000 Rijeka, {t('croatia')}</li>
                        <li>OIB: 79707251376</li>
                        <li>Mb: 05920493</li>
                        <li>Mob: +38592 2862 492</li>
                        <li>Mail: info@sonexapp.hr</li>
                    </ul>
                </div>

                <div className="row col-lg-4 col-md-6 col-sm-12" style={{ textAlign: 'left' }}>
                    <ul style={{ marginLeft: "20px" }}>
                        <li><a href="/terms" style={{ color: "white" }}>{t('terms_of_service')}</a></li>
                        <li><a href="/payments" style={{ color: "white" }}>{t('terms_of_payments')}</a></li>
                        <li><a href="/privacy" style={{ color: "white" }}>{t('privacy_declaration')}</a></li>
                        <li><a href="/safety" style={{ color: "white" }}>{t('payment_safety')}</a></li>
                    </ul>
                </div>

                <div className="row col-lg-4 col-md-6 col-sm-12" style={{ textAlign: 'left' }}>
                    <ul style={{ marginLeft: "20px" }}>
                        <li>{t('payment')}:</li>
                        <li>{t('payment_description')}</li>
                    </ul>
                </div>

            </div>
            <div className="row col-lg-12 col-md-12 col-sm-12">
                <div className="row col-lg-4 col-md-6 col-sm-12" style={{ textAlign: 'left' }}>
                    <label style={{ marginLeft: "20px" }}>{t('supported_by')}:</label>
                    <a href="https://www.zicer.hr" target="_blank" rel="noopener noreferrer" style={{ width: "297px", height: "168px" }}>
                        <img
                            src={zicer}
                            alt="Zicer logo"
                            style={{
                                width: "297px",
                                height: "168px",
                                marginLeft: "20px",
                                borderRadius: "10px"
                            }}
                        />
                    </a>
                </div>
                <div className="row col-lg-4 col-md-6 col-sm-12" style={{ textAlign: 'left' }}>
                    <label style={{ marginLeft: "20px" }} >{t('payments_provided_by')}: <a href="https://www.corvuspay.com" target="_blank" rel="noopener noreferrer">CorvusPay</a></label>
                    <a href="https://www.corvuspay.com" target="_blank" rel="noopener noreferrer" style={{ width: "251px", height: "52px", marginLeft: "20px" }}>
                        <img
                            src={corvus}
                            alt="CorvusPay logo"
                            style={{
                                width: "251px",
                                height: "52px",
                                marginLeft: "-10px"
                            }}
                        />
                    </a>
                </div>
                <div className="row col-lg-4 col-md-6 col-sm-12" style={{ textAlign: 'left' }}>

                    <div className='row'>
                        <label style={{ marginBottom: "5px", marginLeft: "20px" }}>{t('pay_with')}: Mastercard® i Maestro®, Visa®</label>

                        <a href="https://www.mastercard.com" target="_blank" rel="noopener noreferrer" style={{ width: "90px", height: "64px" }}>
                            <img
                                src={master}
                                alt="Mastercard"
                                style={{
                                    width: "90px",
                                    height: "64px",
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    marginLeft: "20px"
                                }}
                            />
                        </a>
                        <a href="https://www.mastercard.hr/hr-hr/consumers/find-cardproducts/debit-cards/maestro-debit.html" target="_blank" rel="noopener noreferrer" style={{ width: "90px", height: "64px", marginLeft: "10px", marginRight: "20px" }}>
                            <img
                                src={maestro}
                                alt="Maestro"
                                style={{
                                    marginLeft: "20px",
                                    width: "90px",
                                    height: "64px",
                                    backgroundColor: "white",
                                    borderRadius: "10px"
                                }}
                            />
                        </a>
                        <a href="https://www.visa.com.hr" target="_blank" rel="noopener noreferrer" style={{ left: "20px", display: "flex", width: "90px", height: "64px", marginLeft: "20px", justifyContent: "center", alignItems: "center", backgroundColor: "white", borderRadius: "10px", overflow: "hidden" }}>
                            <img
                                src={visa}
                                alt="Visa"
                                style={{

                                    width: "90px",  // Maintain the aspect ratio of the image
                                    height: "auto",
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    display: "flex",  // To use margin auto for centering
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            />
                        </a>
                    </div>


                    <div className='row' style={{}}>
                        <label style={{ marginBottom: "5px", marginLeft: "20px" }}>{t('safety_programs')}: Mastercard Identity Check™, Visa Secure™</label>
                        <a href="https://www.mastercard.hr/hr-hr/issuers/identitycheck.html" target="_blank" rel="noopener noreferrer" style={{ display: "flex", width: "90px", height: "64px", marginLeft: "30px", marginTop: "10px", justifyContent: "center", alignItems: "center", backgroundColor: "white", borderRadius: "10px", overflow: "hidden" }}>
                            <img
                                src={mc_idC}
                                alt="Mastercard"
                                style={{

                                    width: "90px",  // Maintain the aspect ratio of the image
                                    height: "auto",
                                    backgroundColor: "white",
                                    borderRadius: "10px",
                                    display: "flex",  // To use margin auto for centering
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            />
                        </a>

                        <a href="https://www.visa.com.hr/placajte-visakarticom/featured-technologies/verified-by-visa.html" target="_blank" rel="noopener noreferrer" style={{ display: "flex", width: "75px", height: "75px", marginLeft: "117px", marginTop: "15px", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                            <img
                                src={visa_secure}
                                alt="Visa Secure"
                                style={{
                                    width: "75px",  // Maintain the aspect ratio of the image
                                    height: "75",
                                    display: "flex",  // To use margin auto for centering
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            />
                        </a>
                    </div>
                </div>
                <p>&copy; {new Date().getFullYear()} InnoLink.doo All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
