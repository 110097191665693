import React from 'react';
import ImageDjango from './ImageDjango'; // Adjust the import path according to your project structure
import { useTranslation } from 'react-i18next';

const UserDetailForm = ({ user }) => {
  const { t } = useTranslation();
  // Assuming 'user' object contains all the necessary user details
  // You might need to add or adjust fields based on your actual user object structure
  
  return (
    <>
      <div>
      <form>
        <p className="title">{t('user_details')}</p>

        <div className="input-field">
          <input
            className="input"
            type="text"
            name="name"

            value={user.unique_name}
            disabled={true}
            required
          />
          <label className="label" htmlFor="name">ID:</label>
        </div>

        <div className="input-field">
          <input
            className="input"
            type="text"
            name="name"

            value={user.first_name}
            disabled={true}
            required
          />
          <label className="label" htmlFor="name">{t("name_label")}</label>
        </div>

        <div className="input-field">
          <input
            className="input"
            type="text"
            name="name"

            value= {user.last_name}
            disabled={true}
            required
          />
          <label className="label" htmlFor="name">{t("surname_label")}</label>
        </div>

        <div className="input-field">
          <input
            className="input"
            type="text"
            name="name"

            value= {user.email}
            disabled={true}
            required
          />
          <label className="label" htmlFor="name">Email:</label>
        </div>

        <span style={{ display: 'block', height: '10px', alignContent: "center" }}></span>
        <ImageDjango src={user.image} alt="User Profile" />


      </form>

      </div>
</>
  );
};

export default UserDetailForm;

