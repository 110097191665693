import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const tableListCreate = createAsyncThunk(
    'company/tableList/create/',
    async (payload, thunkAPI,) => {


        const { company } = payload;
        const body = JSON.stringify(payload);

        try {
            const res = await fetch(`/api/company/tableList/create/${company}/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 201) {
                return data;

            } else {
                console.log("tableListCreate != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("tableListCreate Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);