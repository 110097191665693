import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { passwordResetExecute } from "features/permissionsSys/PasswordResetExecute";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Layout from "components/Layout";
import './App.css';

const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
        errors.push("Minimalna duljina lozinke je 8 znakova.");
    }
    if (!/\d/.test(password)) {
        errors.push("Lozinka mora sadržavati barem jedan broj.");
    }
    if (!/[a-z]/.test(password)) {
        errors.push("Lozinka mora sadržavati barem jedno malo slovo.");
    }
    if (!/[A-Z]/.test(password)) {
        errors.push("Lozinka mora sadržavati barem jedno veliko slovo.");
    }
    //if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    //    errors.push("Password must include at least one special character.");
    //}
    return errors;
}

const PasswordReset = () => {
    const dispatch = useDispatch();

    const { recovery_id } = useParams();
    const { resetPasswordRequested, loadingPermissions } = useSelector(state => state.permissions);

    const [passwordErrors1, setPasswordErrors1] = useState([]);
    const [passwordErrors2, setPasswordErrors2] = useState([]);
    const [eqPass, setEqPass] = useState(true);
    const [formData, setFormData] = useState({
        password1: "",
        password2: "",
    });


    const { password1, password2 } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "password1") {
            const errors = validatePassword(e.target.value);
            setPasswordErrors1(errors);
        }

        if (e.target.name === "password2") {
            const errors = validatePassword(e.target.value);
            setPasswordErrors2(errors);
        }

        setEqPass(true);

    };


    const onSubmit = e => {
        e.preventDefault();
        setEqPass(password1 === password2);
        if (!eqPass) return;

        const payload = {
            "hash": recovery_id,
            "new_password": password1,
        };

        dispatch(passwordResetExecute(payload));
    };

    return (
        <Layout title='Sonex | Password recovery' content='Login page'>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '50px' }} >
                <div className="row col-lg-4 col-md-6 col-sm-12" >

                    <form className="form-control"
                        action=""
                        onSubmit={onSubmit}>
                        <p className="title">Promjena lozinke</p>

                        <div className="input-field">
                            <input className="input"
                                type="password"
                                name="password1"
                                autoComplete="current-password"
                                onChange={onChange}
                                value={password1}
                                required
                            />
                            <label className="label" htmlFor="input">Lozinka:</label>
                        </div>
                        {passwordErrors1.map((error, index) => (
                            <div key={index} style={{ fontSize: "9px", color: "red" }}>{error}</div>
                        ))}

                        <div className="input-field">
                            <input className="input"
                                type="password"
                                name="password2"
                                autoComplete="current-password"
                                onChange={onChange}
                                value={password2}
                                required
                            />
                            <label className="label" htmlFor="input">Lozinka ponovno:</label>
                        </div>
                        {passwordErrors2.map((error, index) => (
                            <div key={index} style={{ fontSize: "9px", color: "red" }}>{error}</div>
                        ))}

                        <a style={{ fontSize: "12px" }}>Unesite lozinku, zatim ju potvrdite ponovnim unošenjem.
                            Lozinka mora sadržavati minimalno 8 znakova te velika i mala slova.</a>

                        {!eqPass ?
                            (<><a style={{ fontSize: "12px", color: "red" }}>Unešene lozinke moraju biti jednake!</a></>)
                            : (<></>)}

                        {resetPasswordRequested ?
                            (<><a style={{ fontSize: "12px", color: "green" }}>Lozinka uspješno promijenjena.</a></>)
                            : (<></>)}

                        {loadingPermissions ?
                            (
                                <div className="spinner-border text-primary mt-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <button className="submit-btn mt-4"> Promijeni lozinku </button>
                            )
                        }
                    </form>
                </div>
            </div>

            <div className="container" style={{ marginTop: "100px", marginBottom: "20px" }}>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        {/* This 'container' div centers the content and sets a max-width for large screens */}
                        <div className="row">
                            {/* Each column now takes up 4 of 12 possible columns on large screens, centered within the '.col-lg-8' container */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>Dobro došli u naš sustav Narudžbe & Rezervacije. U srcu naše misije leži želja da olakšamo proces narudžbi i rezervacija, pretvarajući ga u bezbrižno iskustvo.
                                    Naša platforma je dizajnirana s vama na umu - brza, pouzdana i intuitivna, osigurava da vaše narudžbe i rezervacije budu obrađene u rekordnom vremenu, bez komplikacija.</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Zašto odabrati Sonex?</h2>
                                <p style={{ fontSize: "14px" }}>Brzina i Efikasnost: U samo nekoliko klikova, vaša narudžba ili rezervacija bit će potvrđena.</p>
                                <p style={{ fontSize: "14px" }}>Pouzdanost: Naš sustav koristi najnovije tehnologije za osiguravanje točnosti vaših narudžbi i rezervacija.</p>
                                <p style={{ fontSize: "14px" }}>Prilagodljivost: Bez obzira jeste li pojedinac ili tvrtka, naša platforma pruža prilagođena rješenja.</p>
                                <p style={{ fontSize: "14px" }}>Izvanredna podrška: Naš tim za korisničku podršku dostupan je 24/7.</p>


                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>Započnite svoje bezbrižno iskustvo s Narudžbama & Rezervacijama danas i otkrijte kako možemo transformirati
                                    vaše planiranje u uživanje. Dobrodošli u budućnost planiranja!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >
    );
};

export default PasswordReset;