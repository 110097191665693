import React, { useState, useEffect } from 'react';
import './Carousel.css';

const Carousel = ({ photos, companyName, about }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visiblePhotos, setVisiblePhotos] = useState([]);

  useEffect(() => {
    const updatedVisiblePhotos = photos.filter(photo => photo.show);
    setVisiblePhotos(updatedVisiblePhotos);

    // Reset the current index if it exceeds the length of visiblePhotos
    if (currentIndex >= updatedVisiblePhotos.length) {
      setCurrentIndex(0);
    }
  }, [photos]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === visiblePhotos.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [visiblePhotos]);

  const goToNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === visiblePhotos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevious = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? visiblePhotos.length - 1 : prevIndex - 1
    );
  };

  const transformUrl = url => {
    if (!url.startsWith('http://localhost:8000')) {
      return `${process.env.REACT_APP_MEDIA}${url}`;
    }
    return url.replace('http://localhost:8000', `${process.env.REACT_APP_MEDIA}`);
  }

  return (
    <div className="carousel" style={{borderRadius: '10px 10px 0px 0px'}}>
      <button className="carousel-butt prev" onClick={goToPrevious}>
        &#10094;
      </button>
      <div className="carousel-overlay">
        <p className="carousel-text" style={{ fontSize: '30px' }}>{companyName}</p>
        <p className="carousel-text">{about}</p>
      </div>
      <div className="carousel-images">
        {visiblePhotos.map((photo, index) => (
          <img
            key={index}
            src={transformUrl(photo.image)}
            alt={`Slide ${index}`}
            className={`carousel-image ${index === currentIndex ? 'active' : ''}`}
          />
        ))}
      </div>
      <button className="carousel-butt next" onClick={goToNext}>
        &#10095;
      </button>
    </div>
  );
};

export default Carousel;
