import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


const NumberPad = ({peopleNum, setPeopleNum, okClicked, reservation_setup_content = null}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(peopleNum==="0"? `${t('person_count')}`  : peopleNum);
  const [firstEntry, setFirstEntry]= useState(true);
  const [minNumber, setMinNumber] = useState(1);
  const [minNumberEnabled, setMinNumberEnabled] = useState(false);

    const removeLeadingZeros = (value) => {
      return value.replace(/^0+/, '') || '0'; // Replace leading zeros, return '0' if empty
    };

    const handleButtonClick = (number) => {
      let newValue;
    
      if (firstEntry) {
        setFirstEntry(false);
        newValue = number.toString(); // Directly convert to string to ensure consistent types
      } else if (inputValue.length < 2) {
        newValue = inputValue + number.toString();
      } else {
        // If it already has 2 digits, replace the last digit with the new input
        newValue = inputValue.slice(0, -1) + number.toString();
      }
    
      setInputValue(removeLeadingZeros(newValue));
      setPeopleNum(removeLeadingZeros(newValue)); // Update the parent component state directly with the new value
    };

    const removeLast = () =>{
        if(inputValue !== `${t('person_count')}`){
            
            if (inputValue.length > 1) {
                setInputValue(inputValue.slice(0, -1)); // Remove last digit
                setPeopleNum(inputValue.slice(0, -1))
              } else {
                setInputValue(`${t('person_count')}`);
                setPeopleNum('0')
                setFirstEntry(true);
              }
        }
    };


    useEffect(() => {
      if (reservation_setup_content && reservation_setup_content) {
          const dat = reservation_setup_content;
          setMinNumber(dat?.minimum_guests_count ?? "");
          setMinNumberEnabled(dat.require_minimum_guests);
      }

  }, [dispatch, reservation_setup_content]);


  //Prikaži upozorenje
  const countWarning = () =>{
    if(minNumberEnabled){
      return `${t('person_count')} (${t('minimum')} ${minNumber})`

    }else{
      return `${t('person_count')}`
    }
  };

  const f_okClicked = () => {
    if(minNumberEnabled && Number(inputValue) < Number(minNumber)){
      setInputValue(minNumber);
      setPeopleNum(minNumber);
    }
    okClicked();
  };


  return (
    <div>
      <div className="display">{peopleNum==="0"? countWarning() : peopleNum}</div>
      <div className="number-pad" >
      <span style={{ display: 'block', width: '260px' }}></span>
        <div style={{maxWidth: "250px",
        marginLeft: "-50px"}}>
        

        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <button
            key={number}
            className="number-button"
            onClick={() => handleButtonClick(number.toString())}
          >
            {number}
          </button>
        ))}

        <button
            id="C"
            className="number-button-cancel"
            onClick={() =>removeLast()}
          >
            C
        </button>   


        <button
            id="0"
            className="number-button"
            onClick={() => handleButtonClick("0")}
          >
            0
        </button>

        <button
            id="OK"
            style={{paddingLeft: "15px"}}
            className="number-button"
            onClick={() => f_okClicked()}
          >
            OK
        </button>

        </div>
        
      </div>
    </div>
  );
};

export default NumberPad;
