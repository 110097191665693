import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkdaysAndTime } from 'features/reservationSys/GetWorkdaysAndTime';
import { getWorkdaysAndTimeId } from 'features/reservationSys/GetWorkdaysAndTimeId';
import { showEventsList } from "features/eventSys/ShowEventsList";

const Calendar = ({ onDateSelect }) => {
    const dispatch = useDispatch();
    const { permissions } = useSelector(state => state.permissions)
    const { eventList, workWeek } = useSelector(state => state.reservation);
    const { frontpageView } = useSelector(state => state.frontpage);
    const [eventListLocal, setEventListLocal] = useState(eventList || []);

    useEffect(() => {

        if ( permissions && !workWeek) {
            const companyIdObject = { "company_id": permissions.company };
            dispatch(getWorkdaysAndTime(companyIdObject));
            dispatch(getWorkdaysAndTimeId(companyIdObject));
        }
    }, [dispatch, workWeek]);

    useEffect(() => {

        if ( frontpageView && !workWeek) {
            const companyIdObject = { "company_id": frontpageView.company_id };
            dispatch(getWorkdaysAndTime(companyIdObject));
            dispatch(getWorkdaysAndTimeId(companyIdObject));
        }
    }, [dispatch, frontpageView]);

    useEffect(() => {
        if (permissions) {
            const payload = { company_id: permissions.company };
            dispatch(showEventsList(payload));

            if (frontpageView) {
                const payload = { company_id: frontpageView.company_id };
                dispatch(showEventsList(payload));
            }
        }
    }, [dispatch]);

    useEffect(() => {
        setEventListLocal(eventList);
    }, [eventList]);


    const now = new Date();
    const today = now.getDate();
    const thisMonth = now.getMonth();
    const thisYear = now.getFullYear();
    const [currentMonth, setCurrentMonth] = useState(thisMonth);
    const [currentYear, setCurrentYear] = useState(thisYear);
    const [selectedDate, setSelectedDate] = useState(`${thisYear}-${String(thisMonth + 1).padStart(2, '0')}-${String(today).padStart(2, '0')}`);

    const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const daysInMonth = getDaysInMonth(currentMonth, currentYear);

    // Adjusted to have Monday as the first day of the week
    const dayOffset = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Adjusted for Monday as first day

    const prevMonth = (e) => {
        e.preventDefault();
        setCurrentMonth(currentMonth === 0 ? 11 : currentMonth - 1);
        setCurrentYear(currentMonth === 0 ? currentYear - 1 : currentYear);
    };

    const nextMonth = (e) => {
        e.preventDefault();
        setCurrentMonth(currentMonth === 11 ? 0 : currentMonth + 1);
        setCurrentYear(currentMonth === 11 ? currentYear + 1 : currentYear);
    };

    const isWorkingDay = (date) => {
        const dayOfWeek = new Date(date).getDay();
        if(workWeek){
            switch (dayOfWeek) {
                case 0: return workWeek.sunday_is_working_day;
                case 1: return workWeek.monday_is_working_day;
                case 2: return workWeek.tuesday_is_working_day;
                case 3: return workWeek.wednesday_is_working_day;
                case 4: return workWeek.thursday_is_working_day;
                case 5: return workWeek.friday_is_working_day;
                case 6: return workWeek.saturday_is_working_day;
                default: return false;
            }

        }

    };

    const handleDayClick = (day) => {
        const dateStr = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        if (!isWorkingDay(dateStr)) return; // Prevent action on non-working days

        setSelectedDate(dateStr);
        onDateSelect(dateStr);
    };

    const findEventByDate = (date) => eventListLocal.find(event => event.selectedDate === date) || "";

    return (
        <div style={{maxWidth: "100%"}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <button onClick={prevMonth} className="btn_matrix" style={{ height: '20px', paddingTop: '0px', fontSize: '12px', marginRight: '10px' }}>{"<<"}</button>
                <span>{`${new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} ${currentYear}`}</span>
                <button onClick={nextMonth} className="btn_matrix" style={{ height: '20px', paddingTop: '0px', fontSize: '12px', marginLeft:'10px'}}>{">>"}</button>
            </div>
            {/* Month navigation and header here */}
            <div className="calendar-grid" style={{maxWidth: "70px"}}>
                {Array.from({ length: dayOffset }).map((_, index) => <div key={`empty-${index}`} className="calendar-day-empty"></div>)}
                {Array.from({ length: daysInMonth }, (_, i) => i + 1).map(day => {
                    const dateStr = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                    const isWorkDay = isWorkingDay(dateStr);
                    const event = findEventByDate(dateStr);
                    return (
                        <div key={day} onClick={() => isWorkDay && handleDayClick(day)} style={{maxWidth: "70px"}}
                            className={`calendar-day ${selectedDate === dateStr ? 'selected-day' : ''} ${!isWorkDay ? 'non-working-day' : ''} ${currentYear === thisYear && currentMonth === thisMonth && day === today ? 'current-day' : ''}`}>
                            {day}
                            {event && <div className="event-text">{event.eventName}</div>}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Calendar;
