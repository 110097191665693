import { createAsyncThunk } from '@reduxjs/toolkit';

export const showEventsList = createAsyncThunk(
    'reservations/company/event/viewall',

    async (payload, thunkAPI,) => {
        const { company_id } = payload;

        try {
            const res = await fetch('/api/reservations/company/event/viewall', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ company_id }),
            });

            const data = await res.json();
            if (res.status === 200) {
                return data;

            } else {
                console.log('res.status !200');
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log('res.status Errorčina');
            console.log(err);
            const errorResponse = err.response ? err.response.data : { message: 'ShowEventlistPayload An unknown error occurred' };
            return thunkAPI.rejectWithValue(errorResponse);
        }
    }
);