// Assuming the file path is correct based on your project structure
import React, { useState, Suspense, useEffect } from 'react';
import BusinessSidebar from './businessSidebar/BusinessSidebar';
import { useDispatch, useSelector } from 'react-redux';
import Layout from "components/Layout";
import { useNavigate } from 'react-router-dom';
import { businessSidebarConfig } from './businessSidebar/BusinessSidebarConfig';
import { setCurrentView } from '../features/reservation';

import { refreshAuth } from 'features/user';





const BizzPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { company, invalid } = useSelector(state => state.user);
    const storedView = useSelector(state => state.reservation.currentView);
    //const initialComponent = storedView || businessSidebarConfig[0].items[0].component;
    const initialComponent = businessSidebarConfig[0].items[0].component;
    const [activeView, setActiveView] = useState(initialComponent);

    useEffect(() => {
        const refreshUser = () => {
            dispatch(refreshAuth());
        };

        const intervalId = setInterval(refreshUser, 10 * 60 * 1000); // 10 minutes in milliseconds
        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        // Dispatch the action to update currentView in the Redux store when activeView changes
        dispatch(setCurrentView(activeView));
    }, [dispatch, activeView]);

    //KO je invalid user cert
    useEffect(() => {
        if (invalid) {
            navigate('/login');
        }
    }, [invalid]);


    const renderViewComponent = (componentName) => {
        const Component = React.lazy(() => import(`./reservationsSidebar/view/${componentName}`));
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Component />
            </Suspense>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form submission from reloading the page
    };

    return (

        <>
            <div className="container" style={{ paddingTop: '10px' }}>
                <form onSubmit={handleSubmit}>
                    <div >
                        <BusinessSidebar onSelect={(componentName) => { setActiveView(componentName); }} />
                        <div style={{ flex: 2, paddingLeft: '0px', paddingRight: '0px' }}>
                            {renderViewComponent(activeView)}
                        </div>
                    </div>
                </form>
            </div>
        </ >
    );
};


export default BizzPage;