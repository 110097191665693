import Layout from "components/Layout";
import { Navigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { register } from "features/user";
import { useTranslation } from 'react-i18next';


const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
        errors.push("Minimalna duljina lozinke je 8 znakova.");
    }
    if (!/\d/.test(password)) {
        errors.push("Lozinka mora sadržavati barem jedan broj.");
    }
    if (!/[a-z]/.test(password)) {
        errors.push("Lozinka mora sadržavati barem jedno malo slovo.");
    }
    if (!/[A-Z]/.test(password)) {
        errors.push("Lozinka mora sadržavati barem jedno veliko slovo.");
    }
    //if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    //    errors.push("Password must include at least one special character.");
    //}
    return errors;
}

const RegisterPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { registered, loading } = useSelector(state => state.user); //u store.js se referiramo kao user
    const [passwordErrors, setPasswordErrors] = useState([]);

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_realtor: "False"
    });

    //destrukturiraj kako bi mogao koristit direektno
    const { first_name, last_name, email, password, is_realtor } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "password") {
            const errors = validatePassword(e.target.value);
            setPasswordErrors(errors);
        }

    }; //state nam je objekt  iz linije 9

    //Kad stisnes gumb okine se submit
    const onSubmit = e => {
        console.log("ON submit action -----------------------");
        //refreshanje forme je default ponašanje
        e.preventDefault();

        //moras dispatchat event
        dispatch(register({ first_name, last_name, email, password, is_realtor }));

    };

    if (registered) return <Navigate to="/login" />;


    // name = "first_name" -> definiran naziv u router/auth/register.js
    // a accessamo ga zbog destrukturiranja i linije 17
    return (


        <Layout title='Sonex | Login' content='Login page'>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '50px' }}>
                <div className="row col-lg-4 col-md-6 col-sm-12"  >

                    <form className="form-control" style={{ boxShadow: "0px 0px 0px 0px" }}
                        action=""
                        onSubmit={onSubmit}>
                        <p className="title">{t('register_user_account')}</p>

                        <div className="input-field">
                            <input className="input"
                                type="text"
                                name="first_name"
                                onChange={onChange}
                                value={first_name}
                                required
                            />
                            <label className="label" htmlFor="input">{t('name_label')}</label>
                        </div>

                        <div className="input-field">
                            <input className="input"
                                type="text"
                                name="last_name"
                                onChange={onChange}
                                value={last_name}
                                required
                            />
                            <label className="label" htmlFor="input">{t('surname_label')}</label>
                        </div>

                        <div className="input-field">
                            <input className="input"
                                type="email"
                                name="email"
                                onChange={onChange}
                                value={email}
                                required
                            />
                            <label className="label" htmlFor="input">{t('email_label')}</label>
                        </div>

                        <div className="input-field">
                            <input className="input"
                                type="password"
                                name="password"
                                onChange={onChange}
                                value={password}
                                required
                            />
                            <label className="label" htmlFor="input">{t('password_label')}</label>
                        </div>
                        {passwordErrors.map((error, index) => (
                            <div key={index} style={{ fontSize: "9px", color: "red", marginTop: "0px" }}>{error}</div>
                        ))}
                        {loading ?
                            (
                                <div className="spinner-border text-primary mt-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <button className="submit-btn mt-4"> {t('register')} </button>
                            )
                        }
                    </form>
                </div>
            </div>

            <div className="container" style={{ marginTop: "100px", marginBottom: "20px" }}>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        {/* This 'container' div centers the content and sets a max-width for large screens */}
                        <div className="row">
                            {/* Each column now takes up 4 of 12 possible columns on large screens, centered within the '.col-lg-8' container */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>{t('login_text_1')}</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>{t('why_sonex_qm')}</h2>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2a')}</p>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2b')}</p>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2c')}</p>
                                <p style={{ fontSize: "14px" }}>{t('login_text_2d')}</p>


                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>{t('login_text_3')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >
    );
};

export default RegisterPage;