import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const ReservationConfirm = ({info, setCommentParent, sendReservationRequest, bckButton}) => {
  const { t } = useTranslation();
    

  const [comment, setComment] = useState("");
  
  const finalInfo = {
    nameAndSurname: "",
    gsm: "",
    peopleCount: "",
    menu: "",
    timeSpan: "",
    onDate: "",
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    setCommentParent(e.target.value);
  };

  const [inputValue, setInputValue] = useState(info? info : finalInfo);

  useEffect(() => {
    setInputValue(info);
  }, [info]);

    // Update inputValue when selectedMenu changes
    //useEffect(() => {
    //  setInputValue(selectedMenu ? selectedMenu.subtitle2 : "Odaberite Menu");
    //}, [selectedMenu]);


  return (
    <div className="number-pad" >
    <span style={{ display: 'block', width: '260px' }}></span>
        <div style={{maxWidth: "350px", marginLeft: "-85px"}}>
      <div className="display" style={{ textAlign: "left", fontSize: "16px"}}>
        <ul>
          <li>{inputValue.nameAndSurname}</li>
          <li>GSM: {inputValue.gsm}</li>
          <li>{t('person_count')}: {inputValue.peopleCount}</li>
          <li>{t('time_label')} {inputValue.timeSpan}</li>
          <li>{t('on_date_label')} {inputValue.onDate}</li>
        </ul>

        <div style={{marginLeft: "25px"}}>
            <div className="contact-input-field">
                <label className="contact-input-field-label-b" htmlFor="comment">{t('comment_label')}</label>

                <textarea
                  className="contact-input"
                  name="comment"
                  onChange={handleCommentChange}
                  value={comment}
                  style={{ height: '100px' }} // Adjust height as needed
              />
            </div>

        </div>
      </div>
      
      <span style={{ display: 'block' }}></span>
      <div className="row number-pad" style={{ marginLeft: "30px", display: "flex", alignItems:"center", justifyContent: "center", flexWrap: "nowrap"}}>

      <button
            id="bckButton"
            className="number-button"
            onClick={bckButton}
          >
            C
        </button>
        
        
        <button
          id="show_menus"
          className="time-frame-button_b"
          onClick={sendReservationRequest}
        >
           {t('send_request')}
        </button>

      </div>
    </div>
    </div>
  );
};

export default ReservationConfirm;
