import React, { useState } from 'react';
import Layout from "components/Layout";
import Pic from "containers/images/minit_logo.jpg";

const FlipCard = ({ child1, child2, enableFlip=true }) => {
    // State to track whether the card is flipped
    const [isFlipped, setIsFlipped] = useState(false);

    // Function to toggle the flip state
    const handleFlip = () => {
        setIsFlipped(!isFlipped && enableFlip);
    };

    return (
        <div className="fp_card" onClick={handleFlip} style={{ width: "100%", height: "500px", padding: "0", margin: "0", boxSizing: "border-box" }}>
            <div className={`fp_content ${isFlipped ? 'flipped' : ''}`} style={{ width: "100%", height: "100%", padding: "0", margin: "0", boxSizing: "border-box", position: "relative" }}>
                <div className="fp_back" style={{ width: "100%", height: "100%", padding: "0", position: "absolute", top: "0", left: "0", boxSizing: "border-box" }}>
                    <div className="fp_back-content" style={{ backgroundImage: `url(${Pic})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: "100%", height: "100%", padding: "0", margin: "0", boxSizing: "border-box" }}>
                        {child1}
                    </div>
                </div>
                <div className="fp_front" style={{ width: "100%", height: "100%", position: "absolute", top: "0", left: "0", boxSizing: "border-box" }}>
                    <div className="fp_img" style={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
                        <div className="fp_circle"></div>
                        <div className="fp_circle" id="fp_right"></div>
                        <div className="fp_circle" id="fp_bottom"></div>
                    </div>
                    <div className="fp_front-content" style={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
                        <small className="fp_badge"></small>
                        <div className="fp_description">
                            <div className="fp_title">
                                <strong>-</strong>
                            </div>
                            {child2}
                            <p className="fp_card-footer">-s &nbsp; | &nbsp; -</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlipCard;
