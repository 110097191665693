import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const roomsView = createAsyncThunk(
    'reservations/rooms/company/',
    async ({ company_id }, thunkAPI,) => {

        //console.log('reservations/rooms/company/');

        try {
            const res = await fetch(`/api/reservations/rooms/company/${company_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("roomsView != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("roomsView Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);