import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ExtraOptionSelector = ({ custom_elements, set_custom_elements, okClicked, bckButton, select_one }) => {
  const [elements, setElements] = useState(custom_elements);
  const { t } = useTranslation();

  const handleCheckboxChange = (index) => {
    const updatedElements = elements.map((element, idx) => {
      if (select_one) {
        return { ...element, checked: idx === index ? !element.checked : false };
      } else {
        return { ...element, checked: idx === index ? !element.checked : element.checked };
      }
    });
    setElements(updatedElements);
    set_custom_elements(updatedElements);
  };

  useEffect(() => {
    setElements(custom_elements);
  }, [custom_elements]);

  return (
    <>
      <div className="display">{t('extra_options')}</div>
      <div className="number-pad">
        <span style={{ display: 'block', width: '260px' }}></span>
        <div style={{ maxWidth: '250px', marginLeft: '-100px', minHeight:"250px" }}>
        <div style={{minHeight:"250px" }}>

        {elements.map((element, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <input
                type="checkbox"
                checked={element.checked}
                onChange={() => handleCheckboxChange(index)}
                style={{ marginRight: '10px' }}
              />
              <label>{element.name}</label>
            </div>
          ))}

        </div>

          <div style={{ maxWidth: '250px', marginLeft: '30px' }}>
            <button id="C" className="number-button-cancel" onClick={bckButton}>
              {'<'}
            </button>
            <button id="OK" className="number-button" onClick={okClicked} style={{paddingLeft: "15px"}}>
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExtraOptionSelector;
