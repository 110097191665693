import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const ReservationTimeSelectorFromDefined = ({timeFrame, setTimeFrame, okClicked, bckButton, duration="01:00"}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [innerDuration, setInnerDuration] = useState("01:00");

    useEffect(() => {
        if (!duration) return;

        setInnerDuration(duration);
        
    }, [dispatch, duration]);
    
    const internalData = {
      time_from:"12:00",
      time_to:"13:00"
    };

    const [timeData, setTimeData] = useState(internalData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
      setTimeFrame(internalData);

  }, [dispatch]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setTimeData((prevData) => ({
            ...prevData,
            [name]: value}));
    };

    const validateForm = () => {
      let tempErrors = {};
      if (!timeData.time_to.trim()) tempErrors.time_to = `${t('error_time_to_is_obligatory')}`;
      if (!timeData.time_from.trim()) tempErrors.time_from = `${t('error_time_from_is_obligatory')}`;
  
      // Check if time_to is less than time_from
      //if (timeData.time_from && timeData.time_to && timeData.time_to <= timeData.time_from) {
      //    tempErrors.time_to = `${t('error_time_to_less_than_time_from')}`;
      //}
  
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
  };
  

    //const handleSelectedTime = () => {
    //  if(!validateForm()) return;
//
    //  const payload = {
//
    //        time_from: timeData.time_from,
    //        time_to: duration.substring(0, 5)
    //  }
//
    //  setTimeFrame(payload);
    //  console.log(JSON.stringify(payload));
    //  okClicked();
    //};

    const handleSelectedTime = () => {
        if (!validateForm()) return;
    
        // Parse time_from (assuming it is in "hh:mm" format)
        const [hoursFrom, minutesFrom] = timeData.time_from.split(':').map(Number);
        const timeFromDate = new Date();
        timeFromDate.setHours(hoursFrom, minutesFrom, 0, 0);
    
        // Parse duration (assuming it is in "hh:mm" format and taking the substring as mentioned)
        const [durationHours, durationMinutes] = duration.substring(0, 5).split(':').map(Number);
    
        // Calculate the new time
        const timeToDate = new Date(timeFromDate);
        timeToDate.setHours(timeFromDate.getHours() + durationHours, timeFromDate.getMinutes() + durationMinutes);
    
        // Format the new time to "hh:mm"
        const timeToHours = String(timeToDate.getHours()).padStart(2, '0');
        const timeToMinutes = String(timeToDate.getMinutes()).padStart(2, '0');
        const timeTo = `${timeToHours}:${timeToMinutes}`;
    
        const payload = {
            time_from: timeData.time_from,
            time_to: timeTo,
        };
    
        setTimeFrame(payload);
        console.log(JSON.stringify(payload));
        okClicked();
    };


    return (<>
      <div className="display">{t('time_of_reservation')}</div>
        <div className="number-pad" style={{marginLeft: "130px"}}> 
            <span style={{ display: 'block', width: '260px' }}></span>
            <div style={{maxWidth: "250px", marginLeft: "-175px"}}>
                <div>
                    <span style={{ display: 'block', height: '30px' }}></span>
                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="time_from">{t('vrijeme_od')}</label>
                        <input
                            className="contact-input"
                            type="time"
                            id="time_from"
                            name="time_from"
                            value={timeData.time_from}
                            onChange={handleChange}
                        />
                        {errors.time_from && <div className="contact-validation-message">{errors.time_from}</div>}
                    </div>
                    <p style={{marginLeft: "60px"}}>{t('u_trajanju_od')} {innerDuration.substring(0, 5)} h</p>
                    <span style={{ display: 'block', height: '30px' }}></span>  
                    <p style={{marginLeft: "30px", textAlign: "center"}}>{t('timespan_text')}</p>                  
                </div>
                <div>
                    <span style={{ display: 'block', height: '10px' }}></span>
                    <div className="number-pad" style={{maxWidth: "250px", marginLeft: "30px"}}>
                        <button
                            id="C"
                            className="number-button"
                            onClick={bckButton}
                        >
                            {'<'}
                        </button>   

                        <button
                            id="OK"
                            className="number-button-ok"
                            onClick={handleSelectedTime}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>);
};

export default ReservationTimeSelectorFromDefined;
