import React from 'react';
import { useTranslation } from 'react-i18next';

const SVGDisplay = ({ selections }) => {
    const selectedItems = selections.filter(item => item.isSelected);
    const { t } = useTranslation();

    return (
        <div style={{ padding: '20px', backgroundColor: '#21254D', borderRadius: '0px 0px 10px 10px', width: "100%"}}>
            {selectedItems.length === 0 ? (
                <p style={{ color: 'white' }}>{t('select_icon')}</p>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                    {selectedItems.map((svg, index) => (
                        <div key={index} style={{ margin: '0px', padding: '0px', backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: '8px', textAlign: 'center' }}>
                            <img src={svg.src} alt={svg.name} style={{ width: '80px', height: '80px', marginBottom: '10px', filter: `hue-rotate(${svg.color})` }} />
                            <p>{svg.description}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SVGDisplay;
