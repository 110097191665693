import { createAsyncThunk } from '@reduxjs/toolkit';

export const appMenuViewByCompany = createAsyncThunk(
    'menu/company/',
    async ({ company_id }, thunkAPI) => {

        try {
            const res = await fetch(`/api/menu/company/${company_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            const contentType = res.headers.get('content-type');
            const text = await res.text(); // Get the response text



            if (contentType && contentType.includes('application/json')) {
                const data = JSON.parse(text); // Parse the JSON
                if (res.status === 200) {
                    return data;
                } else {
                    console.log("appMenuViewByCompany != 200 -----------------------");
                    return thunkAPI.rejectWithValue(data);
                }
            } else {
                console.error("Unexpected content-type:", contentType);
                return thunkAPI.rejectWithValue({ error: "Invalid content-type received", details: text });
            }
        } catch (err) {
            console.error("appMenuViewByCompany Error -----------------------");
            console.error(err);
            return thunkAPI.rejectWithValue({ error: "Network error", details: err.message });
        }
    }
);
