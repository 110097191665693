import { createAsyncThunk } from '@reduxjs/toolkit';

export const galleryCreate = createAsyncThunk(
    'reservations/gallery/create/',
    async (payload, { rejectWithValue }) => {
        const formData = new FormData();

        const { company_id } = payload;

        formData.append('show', payload.show);
        formData.append('company_id', payload.company_id);
        formData.append('image', payload.image); // Directly append the file object

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const response = await fetch(`/api/reservations/gallery/create/${company_id}/`, {
                method: 'POST',
                body: formData, // Send formData directly
                // Do not set Content-Type header, let the browser set it with the correct boundary
            });

            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
