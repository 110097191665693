import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const reservationListingChangelogView = createAsyncThunk(
    'reservations/listing/log/',
    async ({ company_id, listing_id }, thunkAPI,) => {

        try {
            const res = await fetch(`/api/reservations/listing/log/${company_id}/${listing_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("reservations/listing/log != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("reservations/listing/log Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);