import { createSlice } from '@reduxjs/toolkit';
import { registerCompany } from './reservationSys/RegisterCompany'; //funkcije outsourceane - Ne  koristimo, koristimo funkciju iz user
import { getWorkdaysAndTime } from './reservationSys/GetWorkdaysAndTime'; //funkcije outsourceane - Ne  koristimo, koristimo funkciju iz user
import { updateWorkdaysAndTime } from './reservationSys/UpdateWorkdaysAndTime';
import { createWorkdaysAndTime } from './reservationSys/CreateWorkdaysAndTime';
import { getWorkdaysAndTimeId } from './reservationSys/GetWorkdaysAndTimeId';
import { createEvents } from './eventSys/CreateEvent';
import { showEventsList } from './eventSys/ShowEventsList';
import { contactViewAll } from './reservationSys/ContactViewAll';
import { cardMenuViewAll } from './cardMenuSys/CardMenuViewAll';
import { contactsDelete } from './reservationSys/ContactsDelete';
import { messagesViewAll } from './messagesSys/MessagesViewAll';
import { tableCreate } from './tableSys/TableCreate';
import { tableViewDaily } from './tableSys/TableViewDaily';
import { tableViewEvent } from './tableSys/TableViewEvent';
import { tableDelete } from './tableSys/TableDelete';
import { tableUpdate } from './tableSys/TableUpdate';
import { reservationsView } from './reservationsRW/ReservationsView';
import { reservationsUpdate } from './reservationsRW/ReservationsUpdate';
import { reservationsDelete } from './reservationsRW/ReservationsDelete';
import { reservationListingCreate } from './reservationListingsSys/ReservationListingCreate';
import { reservationListingDelete } from './reservationListingsSys/ReservationListingDelete';
import { reservationListingUpdate } from './reservationListingsSys/ReservationListingUpdate';
import { reservationListingViewDaily } from './reservationListingsSys/ReservationListingViewDaily';
import { reservationListingChangelogView } from './reservationListingsSys/ReservationListingChangelogView';
import { floorplanView } from './floorplanSys/FloorplanView';
import { createFloorplan } from './floorplanSys/FloorplanCreate';
import { updateEvents } from './eventSys/UpdateEvents';
import { updateEventsPhotos } from './eventSys/UpdateEventsPhoto';
import { tableListCreate } from './tableSys/TableListCreate';
import { tableListsDelete } from './tableSys/TableListsDelete';
import { tableListsView } from './tableSys/TableListsView';

import { reservationSetupCreate } from './reservationSys/ReservationSetupCreate';
import { reservationSetupPatch } from './reservationSys/ReservationSetupPatch';
import { reservationSetupView } from './reservationSys/ReservationSetupView';

import { customSetupCreate } from './customSetup/CustomSetupCreate';
import { customSetupView } from './customSetup/CustomSetupView';

import { kpmgTestCreate } from './customSetup/kpmg/KpmgTestCreate';
import { kpmgTestDelete } from './customSetup/kpmg/KpmgTestDelete';
import { kpmgTestVievAll } from './customSetup/kpmg/KpmgTestVievAll';
import { kpmgTestView } from './customSetup/kpmg/KpmgTestView';
import { kpmgTestCreateReservation } from './customSetup/kpmg/KpmgTestCreateReservation';


const initialState = {
    loading: true,
    loadingCompany: false,

    access: false,
    manager: false,
    employee: false,
    userPermissions: null,

    company: null, //iz listinga
    currentView: null,

    workWeek: null,
    wwId: null, //id tjedna u bazi
    wwInitialized: false,

    eventState: null,
    lastCreatedEvent: null,
    eventList: [],
    updateEventList: false,

    contactList: [],

    menuCard: null,
    menuCardList: [],

    deleted: false,
    messages: [],

    //Prikazani
    guestList: null,

    //Dohvaćeni
    tablesDaily: null,
    tablesEvent: null,

    tablesLoad: false,
    tableExecutionQue: 0,

    reservationsList: [],
    reservationsEQ: 0,

    listingList: [],
    listingListChangeLog: [],
    listingEQ: 0,

    floorPlan: null,
    floorPlanChanged: false,
    tablesLists: [],
    tablesListChanged: false,
    tableListCreated: false,
    newTableList: null,
    tablesToAddToList: [],

    selectedTableOrder: -1,
    reservationListingCreated: false,

    reservation_setup_loading: false,
    reservation_setup_changed: false,
    reservation_setup_content: [],
    reservation_setup_content_does_not_exist: true,

    eventCreated: false,

    custom_setup: null,
    custom_setup_does_not_exist: true,

    kpmg_tests: [],
    kpmg_test_detail: null,
    kpmg_tests_change: false,
    kpmg_test_does_not_exist: false,
    kpmg_loading: false,
    kpmg_deleted_test: false,
    kpmg_test_reservation_created: false,

    // Include other initial state properties as needed
};

const reservationSlice = createSlice({
    name: 'reservation',
    initialState,
    reducers: {
        setSelectedTableOrder: (state, action) => {
            state.selectedTableOrder = action.payload;
        },
        updateTablesToAddToList: (state, action) => {
            state.tablesToAddToList = action.payload;
        },
        updateMenuCard: (state, action) => {
            state.menuCard = action.payload;
        },
        updateContactList: (state, action) => {
            state.contactList = action.payload;
        },
        updateEventState: (state, action) => {
            state.eventState = action.payload;
        },

        updateWorkWeek: (state, action) => {
            state.workWeek = action.payload;
        },
        updateCompanyInfo: (state, action) => {
            state.company = action.payload;
        },
        // Synchronous action creator to reset access
        resetAccess: state => {
            state.access = false;
        },
        // Synchronous action creator to set the current view
        setCurrentView: (state, action) => {
            state.currentView = action.payload;
        },
        setCompany: (state, action) => {
            state.company = action.payload;
        },
        // Add more reducers as needed
        setManager: (state, action) => {
            state.manager = action.payload;
        },
        resetReservationListingCreated: state => {
            state.reservationListingCreated = false;
        }
    },


    extraReducers: builder => { //     reservationsList: [], reservationsEQ: 0, floorplanView
        builder
            .addCase(kpmgTestCreateReservation.pending, state => {
                state.kpmg_loading = true;
            })

            .addCase(kpmgTestCreateReservation.fulfilled, (state, action) => {
                state.kpmg_loading = false;
                state.kpmg_test_reservation_created = true;
            })

            .addCase(kpmgTestCreateReservation.rejected, state => {
                state.kpmg_loading = false;
                state.kpmg_test_reservation_created = false;
            })
            .addCase(kpmgTestCreate.pending, state => {
                state.kpmg_loading = true;
            })

            .addCase(kpmgTestCreate.fulfilled, (state, action) => {
                state.kpmg_loading = false;
                state.kpmg_tests_change = true;
            })

            .addCase(kpmgTestCreate.rejected, state => {
                state.kpmg_loading = false;
                state.kpmg_tests_change = false;
            })
            .addCase(kpmgTestDelete.pending, state => {
                state.kpmg_loading = true;
            })

            .addCase(kpmgTestDelete.fulfilled, (state, action) => {
                state.kpmg_loading = false;
                state.kpmg_tests_change = true;
                state.kpmg_deleted_test = true;
            })

            .addCase(kpmgTestDelete.rejected, state => {
                state.kpmg_loading = false;
                state.kpmg_tests_change = false;
                state.kpmg_deleted_test = true;
            })
            .addCase(kpmgTestView.pending, state => {
                state.kpmg_loading = true;
                state.kpmg_test_does_not_exist = true;
            })

            .addCase(kpmgTestView.fulfilled, (state, action) => {
                state.kpmg_loading = false;
                state.kpmg_test_detail = action.payload;
                state.kpmg_test_does_not_exist = false;
                state.kpmg_tests_change = false;
                state.kpmg_deleted_test = false;
            })

            .addCase(kpmgTestView.rejected, state => {
                state.kpmg_loading = false;
                state.kpmg_tests_change = false;
                state.kpmg_deleted_test = false;

            })
            .addCase(kpmgTestVievAll.pending, state => {
                state.kpmg_loading = true;
            })

            .addCase(kpmgTestVievAll.fulfilled, (state, action) => {
                state.kpmg_loading = false;
                state.kpmg_tests = action.payload;
                state.kpmg_tet_does_not_exist = false;
            })

            .addCase(kpmgTestVievAll.rejected, state => {
                state.kpmg_loading = false;
                state.kpmg_tet_does_not_exist = true;
            })
            .addCase(customSetupView.pending, state => {
                state.reservation_setup_loading = true;
                state.custom_setup_does_not_exist = false;
            })

            .addCase(customSetupView.fulfilled, (state, action) => {
                state.reservation_setup_loading = false;
                state.custom_setup = action.payload;
            })

            .addCase(customSetupView.rejected, state => {
                state.reservation_setup_loading = false;
                state.custom_setup_does_not_exist = true;
            })

            .addCase(customSetupCreate.pending, state => {
                state.reservation_setup_loading = true;
            })

            .addCase(customSetupCreate.fulfilled, (state, action) => {
                state.reservation_setup_loading = false;

            })

            .addCase(customSetupCreate.rejected, state => {
                state.reservation_setup_loading = false;
            })

            .addCase(reservationSetupPatch.pending, state => {
                state.reservation_setup_loading = true;
            })
            .addCase(reservationSetupPatch.fulfilled, (state, action) => {
                state.reservation_setup_loading = false;
                state.reservation_setup_changed = true;
            })

            .addCase(reservationSetupPatch.rejected, state => {
                state.reservation_setup_loading = false;
            })
            .addCase(reservationSetupView.pending, state => {
                state.reservation_setup_loading = true;
                state.reservation_setup_changed = false;
                state.reservation_setup_content_does_not_exist = false;
            })
            .addCase(reservationSetupView.fulfilled, (state, action) => {
                state.reservation_setup_loading = false;
                state.reservation_setup_content = action.payload;
            })

            .addCase(reservationSetupView.rejected, state => {
                state.reservation_setup_loading = false;
                state.reservation_setup_content_does_not_exist = true;
            })
            .addCase(reservationSetupCreate.pending, state => {
                state.reservation_setup_loading = true;
            })

            .addCase(reservationSetupCreate.fulfilled, (state, action) => {
                state.reservation_setup_loading = false;
                state.reservation_setup_changed = true;
            })
            .addCase(reservationSetupCreate.rejected, state => {
                state.reservation_setup_loading = false;
            })

            .addCase(tableListCreate.pending, state => {
                state.loading = true;
            })
            .addCase(tableListCreate.fulfilled, (state, action) => {
                state.loading = false;
                state.tablesListChanged = true;
                state.tableListCreated = true;
                state.newTableList = action.payload;
            })
            .addCase(tableListCreate.rejected, state => {
                state.loading = false;
            })
            .addCase(tableListsDelete.pending, state => {
                state.loading = true;
            })
            .addCase(tableListsDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.tablesListChanged = true
            })
            .addCase(tableListsDelete.rejected, state => {
                state.loading = false;
            })
            .addCase(tableListsView.pending, state => {
                state.loading = true;
                state.tablesListChanged = false
            })
            .addCase(tableListsView.fulfilled, (state, action) => {
                state.loading = false;
                state.tablesLists = action.payload;
                state.tableListCreated = false;
            })
            .addCase(tableListsView.rejected, state => {
                state.loading = false;
                state.tableListCreated = false;
            })
            .addCase(floorplanView.pending, state => {
                state.loading = true;
                state.floorPlanChanged = false;
            })
            .addCase(floorplanView.fulfilled, (state, action) => {
                state.loading = false;
                state.floorPlan = action.payload;
            })
            .addCase(floorplanView.rejected, state => {
                state.loading = false;
            })
            .addCase(createFloorplan.pending, state => {
                state.loading = true;
                state.floorPlanChanged = false;
            })
            .addCase(createFloorplan.fulfilled, (state, action) => {
                state.loading = false;
                state.floorPlanChanged = true;
            })
            .addCase(createFloorplan.rejected, state => {
                state.loading = false;
            })
            .addCase(reservationListingChangelogView.pending, state => {
                state.listingEQ = state.listingEQ + 1;
                state.loading = true;
            })
            .addCase(reservationListingChangelogView.fulfilled, (state, action) => {
                state.listingEQ = state.listingEQ - 1;
                state.loading = false;
                state.listingListChangeLog = action.payload;
            })
            .addCase(reservationListingChangelogView.rejected, state => {
                state.listingEQ = state.listingEQ - 1;
                state.loading = false;
            })
            .addCase(reservationListingUpdate.pending, state => {
                state.listingEQ = state.listingEQ + 1;
            })
            .addCase(reservationListingUpdate.fulfilled, (state, action) => {
                state.listingEQ = state.listingEQ - 1;
            })
            .addCase(reservationListingUpdate.rejected, state => {
                state.listingEQ = state.listingEQ - 1;
            })
            .addCase(reservationListingCreate.pending, state => {
                state.listingEQ = state.listingEQ + 1;
            })
            .addCase(reservationListingCreate.fulfilled, (state, action) => {
                state.listingEQ = state.listingEQ - 1;
                state.reservationListingCreated = true;
            })
            .addCase(reservationListingCreate.rejected, state => {
                state.listingEQ = state.listingEQ - 1;
            })
            .addCase(reservationListingDelete.pending, state => {
                state.listingEQ = state.listingEQ + 1;
            })
            .addCase(reservationListingDelete.fulfilled, (state, action) => {
                state.listingEQ = state.listingEQ - 1;
            })
            .addCase(reservationListingDelete.rejected, state => {
                state.listingEQ = state.listingEQ - 1;
            })
            .addCase(reservationListingViewDaily.pending, state => {
                state.loading = true;
                state.listingEQ = 0;
            })
            .addCase(reservationListingViewDaily.fulfilled, (state, action) => {
                state.loading = false;
                state.listingList = action.payload;
                state.listingEQ = 0;
            })
            .addCase(reservationListingViewDaily.rejected, state => {
                state.loading = false;
                state.listingEQ = 0;
            })
            .addCase(reservationsDelete.pending, state => {
                state.reservationsEQ = state.reservationsEQ + 1;
            })
            .addCase(reservationsDelete.fulfilled, (state, action) => {
                state.reservationsEQ = state.reservationsEQ - 1;
            })
            .addCase(reservationsDelete.rejected, state => {
                state.reservationsEQ = state.reservationsEQ - 1;
            })
            .addCase(reservationsUpdate.pending, state => {
                state.reservationsEQ = state.reservationsEQ + 1;
            })
            .addCase(reservationsUpdate.fulfilled, (state, action) => {
                state.reservationsEQ = state.reservationsEQ - 1;
            })
            .addCase(reservationsUpdate.rejected, state => {
                state.reservationsEQ = state.reservationsEQ - 1;
            })
            .addCase(reservationsView.pending, state => {
                state.tablesLoad = true;
                state.reservationsEQ = 0;
            })
            .addCase(reservationsView.fulfilled, (state, action) => {
                state.tablesLoad = false;
                state.reservationsList = action.payload;
                state.reservationsEQ = 0;
            })
            .addCase(reservationsView.rejected, state => {
                state.tablesLoad = false;
                state.reservationsEQ = 0;
            })
            .addCase(tableUpdate.pending, state => {
                state.tablesLoad = true;
                state.tableExecutionQue = state.tableExecutionQue + 1;
            })
            .addCase(tableUpdate.fulfilled, (state, action) => {
                state.tablesLoad = false;
                state.tableExecutionQue = state.tableExecutionQue - 1;
            })
            .addCase(tableUpdate.rejected, state => {
                state.tablesLoad = false;
                state.tableExecutionQue = state.tableExecutionQue - 1;
            })
            .addCase(tableDelete.pending, state => {
                state.tablesLoad = true;
                state.tableExecutionQue = state.tableExecutionQue + 1;
            })
            .addCase(tableDelete.fulfilled, (state, action) => {
                state.tablesLoad = false;
                state.tableExecutionQue = state.tableExecutionQue - 1;
            })
            .addCase(tableDelete.rejected, state => {
                state.tablesLoad = false;
                state.tableExecutionQue = state.tableExecutionQue - 1;
            })
            .addCase(tableViewEvent.pending, state => {
                state.tablesLoad = true;
                state.tableExecutionQue = 0;
            })
            .addCase(tableViewEvent.fulfilled, (state, action) => {
                state.tablesLoad = false;
                state.tablesEvent = action.payload;
                state.tableExecutionQue = 0;
            })
            .addCase(tableViewEvent.rejected, state => {
                state.tablesLoad = false;
                state.tableExecutionQue = 0;
            })
            .addCase(tableViewDaily.pending, state => {
                state.tablesLoad = true;
                state.tableExecutionQue = 0;
            })
            .addCase(tableViewDaily.fulfilled, (state, action) => {
                state.tablesLoad = false;
                state.tablesDaily = action.payload;
                state.tableExecutionQue = 0;
            })
            .addCase(tableViewDaily.rejected, state => {
                state.tablesLoad = false;
                state.tableExecutionQue = 0
            })
            .addCase(tableCreate.pending, state => {
                state.tablesLoad = true;
                state.tableExecutionQue = state.tableExecutionQue + 1;
            })
            .addCase(tableCreate.fulfilled, (state, action) => {
                state.tablesLoad = false;
                state.tablesDaily = action.payload;
                state.tableExecutionQue = state.tableExecutionQue - 1;
            })
            .addCase(tableCreate.rejected, state => {
                state.tablesLoad = false;
                state.tableExecutionQue = state.tableExecutionQue - 1;
            })
            .addCase(messagesViewAll.pending, state => {
                state.loading = true;
            })
            .addCase(messagesViewAll.fulfilled, (state, action) => {
                state.loading = false;
                state.messages = action.payload;
            })
            .addCase(messagesViewAll.rejected, state => {
                state.loading = false;
            })
            .addCase(contactsDelete.fulfilled, state => {
                state.deleted = true;
            })
            .addCase(cardMenuViewAll.pending, state => {
                state.loading = true;
            })
            .addCase(cardMenuViewAll.fulfilled, (state, action) => {
                state.loading = false;
                state.menuCardList = action.payload;
            })
            .addCase(cardMenuViewAll.rejected, state => {
                state.loading = false;
            })
            .addCase(contactViewAll.pending, state => {
                state.loading = true;
                state.deleted = false;
            })
            .addCase(contactViewAll.fulfilled, (state, action) => {
                state.loading = false;
                state.contactList = action.payload;
            })
            .addCase(contactViewAll.rejected, state => {
                state.loading = false;
            })
            .addCase(showEventsList.pending, state => {
                state.loading = true;
                state.updateEventList = false;
                state.eventCreated = false;
            })
            .addCase(showEventsList.fulfilled, (state, action) => {
                state.loading = false;
                state.eventList = action.payload;
            })
            .addCase(showEventsList.rejected, state => {
                state.loading = false;
            })
            .addCase(updateEventsPhotos.pending, state => {
                state.loading = true;
            })
            .addCase(updateEventsPhotos.fulfilled, (state, action) => {
                state.loading = false;
                state.updateEventList = true;
            })
            .addCase(updateEventsPhotos.rejected, state => {
                state.loading = false;
                state.updateEventList = true;
            })
            .addCase(updateEvents.pending, state => {
                state.loading = true;
            })
            .addCase(updateEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.updateEventList = true;
            })
            .addCase(updateEvents.rejected, state => {
                state.loading = false;
                state.updateEventList = true;
            })
            .addCase(createEvents.pending, state => {
                state.loading = true;
            })
            .addCase(createEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.eventCreated = true;
                state.lastCreatedEvent = action.payload;
            })
            .addCase(createEvents.rejected, state => {
                state.loading = false;
            })
            .addCase(registerCompany.pending, state => {
                state.loading = true;
            })
            .addCase(registerCompany.fulfilled, state => {
                state.loading = false;
            })
            .addCase(registerCompany.rejected, state => {
                state.loading = false;
            })
            .addCase(getWorkdaysAndTime.pending, state => {
                state.loadingCompany = true;
            })
            .addCase(getWorkdaysAndTime.fulfilled, (state, action) => {
                state.loadingCompany = false;
                state.wwInitialized = true;
                state.workWeek = action.payload;
            })
            .addCase(getWorkdaysAndTime.rejected, state => {
                state.loadingCompany = false;
                state.wwInitialized = false;
            })
            .addCase(updateWorkdaysAndTime.pending, state => {
                state.loadingCompany = true;
            })
            .addCase(updateWorkdaysAndTime.fulfilled, (state, action) => {
                state.loadingCompany = false;
                state.workWeek = action.payload;
            })
            .addCase(updateWorkdaysAndTime.rejected, state => {
                state.loadingCompany = false;
            })
            .addCase(createWorkdaysAndTime.pending, state => {
                state.loadingCompany = true;
            })
            .addCase(createWorkdaysAndTime.fulfilled, (state, action) => {
                state.loadingCompany = false;
                state.workWeek = action.payload;
                state.wwInitialized = true;
            })
            .addCase(createWorkdaysAndTime.rejected, state => {
                state.loadingCompany = false;
            })
            .addCase(getWorkdaysAndTimeId.pending, state => {
                state.loadingCompany = true;
            })
            .addCase(getWorkdaysAndTimeId.fulfilled, (state, action) => {
                state.wwId = action.payload;
            })
            .addCase(getWorkdaysAndTimeId.rejected, state => {
                state.loadingCompany = false;
            })
            ;
    }
});

// Export actions
export const { resetAccess, setCurrentView, setCompany, setManager, updateCompanyInfo, updateWorkWeek,
    updateEventState, updateContactList, updateMenuCard, updateTablesToAddToList, setSelectedTableOrder, resetReservationListingCreated
} = reservationSlice.actions;
export const selectCompany = (state) => state.reservation.company;

// Export the reducer
export default reservationSlice.reducer;
