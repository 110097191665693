import { createAsyncThunk } from '@reduxjs/toolkit';
import { json } from 'react-router-dom';

export const createWorkdaysAndTime = createAsyncThunk(
    'reservations/company/workweek/create',
    async (payload, thunkAPI) => {
        const { data, company_id } = payload;


        try {
            const res = await fetch('/api/reservations/company/workweek/create', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data, company_id }),
            });

            const responseData = await res.json(); // Renamed from 'data' to 'responseData'

            if (res.status === 200) {
                return responseData; // Use 'responseData' here
            } else {
                console.log("GetWorkdaysAndTime != 200 -----------------------");
                return thunkAPI.rejectWithValue(responseData); // Use 'responseData' here
            }

        } catch (err) {
            console.log("GetWorkdaysAndTime Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);
