import React, { useState } from 'react';
import Calendar from '../forms/Calendar';
import { useDispatch, useSelector } from 'react-redux';
import { userRequestCreate } from 'features/frontpage/UserRequestCreate';
import { useTranslation } from 'react-i18next';

const ContactFormPublic = ({disabled = false}) => {
    const initialContactData = {
        name: '',
        surname: '',
        email: '',
        phone_prefix: '+38591', // Default prefix
        phone_number: '', // User-entered number
        custom_phone_prefix: '', // Custom prefix entered by user
    };

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [contactData, setContactData] = useState(initialContactData);
    const [errors, setErrors] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);

    const { frontpageView } = useSelector(state => state.frontpage);

    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactData((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'phone_prefix' && value !== 'other' && { custom_phone_prefix: '' })
        }));
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!contactData.name.trim()) tempErrors.name = `${t('error_name_is_obligatory')}`;
        if (!contactData.surname.trim()) tempErrors.surname = `${t('error_surname_is_obligatory')}`;
        if (!contactData.email.trim()) tempErrors.email = `${t('error_email_is_obligatory')}`;
        if (!contactData.phone_number.trim()) tempErrors.phone_number = `${t('error_phone_is_obligatory')}`;
        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    

    const handleCreateContactLocal = () => {
        if (!validateForm()) return;
        if (!frontpageView) return;

        const payload = {
            company_id: frontpageView.company_id,
            name: contactData.name,
            surname: contactData.surname,
            email: contactData.email,
            phone_prefix: contactData.phone_prefix, // Default prefix
            phone_number: contactData.phone_number, // User-entered number
            custom_phone_prefix: contactData.custom_phone_prefix, // Custom prefix entered by user
            selected_date: selectedDate,
        };

        console.log("payload=====>" + JSON.stringify(payload));
        dispatch(userRequestCreate(payload));
        
    };

    const convertDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${day}.${month}.${year}`;
    };

    const options = [
        { value: '+38591', label: '+38591' },
        { value: '+38592', label: '+38592' },
        { value: '+38593', label: '+38593' },
        { value: '+38594', label: '+38594' },
        { value: '+38595', label: '+38595' },
        { value: '+38596', label: '+38596' },
        { value: '+38597', label: '+38597' },
        { value: '+38598', label: '+38598' },
        { value: '+38599', label: '+38599' },
        { value: 'other', label: 'Other' }
    ];

    return (
        <>
            <h2 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px', marginTop: "20px" }}>{t('request_reservation_for')} {convertDate(selectedDate)}</h2>
            <div className="input-field">
                        <input
                            className="input"
                            type="date"
                            name="selectedDate"
                            value={selectedDate}
                            onChange={e => setSelectedDate(e.target.value)}
                            style={{backgroundColor: "white"}}
                            required
                            disabled = {disabled}
                        />
                    </div>
            <div>
                <div className="input-field">
                    <input
                        className="input"
                        type="text"
                        id="name"
                        name="name"
                        placeholder={t('name_label')}
                        value={contactData.name}
                        onChange={handleChange}
                        style={{backgroundColor: "white"}}
                        disabled = {disabled}
                        required
                    />
                    {errors.name && <div className="validation-message">{errors.name}</div>}
                </div>
                <div className="input-field">
                    <input
                        className="input"
                        type="text"
                        id="surname"
                        name="surname"
                        placeholder={t('surname_label')}
                        value={contactData.surname}
                        onChange={handleChange}
                        style={{backgroundColor: "white"}}
                        disabled = {disabled}
                        required
                    />
                    {errors.surname && <div className="validation-message">{errors.surname}</div>}
                </div>
                <div className="input-field">
                    <input
                        className="input"
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email:"
                        value={contactData.email}
                        onChange={handleChange}
                        style={{backgroundColor: "white"}}
                        disabled = {disabled}
                        required
                    />
                    {errors.email && <div className="validation-message">{errors.email}</div>}
                </div>
                <div className="input-field" style={{ display: 'flex', alignItems: 'center' }}>
                {contactData.phone_prefix !== 'other' && (
                    <select
                        className="input"
                        id="phone_prefix"
                        name="phone_prefix"
                        value={contactData.phone_prefix}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        required
                        disabled = {disabled}
                        style={{ marginRight: '8px', width: "30%", backgroundColor: "white" }}
                    >
                        {options.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                    )}
                    {contactData.phone_prefix === 'other' && (
                        <input
                            className="input"
                            type="text"
                            id="custom_phone_prefix"
                            name="custom_phone_prefix"
                            placeholder="Prefix"
                            value={contactData.custom_phone_prefix}
                            onChange={handleChange}
                            required
                            disabled = {disabled}
                            style={{ marginRight: '8px', width: "30%", backgroundColor: "white" }}
                        />
                    )}
                    <input
                        className="input"
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        placeholder={t('phone_number_label')}
                        value={contactData.phone_number}
                        onChange={handleChange}
                        style={{backgroundColor: "white"}}
                        disabled = {disabled}
                        required
                    />
                    {errors.phone_number && <div className="validation-message">{errors.phone_number}</div>}
                </div>
            </div>
            <div>
                <span style={{ display: 'block', height: '10px' }}></span>
                {disabled ? (<></>) :(<button type="button" className="edit-button" style={{ width: '100%' }} onClick={handleCreateContactLocal} disabled={disabled}>
                {t('send_request')}
                </button>) }
                
                <span style={{ display: 'block', height: '10px' }}></span>
            </div>
        </>
    );
};

export default ContactFormPublic;
