import Layout from "components/Layout";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import FlipPanel from "./components/cards/FlipPanel";
import GlassPanelWithChild from "./components/cards/GlassPanelWithChild";
import React, { useState, useEffect } from 'react';
import { companyExistenceCheck } from "features/publicSys/CompanyExistenceCheck";
import { companyPublicData } from "features/publicSys/CompanyPublicData";
import NumberPad from "./components/NumberPad";
import ReservationTimeSelector from "./components/ReservationTimeSelector";
import ContactFormPublic from "./components/ContactFormPublic";
import ReservationMenuSelector from "./components/ReservationMenuSelector";
import ModalWithChildren from "containers/modals/ModalWithChildren";
import { cardMenuViewAllPublic } from "features/cardMenuSys/CardMenuViewAllPublic";
import FlipCard from "containers/components/cards/FlipCard";
import GlassCard from "containers/components/cards/GlassCard";
import ReservationConfirm from "./components/ReservationConfirm";
import { verifyContactA } from "features/publicSys/VerifyContactA";
import { verifyReservation } from "features/publicSys/VerifyReservation";
import { reservationSetupView } from "features/reservationSys/ReservationSetupView";

import ReservationTimeSelectorFromTo from "./components/ReservationTimeSelectorFromTo";
import ReservationTimeSelectorFromDefined from "./components/ReservationTimeSelectorFromDefined";
import ExtraOptionSelector from "./components/ExtraOptionSelector";
import ReservationExtraText from "./components/ReservationExtraText";


import { useTranslation } from 'react-i18next';

const RequestReservationPageAlt = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { yyyymmdd, companyName, companyId } = useParams();
    const { loadingCompanyExists, companyExists } = useSelector(state => state.public);
    const { menuCardList, companyData } = useSelector(state => state.public);
    const { contactId, reservationError, requestStarted } = useSelector(state => state.public);
    const { reservation_setup_loading, reservation_setup_content } = useSelector(state => state.reservation);
    const [valid, setValid] = useState(true);
    const [peopleNum, setPeopleNum] = useState("0");
    const [timeFrame, setTimeFrame] = useState(null);
    const [tabIndex, setTabIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [rezError, setRezError] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    const formatDateFromYyyymmdd = (yyyymmdd) => {
        const year = yyyymmdd.substring(0, 4);
        const month = yyyymmdd.substring(4, 6);
        const day = yyyymmdd.substring(6, 8);
        return `${day}.${month}.${year}`;
    };

    const [cardData, setCardData] = useState({

        name: companyData?.name ?? "",
        about_us: companyData?.about_us ?? "",
        address: companyData?.address ?? "",

        selected_date: formatDateFromYyyymmdd(yyyymmdd),
        listItemInput: ''
    });

    const initialContactData = {
        name: '',
        surname: '',
        email: '',
        phone_prefix: '+38591', // Default prefix
        phone_number: '', // User-entered number
        custom_phone_prefix: '',
    };

    const [contactData, setContactData] = useState(initialContactData);
    const [commentData, setCommentData] = useState("");

    // State to track whether the card is flipped
    const [isFlipped, setIsFlipped] = useState(false);


    const [formData, setFormData] = useState({
        message_template: '',
        defined_time: '01:00', //Timeframe
        defined_time_frames: [], //Lista od - do
        time_from: '',
        time_to: '',
        minimum_guests_count: '0',
        custom_elements: [],
        listItemInput: '',
        extra_text: '',
        card_background_image: '',
    });

    const [timeFrame_ft, setTimeFrame_ft] = useState(true);
    const [timeFrame_fDef, setTimeFrame_fDef] = useState(false);
    const [timeFrame_custom, setTimeFrame_custom] = useState(false);
    const [enable_min_guest_count, set_enable_min_guest_count] = useState(false);
    const [show_menu, set_show_menu] = useState(true);
    const [menu_mandatory, set_menu_mandatory] = useState(false);
    const [select_one, set_select_one] = useState(false);
    const [select_many, set_select_many] = useState(false);
    const [show_extra_text, set_show_extra_text] = useState(false);

    const [reservation_setup, setReservation_setup] = useState({
        message_template: "",
        time_free_choice: false,
        time_defined_length: true,
        time_defined_spread: false,
        defined_time: null,
        time_from: null,
        time_to: null,
        show_menu: true,
        obligatory_menu: false,
        require_minimum_guests: false,
        minimum_guests_count: 1,
        custom_elements_select_many: false,
        show_extra_text: false,
        extra_text: "",
        specific_items_select_one: false,
    });

    const [customElement, setCustomElement] = useState([]);

    useEffect(() => {
        if (reservation_setup_content && reservation_setup_content[0]) {
            setReservation_setup(reservation_setup_content[0]);
        }

    }, [dispatch, reservation_setup_content]);

    // Function to toggle the flip state
    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };

    // Effect for dispatching the action
    useEffect(() => {
        const payload = {
            company_slug: companyName,
            company_id: companyId,
        };
        dispatch(companyExistenceCheck(payload));
    }, [dispatch, companyName, companyId]); // Removed applyValidity from dependencies

    useEffect(() => {
        setCardData({
            name: companyData?.name ?? "",
            about_us: companyData?.about_us ?? "",
            address: companyData?.address ?? "",
            selected_date: formatDateFromYyyymmdd(yyyymmdd),
            listItemInput: '',
        });
    }, [companyData, yyyymmdd]);

    useEffect(() => {
        if (companyExists && !companyData) {
            const payload = {
                company_id: companyId,
            };
            dispatch(companyPublicData(payload));
            dispatch(reservationSetupView(payload));
        }
    }, [dispatch, companyExists, companyData, companyId]);



    // Effect to apply validity based on companyExists state
    useEffect(() => {
        // Make sure companyExists is not undefined before attempting to access its properties
        if (companyExists !== null) {
            setValid(companyExists.exists);
        }
    }, [companyExists]);

    useEffect(() => {
        // Make sure companyExists is not undefined before attempting to access its properties
        if (reservationError) {
            setRezError(reservationError);
        }
    }, [reservationError]);

    //Dovuci menije
    useEffect(() => {

        if (!menuCardList || menuCardList.length === 0) {
            const payload = {
                company_id: companyId,
            }
            //console.log("Dovuci menije")
            dispatch(cardMenuViewAllPublic(payload));
        }
    }, [dispatch, menuCardList]);

    useEffect(() => {
        if (selectedMenu) {
            console.log(selectedMenu);
            // Perform any action you need with the updated selectedMenu here
        }
    }, [selectedMenu]);

    useEffect(() => {
        if (reservation_setup_content[0]) {
            const dat = reservation_setup_content[0];

            const payload = {
                message_template: dat?.message_template ?? "",
                defined_time: dat?.defined_time ?? "", //Timeframe
                defined_time_frames: dat?.defined_time_frames ?? [], //Lista od - do
                time_from: dat?.time_from ?? "",
                time_to: dat?.time_to,
                minimum_guests_count: dat?.minimum_guests_count ?? "",
                custom_elements: dat?.custom_elements ?? [],
                listItemInput: '',
                extra_text: dat?.extra_text ?? "",
                card_background_image: '',
            };

            console.log(JSON.stringify(payload));
            setCustomElement(dat?.custom_elements ?? [])
            setFormData(payload);
            setTimeFrame_ft(dat.time_free_choice);
            setTimeFrame_fDef(dat.time_defined_length);
            setTimeFrame_custom(dat.time_defined_spread);
            set_enable_min_guest_count(dat.require_minimum_guests);
            set_show_menu(dat.show_menu);
            set_menu_mandatory(dat.obligatory_menu);
            set_select_one(dat.specific_items_select_one);
            set_select_many(dat.specific_items_select_many);
            set_show_extra_text(dat.show_extra_text);
        }

    }, [reservation_setup_content]);

    const okClickedNumPad = () => {
        //console.log(peopleNum);
        if (peopleNum !== "0") {
            handleFlip();
        }
    };

    const okClickedTimeFrame = () => {
        console.log(timeFrame);
        if (timeFrame != null) {
            handleFlip();
            setTabIndex(tabIndex + 2);
        }
    };

    const handleUpdateContact = () => {
        console.log(contactData);
        handleFlip();

    };

    const pressBackContact = () => {
        setTabIndex(tabIndex - 2);
        handleFlip();

    };

    const onSelectMenu = (menuCard) => {
        setSelectedMenu(menuCard);
        setShowModal(false);
    };


    const showMenu = () => {
        setShowModal(true);
    };

    const pressBackMenu = () => {
        console.log("pressBackMenu");
        handleFlip();
    };


    const okClickedMenu = () => {
        // if (selectedMenu) { Meniji neobavezni
        handleFlip();
        setTabIndex(tabIndex + 2);

    };

    const pressBackMenuResConfirm = () => {
        handleFlip();
        setTabIndex(tabIndex - 2);
    };

    const formatPhoneNumber = () => {
        if (contactData) {

            console.log("xxx" + JSON.stringify(contactData));

            if (contactData.phone_prefix === "other") {
                return contactData.custom_phone_prefix + contactData.phone_number;
            } else {
                return contactData.phone_prefix + contactData.phone_number;
            }
        }

        return "";
    };


    //OVDJE SREDI UNOS DATUMA
    const info = () => {
        const finalInfo = {
            nameAndSurname: contactData ? contactData.name + " " + contactData.surname : "",
            gsm: formatPhoneNumber(),
            peopleCount: peopleNum,
            menu: selectedMenu ? selectedMenu.menu_name : "",
            timeSpan: timeFrame ? timeFrame.time_from + " - " + timeFrame.time_to : "",
            onDate: formatDateFromYyyymmdd(yyyymmdd),
        };
        return finalInfo;
    };


    const convertDateYYYYMMDD = (dateStr) => {
        if (dateStr.length === 8) {
            const year = dateStr.substring(0, 4);
            const month = dateStr.substring(4, 6);
            const day = dateStr.substring(6, 8);
            return `${year}-${month}-${day}`;
        } else {
            return "Invalid date format";
        }
    };

    const returnPrefix = () => {
        if (contactData) {

            if (contactData.phone_prefix === "other") {
                return contactData.custom_phone_prefix;
            } else {
                return contactData.phone_prefix;
            }
        }

        return "+00000";
    };

    const sendReservationRequest = () => {
        const contact = {
            company_id: companyId,
            name: contactData.name,
            surname: contactData.surname,
            email: contactData.email,
            phone_prefix: returnPrefix(), // Default prefix
            phone_number: contactData.phone_number, // User-entered number
        }

        //setTabIndex(tabIndex + 2);
        //handleFlip();
        setRequestSent(true);
        dispatch(verifyContactA(contact));
        console.log("contactId=====>" + JSON.stringify(contactId));
        //console.log("sendReservationRequest=====>" + JSON.stringify(contact));
    };


    useEffect(() => {
        // Make sure companyExists is not undefined before attempting to access its properties
        console.log("contactId=====>" + JSON.stringify(contactId));
    }, [contactId]);

    useEffect(() => {
        if (!reservationError && requestStarted && contactId) {
            const payload = {
                company_id: companyId,
                contact_id: contactId.contact_id,
                people_num: peopleNum,
                reservation_date: convertDateYYYYMMDD(yyyymmdd),
                menu_id: selectedMenu ? selectedMenu.id : "",
                time_from: timeFrame.time_from,
                time_to: timeFrame.time_to,
                comment: commentData,
            };
            console.log("TIMEFRAME" + JSON.stringify(timeFrame));
            dispatch(verifyReservation(payload));
            console.log("verifyReservation=====>" + JSON.stringify(payload)); //OVAJ TU SPREMI
        }
    }, [dispatch, reservationError, requestStarted, contactId, peopleNum, yyyymmdd, selectedMenu, timeFrame, companyId]);


    const handleNext2 = () => {
        console.log("filteredComponents.length: " + filteredComponents.length);
        setTabIndex((prevIndex) => {
            let newIndex = prevIndex + 2;
            //if (newIndex >= filteredComponents.length) {
            //    newIndex = filteredComponents.length - 1;  // Set to last index if exceeding bounds
            //}
            return newIndex;
        });
    };

    const handlePrevious2 = () => {
        setTabIndex((prevIndex) => {
            let newIndex = prevIndex - 2;
            if (newIndex < 0) {
                newIndex = 0;  // Set to zero if going below bounds
            }
            return newIndex;
        });
    };

    const okNext = (index, order) => {
        const x = Number(order);
        console.log("okNext: " + index);

        if (x === 2) {
            handleFlip();
            handleNext2();
        } else {
            handleFlip();
        }
        console.log("okNext 2: " + index);
    };

    const okBack = (index, order) => {
        console.log("okBack: " + index);
        const x = Number(order);
        if (x === 2) {
            handleFlip();
        } else {
            handleFlip();
            handlePrevious2();
        }
        console.log("okBack2: " + index);
    };

    const displayedTimeSelector = () => {

        //ReservationTimeSelectorFromTo
        if (timeFrame_ft) return (<>
            <ReservationTimeSelectorFromTo
                okClicked={okClickedTimeFrame}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                bckButton={handleFlip}
            />
        </>);

        //ReservationTimeSelectorFromDefined
        if (timeFrame_fDef) return (<>
            <ReservationTimeSelectorFromDefined
                okClicked={okClickedTimeFrame}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                bckButton={handleFlip}
                duration={reservation_setup.defined_time} />
        </>);

        if (timeFrame_custom) return (<>
            <ReservationTimeSelector
                timeFrames={formData.defined_time_frames}
                okClicked={okClickedTimeFrame}
                timeFrame={timeFrame}
                setTimeFrame={setTimeFrame}
                bckButton={handleFlip} />
        </>);

    };

    const numpad = (index, order) => {
        return <GlassPanelWithChild formData={cardData}
            child={<NumberPad
                okClicked={okClickedNumPad}
                peopleNum={peopleNum}
                setPeopleNum={setPeopleNum}
                reservation_setup_content={reservation_setup} />} />;
    };

    const timeSelectors = (index, order) => {
        return <GlassPanelWithChild formData={cardData} child={displayedTimeSelector()} />;
    };

    const contactForms = (index, order) => {
        return <GlassPanelWithChild formData={cardData}
            child={<ContactFormPublic
                pressBack={() => okBack(index, order)}
                contactDataParent={contactData}
                setContactDataParent={setContactData}
                handleUpdateContact={() => handleUpdateContact(index, order)} />} />;
    };

    const menuSelector = (index, order) => {
        return <GlassPanelWithChild formData={cardData}
            child={<ReservationMenuSelector
                selectedMenu={selectedMenu}
                setSelectedMenu={setSelectedMenu}
                showMenu={showMenu}
                okClicked={() => okNext(index, order)}
                bckButton={() => okBack(index, order)} />} />;
    };


    const reservationConfirmComponents_confirmator = (index, order) => {
        return <GlassPanelWithChild formData={cardData}
            child={<ReservationConfirm info={info()}
                setCommentParent={setCommentData}
                sendReservationRequest={() => sendReservationRequest()}
                bckButton={() => okBack(index, order)} />} />;
    };

    const okClickedCustomElement = (index, order) => {
        okNext(index, order);
    };

    /**ustom_elements, custom_element, set_custom_element, okClicked, bckButton */
    const extraOptions = (index, order) => {
        return <GlassPanelWithChild formData={cardData}
            child={<ExtraOptionSelector
                custom_elements={customElement}
                set_custom_elements={setCustomElement}
                okClicked={() => okClickedCustomElement(index, order)}
                bckButton={() => okBack(index, order)}
                select_one={select_one} />} />;

    };

    const extraText = (index, order) => {
        return <GlassPanelWithChild formData={cardData}
            child={<ReservationExtraText text={formData.extra_text} okClicked={() => okNext(index, order)} bckButton={() => okBack(index, order)} />} />;

    };

    const reportMessage = () => {

        if (rezError) {
            return (<><div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35vh' }} >{t('register_error_text')}</div></>);
        } else {
            return (<><div style={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35vh' }} >
                <p>
                    {t('register_success_text')}
                </p>
            </div></>);
        }

    };

    const finalMessage = (index, order) => {
        return <GlassPanelWithChild formData={cardData} child={
            <>
                {requestStarted || reservation_setup_loading ? (
                    <div className="number-pad" >
                        <span style={{ display: 'block', width: '260px' }}></span>
                        <div style={{ maxWidth: "250px", marginLeft: "-185px" }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35vh' }}>
                                <div className="spinner-border text-primary mt-4" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>

                            </div>
                        </div>
                    </div>

                ) : (
                    <>
                        <div className="number-pad" >
                            <span style={{ display: 'block', width: '260px' }}></span>
                            <div style={{ maxWidth: "250px", marginLeft: "-85px" }}>
                                {reportMessage()}
                            </div>
                        </div>
                    </>
                )}
            </>
        } />;
    };


    const reservationConfirmComponents = (index, order) => {

        if (!requestSent) {
            return reservationConfirmComponents_confirmator(index, order);

        } else {
            return finalMessage(index, order);
        }

    };

    // Array of components
    const childComponents = [
        numpad,
        timeSelectors,
        contactForms,
        menuSelector,
        extraOptions,
        extraText,
        reservationConfirmComponents,
        //finalMessage,
    ];

    // Array of booleans to decide visibility
    const showComponents = [
        true,  // show numpad
        true,  // show timeSelectors
        true,  // show contactForms
        show_menu === true,  // dynamically decide whether to show menuSelector
        (select_one || select_many) === true,
        show_extra_text === true,
        true,   // show reservationConfirmComponents
        //true,
    ];

    // Filter the components based on the showComponents array
    const filteredComponents = childComponents.filter((component, index) => showComponents[index]);

    // Log the filtered components
    console.log("tabIndex: ", tabIndex);



    const getMenuCardList = () => {

        if (menuCardList && menuCardList.length > 0) { // Check if menuCardList exists and has items

            return (
                <>
                    <div className="row col-lg-12 col-md-12 col-sm-12" style={{ width: "100%" }}>
                        {menuCardList.map((menuCard, index) => (
                            <div key={index} className="col-lg-4 col-md-8 col-sm-12" style={{ marginBottom: '50px' }}>
                                <FlipCard
                                    child1={<GlassCard formData={menuCard} showAltDate={true} altDate={formatDateFromYyyymmdd(yyyymmdd)} />}
                                    enableFlip={false}
                                />
                                <div style={{ marginTop: '10px' }}>
                                    <button className="btn btn-primary" onClick={() => onSelectMenu(menuCard)}>Odaberi</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        } else {

            return (
                <div>
                    <h5>{t('choose_menu')}</h5>
                    <p>Nema dostupnih menija</p>
                </div>
            );
        }
    };


    const generatedCard = () => {
        if (!reservation_setup_content || !reservation_setup_content[0]) return;

        const nextIndex = tabIndex + 1;
        const child2Component = nextIndex < filteredComponents.length
            ? filteredComponents[nextIndex](nextIndex, 2)
            : filteredComponents[filteredComponents.length - 1](filteredComponents.length - 1, 2);

        return (
            <div>
                <span style={{ display: 'block', height: '50px' }}></span>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top' }}>
                    <FlipPanel
                        child1={filteredComponents[tabIndex](tabIndex, 1)}
                        child2={child2Component}
                        isFlipped={isFlipped}
                    />
                </div>
                <span style={{ display: 'block', height: '50px' }}></span>

                <ModalWithChildren isOpen={showModal} onClose={() => setShowModal(false)} renderSave={false} renderDelete={false} style={{ width: "100%" }}>
                    {getMenuCardList()}
                </ModalWithChildren>
            </div>
        );
    };



    if (loadingCompanyExists) {
        return (

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="spinner-border text-primary mt-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>

            </div>

        );
    } else if (valid === false) {
        return (
            <Layout title='Sonex | Zahtjev za rezervacijom' content='Not found'>
                <h1>404 Not Found</h1>
            </Layout>
        );
    } else if (valid === true) {
        return (
            <Layout title={`Request Reservation for ${companyName}`}>
                {generatedCard()}
                <span style={{ display: 'block', height: '50px' }}></span>
                <ModalWithChildren isOpen={showModal} onClose={() => setShowModal(false)} renderSave={false} renderDelete={false} style={{ width: "100%" }}>
                    {getMenuCardList()}
                </ModalWithChildren>
            </Layout>
        );
    } else {
        // Return null or a loading spinner if the validity has not been determined yet.
        return <div>Loading...</div>;
    }
};

export default RequestReservationPageAlt;