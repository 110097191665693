import React, { useState } from 'react';
import Layout from "components/Layout";
import Pic from "containers/images/minit_logo.jpg";

const FlipPanel = ({ child1, child2, isFlipped }) => {

    return (
        <>
            <div className="fpp_card">
                <div className={`fp_content ${isFlipped ? 'flipped' : ''}`}> 
                    <div className="fp_back">
                        <div className="fp_back-content" style={{ backgroundImage: `url(${Pic})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: "99.5%", height: "99.5%"}}>
                            {child1}
                        </div>
                    </div>
                    <div className="fp_front">

                    <div className="fp_back-content" style={{ backgroundImage: `url(${Pic})`,  backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: "99.5%", height: "99.5%" }}>
                            {child2}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default FlipPanel;
