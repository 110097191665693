import Layout from "components/Layout";
import React from 'react';
import '../App.css';

import logo from "../2.png";
import { useTranslation } from 'react-i18next';



const Terms = () => {
    const { t } = useTranslation();



    return (
        <Layout title="Sonex | Safety" content="Home page">
            <div className="container" style={{ paddingTop: '50px' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img src={logo}
                                        style={{
                                            maxWidth: "220px",
                                            height: "auto",
                                            borderRadius: '10px',
                                        }}
                                        alt="Logo"
                                    />
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <p>
                                        <ul>
                                            <li>{t('frontpage_text_1')}</li>

                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray", minHeight: "600px" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>OSNOVNI PODACI O PRODAVATELJU</h3>

                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>

                                    <ul style={{ marginLeft: "20px" }}>
                                        <li>InnoLink.doo</li>
                                        <li>Adresa: Dobriše Cesarića 7, 51000 Rijeka, Hrvatska</li>
                                        <li>OIB: 79707251376</li>
                                        <li>Mb: 05920493</li>
                                        <li>Mob: +38592 2862 492</li>
                                        <li>Mail: info@sonexapp.com</li>
                                    </ul>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>UGOVOR O KUPOPRODAJI</h3>

                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>

                                    <h5>Potrošač, u svojstvu kupca i InnoLink d.o.o. u svojstvu prodavatelja sklapaju</h5>
                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Pravne osobe kao kupci podliježu primjeni Zakona o obveznim odnosima i Zakona o elektroničkoj trgovini,
                                        te se na njih ne primjenjuje Zakon o zaštiti potrošača. Ovi Opći uvjeti poslovanja se primjenjuju na pravne osobe u dijelu
                                        koji se odnosi na glavna obilježja proizvoda, postupak sklapanja ugovora, cijenu proizvoda, način plaćanja i otpremu proizvoda,
                                        opis načina isporuke proizvoda i troškove dostave, opće informacije, jamstvo i servisne uvjete, klub vjernosti,
                                        odricanje od odgovornosti te trenutak sklapanja ugovora. Prodavatelj može prema svome izboru pravnoj osobi omogućiti u svakom konkretnom
                                        slučaju prava koja ima kupac koji je potrošač.
                                    </p>
                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Korisnik je osoba koja koristi web stranicu www.sonexapp.hr, isto kao i svaki kupac i posjetitelj web stranice www.sonexapp.hr
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Sklapanje ugovora o kupoprodaji putem web stranice www.sonexapp.hr regulirano je u skladu sa zakonskim odredbama uzimajući u obzir naročito načela i odredbe direktiva Europske unije.
                                        Sklapanje ugovora putem web stranice www.sonexapp.hr predstavlja sklapanje ugovora na daljinu.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Opći uvjeti poslovanja predstavljaju i predugovornu obavijest, te se odnose na sklapanje ugovora o kupoprodaji ukoliko je kupac potrošač odnosno svaka fizička osoba
                                        koja sklapa pravni posao ili djeluje na tržištu izvan svoje trgovačke, poslovne, obrtničke ili profesionalne djelatnosti, te ukoliko se ugovor sklapa između trgovca i
                                        potrošača u okviru organiziranog sustava prodaje ili pružanja usluge bez istodobne fizičke prisutnosti trgovca i potrošača na jednome mjestu pri čemu se do trenutka
                                        sklapanja ugovora te za sklapanje ugovora isključivo koristi jedno ili više sredstava daljinske komunikacije.

                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Sredstva daljinske komunikacije jesu sva sredstva koja se bez istodobne fizičke prisutnosti trgovca i potrošača mogu koristiti za sklapanje ugovora na daljinu,
                                        kao što je internet i elektronička pošta.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ugovor je sklopljen kada prodavatelj prihvati ponudu kupca, te sve navedeno na stranicama InnoLink d.o.o. predstavlja poziv na stavljanje ponude. Prodavatelj može raskinuti ugovor o kupoprodaji, ako kupac ne uplati kupoprodajnu cijenu,
                                        te nije dužan izvršiti isporuku proizvoda do trenutka primitka kupoprodajne cijene.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Sastavni dio ovih Općih uvjeta poslovanja su i Opći uvjeti zaštite osobnih podataka.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Sadržaj web stranice  www.sonexapp.hr je dostupan na hrvatskom jeziku. Službeni jezik za sklapanje ugovora o kupoprodaji je hrvatski jezik.
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>OBILJEŽJA PROIZVODA I USLUGA</h3>

                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        <h5>Kupac se upoznaje s glavnim obilježjima proizvoda I usluga na web stranici www.sonexapp.com</h5>
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        InnoLink d.o.o. zadržava pravo izmjene informacija, uključujući cijene proizvoda i akcijske ponude na stranicama bez prethodne najave.
                                        Uz sliku proizvoda, koja nije obavezna, nalazi se opis glavnih obilježja proizvoda i njegova cijena s PDV-om i ostalim potencijalnim naknadama.
                                        Cijene, uvjeti plaćanja i akcijske ponude vrijede isključivo u trenutku narudžbe i/ili plaćanja.
                                    </p>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>POSTUPAK SKLAPANJA UGOVORA</h3>

                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <h5>Kupovina se obavlja na web stranicama InnoLink d.o.o. www.sonexapp.hr ispunjavanjem za to predviđenog obrasca.</h5>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>

                                        Prilikom ispunjavanja obrasca kupac je dužan upisati sve podatke koji se od njega traže.
                                        Kupovinu je moguće ostvariti uz potvrdu kupca da je prethodno pročitao i razumio Opće uvjete poslovanja te da je na iste pristao i da je
                                        upoznat da je to narudžba s obvezom plaćanja. Kupovina je moguća 24 sata na dan, 7 dana u tjednu. InnoLink d.o.o. ne odgovara za troškove
                                        korištenja računalne opreme i telekomunikacijskih usluga potrebnih za pristup usluzi. Kupac će elektronskom poštom ili sms-om biti obaviješten
                                        o potvrdi narudžbe.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        U slučaju da InnoLink d.o.o. iz bilo kojeg razloga nije u mogućnosti isporučiti neki od naručenih proizvoda, s kupcem će, telefonom ili e-porukom,
                                        u kontakt stupiti djelatnik InnoLink d.o.o. u svrhu dogovora o isporuci zamjenskog proizvoda ili eventualnog otkazivanja naručenog proizvoda.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Kupovinu proizvoda i/ili usluga u ime i za račun maloljetnika ili osobe lišene poslovne sposobnosti (potpuno ili djelomično),
                                        mogu zatražiti samo njihovi zakonski zastupnici.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Kupovina se obavlja naručivanjem dostupnih proizvoda i/ili isluga koje kupac bira na temelju opisa. Fotografije su ilustrativne prirode te ne moraju uvijek i u
                                        svim detaljima odgovarati dostupnim proizvodima.
                                        Kupovina se obavlja u nekoliko jednostavnih koraka preko forme na web stranicama www.sonexapp.hr
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ponuditelj je pravna ili fizička osoba koja nudi svoje  proizvode i/ili usluge na stranicama www.sonex.hr
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Pretraživanje ponuditelja koje nude proizvode i usluge je moguće po različitim kriterijima.
                                        Upisom određenog pojma u “Pretraživanje” pojavit će se ponuditelji
                                        koji su povezani s navedenim pojmom. Kupac može izabrati određeni uslugu ili proizvod koji ga zanima i pročitati dostupan opis proizvoda
                                        kako bi mogao samostalno donijeti odluku odgovara li proizvod njegovim potrebama.
                                        Kupac proizvode i usluge bira iz kartice koja mu je dostavljena elektroničkom  poštom ili sms-om.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Narudžba proizvoda i/ili usluga se obavlja elektronskim putem. Na zaprimljenoj kartici nalazi se postavljni datum za koji se kupuje
                                        rezervacija/plaća kotizacija. Na kartici se potencijalno nalze popisi sa dodatnim proizvodima i uslugama koje kupac odabere klikom na opis.
                                        Kupac je viđen korak kroz korak kroz proces, trenutni unos se može počistiti pritiskom na gumb C a povratak na prethodnu stranicu krtice gumbom na {"<"}.
                                        Kada kupac završi proces odabira proizvoda klikom na “Pošalji zahtjev“
                                        preusmjeriti će se na stranicu gdje odabire način plaćanja. Kupnju nije moguće nastaviti bez označavanja kućice
                                        “Slažem se s Općim uvjetima poslovanja” čijim označavanjem kupac potvrđuje da je pročitao i razumio ove Opće uvjete poslovanja te da se sa
                                        istima slaže i označavanjem kućice „Upoznat sam s tim da narudžba uključuje obvezu plaćanja“ te može kliknuti na ikonu „Plati“.
                                        Nakon što kupac pritiskom na ikonu “Plati” izvrši narudžbu, prodavatelj će na adresu e-pošte kupca ili sms poslati Opće uvjete poslovanja zajedno s
                                        potvrdom narudžbe te brojem iste kojom potvrđuje da je narudžba kupca zaprimljena i u procesu obrade.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Prodavatelj će kupcu poslati na adresu njegove e-pošte potvrdu o sklopljenom kupoprodajnom ugovoru.
                                        Ukoliko kupac ne primi naručene proizvode koje je platio u roku od 20 radnih dana (subota, nedjelja i neradni dani isključeni)
                                        od izvršenog plaćanja, odnosno 20 radnih dana (subota, nedjelja i neradni dani isključeni)
                                        od sklapanja kupoprodajnog ugovora s ugovorenim plaćanjem po primitku, dužan je obavijestiti o istom na adresu e-pošte info@sonexapp.hr.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ukoliko kupac nije zaprimio potvrdu kupnje putem e-pošte u roku od 72 sata ili nije moguće pristupiti usluzi na način naveden u e-poruci,
                                        dužan je kontaktirati prodavatelja na adresu e-pošte info@sonexapp.hr.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        U slučaju da Hemp InnoLink d.o.o. iz bilo kojeg razloga nije u mogućnosti isporučiti neki od naručenih proizvoda, s kupcem će, telefonom ili e-porukom,
                                        u kontakt stupiti zaposlenik Hemp InnoLink d.o.o. u svrhu dogovora o isporuci zamjenskog proizvoda ili eventualnog otkazivanja naručenog proizvoda.
                                    </p>

                                    <h5>Kupovina se obavlja na web stranicama InnoLink d.o.o. www.sonexapp.hr ispunjavanjem za to predviđenog obrasca.</h5>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray", minHeight: "600px" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>OPĆE INFORMACIJE</h3>
                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Korisnici odnosno kupci su dužni prije početka korištenja web stranice www.sonexapp.hr u vlasništvu InnoLink d.o.o. upoznati se sa Općim uvjetima poslovanja web stranice.
                                        Ukoliko imaju dodatnih pitanja ili nejasnoća vezanih uz Opće uvjete poslovanja, mogu se obratiti na adresu e-pošte info@sonexapp.hr.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Pristupanjem web stranici ili korištenjem bilo kojeg dijela njezinog sadržaja korisnik prihvaća Opće uvjete poslovanja web stranice,
                                        kao i sva ostala pravila i uvjete korištenja predmetne web stranice te usluga koje se putem nje pružaju.
                                        Korisnici su suglasni da neće koristiti web stranicu na način da štete autorima ili trećim osobama,
                                        te prihvaćaju sve rizike korištenja web stranice i usluga. Ukoliko se korisnik ne slaže s navedenim,
                                        dužan je prestati koristiti web stranicu i usluge koje se putem nje pružaju.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Sadržaj web stranice zaštićen je autorskim pravima. Mijenjanje, posuđivanje, prodavanje ili distribuiranje sadržaja moguće je samo uz prethodnu pisanu dozvolu InnoLink d.o.o.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        InnoLink d.o.o. omogućuje korištenje web stranice na najbolji mogući način. To uključuje: nadgledanje rada servera,
                                        širenje kapaciteta prema broju korisnika, podršku korisnicima te otklanjanje eventualnih grešaka i problema u radu sustava.
                                        ne preuzima odgovornost za eventualne probleme u radu stranica i usluga. InnoLink d.o.o. ne može jamčiti da korištenje web stranice neće biti prekinuto ili bez pogrešaka.
                                        Korisnik je suglasan s time da pristup web stranici ponekad može biti u prekidu ili privremeno nedostupan.
                                    </p>

                                    <h5 style={{ marginTop: "20px" }}>
                                        Korisnici koriste web stranicu na vlastitu odgovornost. InnoLink d.o.o. ni na koji način nije odgovorno za štetu koju korisnik može pretrpjeti korištenjem web stranice.
                                        Autori i druge fizičke ili pravne osobe uključene u stvaranje, proizvodnju i distribuciju web stranice nisu odgovorni ni za kakvu štetu nastalu kao posljedica korištenja
                                        ili nemogućnosti korištenja iste.
                                    </h5>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        InnoLink d.o.o. zadržava pravo da onemogući pristup web stranici korisnicima u slučaju procjene da se ista koristi na neprikladan način.
                                        InnoLink d.o.o. zadržava pravo odbiti pristup web stranici bilo kome, na osnovu vlastitih procjena. Korisnik se obvezuje da će web stranicu koristiti na
                                        način da ne ugrožava resurse i usluge u cijelosti. Neprimjereno korištenje web stranice zabranjeno je i rezultira ukidanjem pristupa istoj.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Korisnik je dužan čuvati podatke o svom korisničkom računu tajnima, te je u potpunosti odgovoran za svu štetu nastalu neautoriziranim korištenjem svog korisničkog računa.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        InnoLink d.o.o. zadržava pravo u bilo kojem trenutku izmijeniti ili dopuniti Opće uvjete poslovanja.
                                        Promjene stupaju na snagu danom objave na web stranici. Nastavak pristupanja web stranici ili korištenja bilo kojeg dijela njezinog sadržaja
                                        smatrat će se pristankom na izmijenjene ili dopunjene Opće uvjete poslovanja. InnoLink d.o.o. savjetuje periodično provjeravanje Općih uvjeta
                                        poslovanja radi upoznavanja s eventualnim promjenama.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        InnoLink d.o.o. zadržava pravo u bilo kojem trenutku i bez prethodne najave izmijeniti, dopuniti ili ukinuti bilo koji dio svog poslovanja,
                                        što uključuje i web stranicu, odnosno bilo koji njezin dio, servise, podstranice ili usluge koje se putem njih pružaju.
                                        Predmetno pravo uključuje, ali se ne ograničava na, promjenu vremena dostupnosti sadržaja, dostupnosti novih podataka,
                                        načina prijenosa, kao i prava na pristup ili korištenje web stranicom.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Dužnost je i obaveza korisnika koristiti web stranicu u skladu s pozitivnim propisima te općim moralnim i etičkim načelima.
                                        InnoLink d.o.o. ima pravo u svakom trenutku vršiti kontrolu sadržaja web stranice kako bi osiguralo poštivanje
                                        Općih uvjeta poslovanja i pozitivnih propisa. Izmjene Općih uvjeta poslovanja važeće su odmah po objavi na web stranici.
                                    </p>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray", minHeight: "600px" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>PLAĆANJE</h3>
                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Kupac se obvezuje naručene proizvode platiti kreditnom ili debitnom karticom – plaćanje izravno putem interneta,
                                        korištenjem servisa za plaćanje karticama: MasterCard, Maestro, Visa.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ugovor o kupoprodaji je sklopljen trenutkom prihvata ponude kupca.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ugovorena kupoprodajna cijena uključuje sve poreze i davanja te je izražena u Eurima.
                                    </p>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray", minHeight: "600px" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginTop: "20px", textAlign: 'center' }}>
                                    <h3>OBAVIJEST O NAČINU PISANOG PRIGOVORA POTROŠAČA</h3>
                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Sve prigovore sukladno članku 10. Zakona o zaštiti potrošača, potrošač može poslati putem pošte na adresu
                                        InnoLink d.o.o., elektronički na info@sonexapp.hr  ili osobno u poslovnim prostorijama prodavatelja na adresi InnoLink d.o.o. a.
                                        Adresa je navedena u sekciji OSNOVNI PODACI O PRODAVATELJU.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Kako bi potrošaču InnoLink d.o.o. odgovorilo na pisani prigovor koji nije upućen elektroničkom poštom, mole se potrošači da navedu točne podatke o
                                        svome imenu i prezimenu te adresi na koju će im biti dostavljen odgovor. Odgovor na prigovor potrošača zakonski mora dati u pisanom obliku
                                        najkasnije u roku 15 dana od dana primitka prigovora. U slučaju eventualnog spora InnoLink d.o.o. i potrošač će spor riješiti mirnim putem,
                                        a ukoliko isto nije moguće nadležan je mjerodavni sud prema sjedištu tvrtke Prodavatelja uz primjenu hrvatskog prava. Rješavanje sporova
                                        je moguće pred Sudom časti HGK ili drugim centrima za mirenje.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ukoliko potrošač prihvaća Opće uvjete poslovanja, isti prihvaća i sve druge uvjete navedene na web stranici te sve ostale odredbe navedene
                                        na toj web stranici. Ukoliko se potrošač ne slaže s bilo kojim dijelom predugovorne obavijesti ili Općih uvjeta poslovanja moli se isti
                                        da ne koristi web stranicu i ne sklapa ugovor o kupoprodaji.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        InnoLink d.o.o. zadržava pravo izmjene ovih Općih uvjeta poslovanja i pravila bez prethodne najave. Opći uvjeti poslovanja su sukladni zakonima Republike Hrvatske.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Ukoliko je kupac pravna osoba na njega se ne odnose pravila o zaštiti potrošača propisana Zakonom o zaštiti potrošača.
                                    </p>

                                    <p style={{ marginTop: "20px", fontSize: "14px" }}>
                                        Pravila o pisanom prigovoru potrošača ne odnose se na pravne osobe, a na koje se primjenjuje Zakon o obveznim odnosima i Zakon o
                                        elektroničkoj trgovini.
                                    </p>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </Layout >
    );
};

export default Terms;
