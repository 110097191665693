import { createAsyncThunk } from '@reduxjs/toolkit';

export const reservationSetupView = createAsyncThunk(
    'reservation/setup/view/',
    async ({ company_id }, thunkAPI,) => {

        try {
            const res = await fetch(`/api/reservation/setup/view/${company_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("reservationSetupView != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("reservationSetupView Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);