import { createAsyncThunk } from '@reduxjs/toolkit';

export const kpmgTestCreateReservation = createAsyncThunk(
    'kpmg/test/create/reservation',
    async (payload, thunkAPI,) => {
        const body = JSON.stringify(payload);

        try {
            const res = await fetch('/api/kpmg/test/create/reservation', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                return data;

            } else {
                console.log("kpmgTestCreate != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("kpmgTestCreate Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);