import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const ticketUpdate = createAsyncThunk(
    'company/tickets/update',
    async (payload, thunkAPI,) => {

        console.log("payload " + payload)

        const { id, company_id, tickets_verified } = payload;
        const body = JSON.stringify({ tickets_verified: tickets_verified });



        try {
            const res = await fetch(`/api/company/tickets/update/${company_id}/${id}/`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("tickets/update != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("tickets/update Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);