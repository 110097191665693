import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import kpmgImage from './kpmg_image_01.png';
import { kpmgTestVievAll } from 'features/customSetup/kpmg/KpmgTestVievAll';
import { reservationSetupView } from 'features/reservationSys/ReservationSetupView';
import { showEventsList } from "features/eventSys/ShowEventsList";
import ModalWithChildren from "containers/modals/ModalWithChildren";
import { kpmgTestCreateReservation } from 'features/customSetup/kpmg/KpmgTestCreateReservation';


const getTyDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${dd}.${mm}.${yyyy}`;
};

const getTodayDateAlt = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
};

const parseDate = (dateString) => {
    const [dd, mm, yyyy] = dateString.split('.');
    return new Date(`${yyyy}-${mm}-${dd}`);
};

const formatDate = (date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const yyyy = date.getFullYear();
    return `${dd}.${mm}.${yyyy}`;
};

const StraightLine = ({ width = '100%', height = '2px', color = 'rgb(29, 29, 72)' }) => {
    const lineStyle = {
        width: width,
        height: height,
        backgroundColor: color,
    };

    return <div style={lineStyle}></div>;
};

const KpmgTestRegistration = () => {
    const { companyId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { registered, loading } = useSelector(state => state.user);
    const { kpmg_tests } = useSelector(state => state.reservation);
    const { reservation_setup_loading, reservation_setup_content, kpmg_test_reservation_created } = useSelector(state => state.reservation);
    const { eventList } = useSelector(state => state.reservation);


    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        accaid: "",
        birth_date: getTodayDateAlt(),
        phone_prefix: "+38591", // Default to Croatian prefix
        phone_number: "",
        custom_phone_prefix: "",
        cname: "",
        company_name: "", // Not mandatory
        address: "",
        exam: "-1", // Not mandatory
        exam_date: "-1", // Not mandatory
        vat: "",
    });

    const [timeFrames, setTimeFrames] = useState([]);

    const [errors, setErrors] = useState({});
    const { first_name, last_name, email, accaid, birth_date, phone_prefix, phone_number, custom_phone_prefix, cname, company_name, address, exam, exam_date, vat } = formData;

    const [hasScrolledToBottomTos, setHasScrolledToBottomTos] = useState(false);
    const [hasScrolledToBottomPrivacy, setHasScrolledToBottomPrivacy] = useState(false);
    const [hasScrolledToBottomDeclaration, setHasScrolledToBottomDeclaration] = useState(false);

    const [acceptTos, setAcceptTos] = useState(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);
    const [acceptDeclaration, setAcceptDeclaration] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);
    const [exams, setExams] = useState([{ value: '-1', label: t('select_exam') }]);
    const [dates, setDates] = useState([{ value: '-1', label: t('select_exam_date') }]);
    const [timeFrame, setTimeFrame] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        const payload = { company_id: companyId };

        dispatch(kpmgTestVievAll(payload));
        dispatch(reservationSetupView(payload));
        dispatch(showEventsList(payload));
    }, [dispatch]);

    useEffect(() => {
        if (kpmg_tests) {
            const examOptions = kpmg_tests.map(test => ({
                value: test.id,
                label: test.test_name
            }));
            setExams([{ value: '-1', label: t('select_exam') }, ...examOptions]);
        }
    }, [kpmg_tests]);

    useEffect(() => {
        if (eventList) {
            const dateOptions = eventList.map(event => ({
                value: event.selectedDate,
                label: formatDate(new Date(event.selectedDate))
            }));
            setDates([{ value: '-1', label: t('select_exam_date') }, ...dateOptions]);
        }
    }, [eventList, t]);

    useEffect(() => {
        if (reservation_setup_content[0]) {
            const dat = reservation_setup_content[0];

            if (dat.defined_time_frames) {
                setTimeFrames(dat.defined_time_frames);
            }
        }

    }, [reservation_setup_content]);

    useEffect(() => {
        if (kpmg_test_reservation_created) {
            setShowModal(true);
        }

    }, [kpmg_test_reservation_created]);



    const options = [
        { value: '+38591', label: '+38591' },
        { value: '+38592', label: '+38592' },
        { value: '+38593', label: '+38593' },
        { value: '+38594', label: '+38594' },
        { value: '+38595', label: '+38595' },
        { value: '+38596', label: '+38596' },
        { value: '+38597', label: '+38597' },
        { value: '+38598', label: '+38598' },
        { value: '+38599', label: '+38599' },
        { value: 'other', label: 'Other' }
    ];

    //const dates = [
    //    { value: '-1', label: t('select_exam_date') },
    //    { value: '01.10.2024', label: '01.10.2024' },
    //    { value: '01.11.2024', label: '01.11.2024' },
    //];

    const onChange = (e) => {
        let value = e.target.value;

        if (e.target.name === "accaid") {
            if (value.length > 7) {
                value = value.slice(0, 7);
            }
        }

        setFormData({ ...formData, [e.target.name]: value });
    };

    const handlePhonePrefixChange = (e) => {
        const value = e.target.value;
        if (value !== "other") {
            setFormData({ ...formData, phone_prefix: value, custom_phone_prefix: "" });
        } else {
            setFormData({ ...formData, phone_prefix: value });
        }
    };

    const handleEulaScroll = (e, setScrolledToBottom) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight) {
            setScrolledToBottom(true);
        }
    };

    const validateForm = () => {
        let tempErrors = {};

        if (!first_name.trim()) tempErrors.first_name = t('first_name_is_required');
        if (!last_name.trim()) tempErrors.last_name = t('last_name_is_required');
        if (!email.trim()) tempErrors.email = t('email_is_required');
        if (!accaid.trim()) tempErrors.accaid = t('acca_id_is_required');
        if (!birth_date.trim()) tempErrors.birth_date = t('birth_date_is_required');
        if (!phone_number.trim()) tempErrors.phone_number = t('phone_number_is_required');

        if (phone_prefix === "other") {
            if (!custom_phone_prefix.trim()) {
                tempErrors.custom_phone_prefix = t('custom_phone_prefix_is_required');
            }
        }

        if (!cname.trim()) tempErrors.cname = t('name_is_required');
        if (!address.trim()) tempErrors.address = t('address_is_required');

        if (exam_date === "-1") {
            tempErrors.exam_date = t('choose_exam_date');
        }

        if (exam === "-1") {
            tempErrors.exam = t('choose_exam');
        }

        const today = getTodayDateAlt();
        if (birth_date === today) {
            tempErrors.birth_date = t('birth_date_is_required');
        }

        setErrors(tempErrors);

        return Object.keys(tempErrors).length === 0;
    };

    const tileDisabled = ({ date, view }) => {
        return (
            view === 'month' &&
            !dates.some(
                (enabledDate) =>
                    new Date(parseDate(enabledDate.label)).toDateString() === date.toDateString()
            )
        );
    };

    const handleDateChange = (e) => {
        const value = e.target.value;
        setFormData({ ...formData, exam_date: value });

        if (value !== '-1') {
            const selected = parseDate(dates.find(date => date.value === value).label);
            setSelectedDate(selected);
        } else {
            setSelectedDate(null);
        }
    };

    const handleCalendarChange = (date) => {
        setSelectedDate(date);
        const formattedDate = formatDate(date);
        const selectedOption = dates.find(d => d.label === formattedDate);

        if (selectedOption) {
            setFormData({ ...formData, exam_date: selectedOption.value });
        } else {
            setFormData({ ...formData, exam_date: '-1' });
        }
    };

    const onChangeTimeFrame = (e) => {
        setTimeFrame(e.target.value);
    };

    const returnPrefix = () => {
        if (phone_prefix === "other") {
            return custom_phone_prefix;
        } else {
            return phone_prefix;
        }
    };

    const f_timeFrom = (timeFrame) => {
        if (timeFrame && typeof timeFrame === 'string') {
            return timeFrame.split('-')[0]; // Extracts the part before the '-'
        }
        return ''; // Return empty string if timeFrame is not valid
    };

    const f_timeTo = (timeFrame) => {
        if (timeFrame && typeof timeFrame === 'string') {
            return timeFrame.split('-')[1]; // Extracts the part after the '-'
        }
        return ''; // Return empty string if timeFrame is not valid
    };

    //COMMIT STRUKTURE

    const _contact = {
        company_id: companyId,
        name: first_name,
        surname: last_name,
        email: email,
        phone_prefix: returnPrefix(), // Default prefix
        phone_number: phone_number, // User-entered number
    }

    const _reservation = {
        company_id: companyId,
        //contact_id: contactId.contact_id,
        people_num: 1,
        reservation_date: selectedDate,
        menu_id: "",
        time_from: f_timeFrom(timeFrame),
        time_to: f_timeTo(timeFrame),
        comment: "",
    };

    const _KPMGTestStudent = {
        accaid: accaid, //Obligatory
        name: first_name, //Obligatory
        surname: last_name, //Obligatory
        email: email,
        birth_date: birth_date,
        phone_prefix: returnPrefix(),
        phone_number: phone_number, //Obligatory
        exam: exam,
        date: selectedDate,
        time_from: f_timeFrom(timeFrame),
        time_to: f_timeTo(timeFrame),
        payment_name: cname,
        payment_company_name: company_name,
        payment_address: address,
        payment_: vat,
    }

    const _form_data = {
        contact: _contact,
        reservation: _reservation,
        KPMGTestStudent: _KPMGTestStudent
    }



    const onSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }

        dispatch(kpmgTestCreateReservation(_form_data));
        console.log(_form_data);
    };

    const isSubmitEnabled = hasScrolledToBottomTos && acceptTos && hasScrolledToBottomPrivacy && acceptPrivacy && hasScrolledToBottomDeclaration && acceptDeclaration;

    return (
        <div className="container" style={{ marginTop: "10px", maxWidth: "600px" }}>
            <div className="row justify-content-center" style={{ marginTop: "10px" }}>
                <form className="form-control" style={{ boxShadow: "0px 0px 0px 0px" }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className="title" style={{ margin: 0 }}>{t('acca_reg_form_title')}</p>
                        <LanguageSwitcher />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <img src={kpmgImage} alt="KPMG" style={{ width: "215px", height: "159px", marginRight: "10px" }} />
                        <label style={{ marginTop: "0px", fontSize: "12px" }}>
                            KPMG Croatia d.o.o.<br />
                            Ivana Lučića 2a, Eurotower, 17th floor 10 000 Zagreb<br />
                            accacentre@kpmg.hr.
                        </label>
                    </div>
                    <StraightLine width="100%" height="1px" />

                    <h5>{t('student_details')}</h5>

                    <div className="input-field">
                        <input className="input"
                            type="text"
                            name="first_name"
                            onChange={onChange}
                            value={first_name}
                            required
                        />
                        <label className="label" htmlFor="input">{t('name_label')}</label>
                        {errors.first_name && <div className="error-message">{errors.first_name}</div>}
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="text"
                            name="last_name"
                            onChange={onChange}
                            value={last_name}
                            required
                        />
                        <label className="label" htmlFor="input">{t('surname_label')}</label>
                        {errors.last_name && <div className="error-message">{errors.last_name}</div>}
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="email"
                            name="email"
                            onChange={onChange}
                            value={email}
                            required
                        />
                        <label className="label" htmlFor="input">{t('email')}:</label>
                        {errors.last_name && <div className="error-message">{errors.email}</div>}
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="number"
                            id="accaid"
                            name="accaid"
                            onChange={onChange}
                            value={accaid}
                            max={9999999} // For a 7-digit number
                            required
                        />
                        <label className="label" htmlFor="accaid">ACCA ID:</label>
                        {errors.accaid && <div className="error-message">{errors.accaid}</div>}
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="date"
                            id="birth_date"
                            name="birth_date"
                            onChange={onChange}
                            value={birth_date}
                            required
                        />
                        <label className="label" htmlFor="birth_date">{t('birth_date')}:</label>
                        {errors.birth_date && <div className="error-message">{errors.birth_date}</div>}
                    </div>

                    <div className="input-field">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {phone_prefix !== 'other' && (
                                <select
                                    className="input"
                                    id="phone_prefix"
                                    name="phone_prefix"
                                    value={phone_prefix}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                    required
                                    style={{ marginRight: '8px', width: "30%" }}
                                >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {phone_prefix === 'other' && (
                                <input
                                    className="input"
                                    type="text"
                                    id="custom_phone_prefix"
                                    name="custom_phone_prefix"
                                    placeholder="Prefix"
                                    value={custom_phone_prefix}
                                    onChange={(e) => {
                                        handlePhonePrefixChange(e);
                                    }}
                                    required
                                    style={{ marginRight: '8px', width: "30%" }}
                                />
                            )}
                            <input
                                className="input"
                                type="text"
                                id="phone_number"
                                name="phone_number"
                                placeholder="0000000"
                                value={phone_number}
                                onChange={onChange}
                                style={{ width: "70%" }}
                                required
                            />
                            <label className="label" htmlFor="phone_number">{t('contact_number')}:</label>
                        </div>
                        {errors.phone_number && <div className="error-message">{errors.phone_number}</div>}
                        {errors.custom_phone_prefix && <div className="error-message">{errors.custom_phone_prefix}</div>}
                    </div>
                    <StraightLine width="100%" height="1px" />

                    <h5>{t('exam_information')}</h5>

                    <div className="input-field">
                        <select
                            className="input"
                            id="exam"
                            name="exam"
                            value={exam}
                            onChange={onChange}
                            required
                        >
                            {exams.map((exam, index) => (
                                <option key={`${exam.value}-${index}`} value={exam.value}>
                                    {exam.label}
                                </option>
                            ))}
                        </select>
                        <label className="label" htmlFor="exam">{t('select_exam')}:</label>
                        {errors.exam && <div className="error-message">{errors.exam}</div>}
                    </div>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        width: '100%',  // Ensures the container takes the full width of the parent
                        maxWidth: '100%'  // Limits the max width to prevent overflow
                    }}>
                        <Calendar
                            value={selectedDate}
                            onChange={handleCalendarChange}
                            tileDisabled={tileDisabled}
                            style={{
                                borderRadius: '10px',
                                width: '100%',  // Makes the calendar take up the full width of the container
                                maxWidth: '500px',  // Limits the calendar width for better scaling on larger screens
                                height: 'auto'  // Keeps the height proportional
                            }}
                        />
                    </div>

                    <div className="input-field">
                        <select
                            className="input"
                            id="exam_date"
                            name="exam_date"
                            value={exam_date}
                            onChange={handleDateChange}
                            required
                        >
                            {dates.map((date, index) => (
                                <option key={`${date.value}-${index}`} value={date.value}>
                                    {date.label}
                                </option>
                            ))}
                        </select>
                        <label className="label" htmlFor="exam_date">{t('select_exam_date')}:</label>
                        {errors.exam_date && <div className="error-message">{errors.exam_date}</div>}
                    </div>

                    <div className="input-field">
                        <select
                            className="input"
                            id="timeFrame"
                            name="timeFrame"
                            value={timeFrame}
                            onChange={onChangeTimeFrame}  // Handle the selection change
                            required
                        >
                            <option value="-1">{t('time_frame')}</option>
                            {timeFrames.map((frame, index) => (
                                <option key={`${frame.time_from}-${frame.time_to}-${index}`} value={`${frame.time_from.slice(0, 5)}-${frame.time_to.slice(0, 5)}`}>
                                    {`${frame.time_from.slice(0, 5)} - ${frame.time_to.slice(0, 5)}`}
                                </option>
                            ))}
                        </select>
                        <label className="label" htmlFor="timeFrame">{t('time_frame')}:</label>
                    </div>

                    <StraightLine width="100%" height="1px" />

                    <h5>{t('payment_proforma')}</h5>
                    <p style={{ marginTop: "0px", fontSize: "12px" }}>{t('pro_forma_text')}</p>

                    <div className="input-field">
                        <input className="input"
                            type="text"
                            name="cname"
                            id='cname'
                            onChange={onChange}
                            value={cname}
                            required
                        />
                        <label className="label" htmlFor="cname">{t('name_label')}</label>
                        {errors.cname && <div className="error-message">{errors.cname}</div>}
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="text"
                            id="company_name"
                            name="company_name"
                            onChange={onChange}
                            value={company_name}
                        />
                        <label className="label" htmlFor="company_name">{t('company_name_if_applicable')}:</label>
                    </div>

                    <div className="input-field">
                        <textarea
                            className="input"
                            id="address"
                            name="address"
                            onChange={onChange}
                            value={address}
                            style={{ height: "100px" }}
                            required
                            rows="6"
                        />
                        <label className="label" htmlFor="address">{t('address_label')}</label>
                        {errors.address && <div className="error-message">{errors.address}</div>}
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="number"
                            id="vat"
                            name="vat"
                            onChange={onChange}
                            value={vat}
                        />
                        <label className="label" htmlFor="vat">{t('oib_vat_if_applicable')}:</label>
                    </div>

                    <StraightLine width="100%" height="1px" />

                    <h5>{t('tos')}</h5>

                    <div style={{ height: "400px", overflowY: "scroll", border: "1px solid #ccc", borderRadius: "10px", padding: "10px" }} onScroll={(e) => handleEulaScroll(e, setHasScrolledToBottomTos)}>
                        <p style={{ fontSize: "12px" }}>{t('kpmg_1')}</p>
                        <h5>{t('exam_registration')}</h5>
                        <ul style={{ paddingLeft: "20px", listStyleType: "disc", fontSize: "14px" }}>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_2')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_3')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_4')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_5')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_6')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_7')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_8')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_9')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_10')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_11')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_12')}</li>
                        </ul>
                        <h5>{t('exam_capital')}</h5>
                        <ul style={{ paddingLeft: "20px", listStyleType: "disc", fontSize: "14px" }}>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_13')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_14')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_15')}</li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_16')}</li>
                        </ul>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <input type="checkbox" id="acceptTos" checked={acceptTos} onChange={(e) => setAcceptTos(e.target.checked)} disabled={!hasScrolledToBottomTos} />
                        <label htmlFor="acceptTos" style={{ marginLeft: "8px" }}>{t('i_have_read_and_accept_the_terms_of_service')}</label>
                    </div>

                    <StraightLine width="100%" height="1px" />

                    <h5>{t('privacy_notice')}</h5>

                    <div style={{ height: "400px", overflowY: "scroll", border: "1px solid #ccc", borderRadius: "10px", padding: "10px" }} onScroll={(e) => handleEulaScroll(e, setHasScrolledToBottomPrivacy)}>
                        <ul style={{ paddingLeft: "20px", listStyleType: "disc", fontSize: "14px" }}>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_17')}
                                <ul>
                                    <li style={{ marginBottom: "10px", marginTop: "10px" }}>{t('kpmg_17b')}</li>
                                    <li style={{ marginBottom: "10px" }}>{t('kpmg_17c')}</li>
                                </ul>

                            </li>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_18')}</li>

                            <li style={{ marginBottom: "10px" }}>{t('kpmg_19')}</li>

                            <li style={{ marginBottom: "10px" }}>{t('kpmg_20')}</li>

                            <li style={{ marginBottom: "10px" }}>{t('kpmg_21')}</li>

                            <li style={{ marginBottom: "10px" }}>{t('kpmg_22')}</li>
                        </ul>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <input type="checkbox" id="acceptPrivacy" checked={acceptPrivacy} onChange={(e) => setAcceptPrivacy(e.target.checked)} disabled={!hasScrolledToBottomPrivacy} />
                        <label htmlFor="acceptPrivacy" style={{ marginLeft: "8px" }}>{t('i_have_read_and_accept_the_privacy_notice')}</label>
                    </div>

                    <StraightLine width="100%" height="1px" />

                    <h5>{t('candidate_declaration')}</h5>

                    <div style={{ height: "200px", overflowY: "scroll", border: "1px solid #ccc", borderRadius: "10px", padding: "10px" }} onScroll={(e) => handleEulaScroll(e, setHasScrolledToBottomDeclaration)}>
                        <ul style={{ paddingLeft: "20px", listStyleType: "disc", fontSize: "14px" }}>
                            <li style={{ marginBottom: "10px" }}>{t('kpmg_23')}</li>

                            <li style={{ marginBottom: "10px" }}>{t('kpmg_24')}</li>

                            <li style={{ marginBottom: "10px" }}>{t('kpmg_25')}</li>
                        </ul>
                    </div>

                    <div style={{ marginTop: "10px" }}>
                        <input type="checkbox" id="acceptDeclaration" checked={acceptDeclaration} onChange={(e) => setAcceptDeclaration(e.target.checked)} disabled={!hasScrolledToBottomDeclaration} />
                        <label htmlFor="acceptDeclaration" style={{ marginLeft: "8px" }}>{t('i_have_read_and_accept_the_candidate_declaration')}</label>
                    </div>

                    <StraightLine width="100%" height="1px" />

                    {loading ?
                        (
                            <div className="spinner-border text-primary mt-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <button className="edit-button" type="submit" disabled={!isSubmitEnabled} onClick={onSubmit}> {t('kpmg_register')} </button>
                        )
                    }
                </form>
            </div>
            <ModalWithChildren isOpen={showModal} onClose={() => setShowModal(false)} renderSave={false} renderDelete={false} style={{ maxWidth: "600px", }}>
                <div style={{ width: "100%" }}>
                    <h5>{t('register_success_text')}</h5>
                    <hr />
                </div>
            </ModalWithChildren>
        </div>
    );
};

export default KpmgTestRegistration;
