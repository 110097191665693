import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { passwordRequestReset } from "features/permissionsSys/PaswordRequestReset";
import { Navigate } from "react-router-dom";
import Layout from "components/Layout";
import './App.css';

const PasswordRecoveryRequest = () => {
    const dispatch = useDispatch();
    const { resetPasswordRequested, loadingPermissions } = useSelector(state => state.permissions);

    const [formData, setFormData] = useState({
        email: "",
    });


    const { email } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

    };


    const onSubmit = e => {
        e.preventDefault();
        const payload = { "email": formData.email };
        dispatch(passwordRequestReset(payload));
    };

    return (
        <Layout title='Sonex | Password recovery' content='Login page'>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '50px' }} >
                <div className="row col-lg-4 col-md-6 col-sm-12" >

                    <form className="form-control"
                        action=""
                        onSubmit={onSubmit}>
                        <p className="title">Promjena lozinke</p>
                        <div className="input-field">
                            <input className="input"
                                type="email"
                                name="email"
                                onChange={onChange}
                                value={email}
                                required
                            />
                            <label className="label" htmlFor="input">Email:</label>
                        </div>
                        <a style={{ fontSize: "12px" }}>Na upisanu email adresu će biti poslan email za nastavak procedure.</a>
                        {resetPasswordRequested ?
                            (<><a style={{ fontSize: "12px", color: "green" }}>Zahtjev uspješno poslan.</a></>)
                            :
                            (<></>)}
                        {loadingPermissions ?
                            (
                                <div className="spinner-border text-primary mt-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            ) : (
                                <button className="submit-btn mt-4"> Pošalji zahtjev </button>
                            )
                        }
                    </form>
                </div>
            </div>

            <div className="container" style={{ marginTop: "100px", marginBottom: "20px" }}>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-12">
                        {/* This 'container' div centers the content and sets a max-width for large screens */}
                        <div className="row">
                            {/* Each column now takes up 4 of 12 possible columns on large screens, centered within the '.col-lg-8' container */}
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>Dobro došli u naš sustav Narudžbe & Rezervacije. U srcu naše misije leži želja da olakšamo proces narudžbi i rezervacija, pretvarajući ga u bezbrižno iskustvo.
                                    Naša platforma je dizajnirana s vama na umu - brza, pouzdana i intuitivna, osigurava da vaše narudžbe i rezervacije budu obrađene u rekordnom vremenu, bez komplikacija.</p>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Zašto odabrati Sonex?</h2>
                                <p style={{ fontSize: "14px" }}>Brzina i Efikasnost: U samo nekoliko klikova, vaša narudžba ili rezervacija bit će potvrđena.</p>
                                <p style={{ fontSize: "14px" }}>Pouzdanost: Naš sustav koristi najnovije tehnologije za osiguravanje točnosti vaših narudžbi i rezervacija.</p>
                                <p style={{ fontSize: "14px" }}>Prilagodljivost: Bez obzira jeste li pojedinac ili tvrtka, naša platforma pruža prilagođena rješenja.</p>
                                <p style={{ fontSize: "14px" }}>Izvanredna podrška: Naš tim za korisničku podršku dostupan je 24/7.</p>


                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <h2>Sonex</h2>
                                <p style={{ fontSize: "14px" }}>Započnite svoje bezbrižno iskustvo s Narudžbama & Rezervacijama danas i otkrijte kako možemo transformirati
                                    vaše planiranje u uživanje. Dobrodošli u budućnost planiranja!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Layout >
    );
};

export default PasswordRecoveryRequest;