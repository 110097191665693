import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReservationTimeSelector = ({ timeFrames, timeFrame, setTimeFrame, okClicked, bckButton }) => {
  const [inputValue, setInputValue] = useState(timeFrame ? timeFrame.time_from.substring(0,5) + ' - ' + timeFrame.time_to.substring(0,5) : 'Vremensko razdoblje');
  const [selectedFrame, setSelectedFrame] = useState(timeFrame);

  const { t } = useTranslation();


  const handleButtonClick = (frame) => {
    setInputValue(frame.time_from.substring(0,5) + ' - ' + frame.time_to.substring(0,5));
    
    const x = {
      time_from: frame.time_from.substring(0,5),
      time_to: frame.time_to.substring(0,5)
    }

    setTimeFrame(x);
    setSelectedFrame(frame);
  };

  const removeLast = () => {
    setInputValue(t('time_span'));
    setTimeFrame(null);
    setSelectedFrame(null);
  };

  return (
    <>
      <div className="display">{t('time_of_reservation')}</div>
      <div className="number-pad">
        <span style={{ display: 'block', width: '260px' }}></span>
        <div style={{ maxWidth: '250px', marginLeft: '-100px' }}>
          {timeFrames.map((frame, index) => (
            <button
              key={index}
              className={`time-frame-button ${selectedFrame && selectedFrame.time_from === frame.time_from && selectedFrame.time_to === frame.time_to ? 'selected' : ''}`}
              onClick={() => handleButtonClick(frame)}
            >
              {frame.time_from.substring(0,5)} - {frame.time_to.substring(0,5)}
            </button>
          ))}
          <div style={{ maxWidth: '250px', marginLeft: '30px' }}>
            <button id="C" className="number-button-cancel" onClick={removeLast}>
              C
            </button>
            <button id="0" className="number-button" onClick={bckButton}>
              {'<'}
            </button>
            <button id="OK" className="number-button" onClick={okClicked} style={{paddingLeft: "15px"}}>
              OK
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationTimeSelector;
