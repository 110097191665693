import { createAsyncThunk } from '@reduxjs/toolkit';

export const customSetupCreate = createAsyncThunk(
    'reservation/custom_setup/create',
    async (payload, thunkAPI,) => {

        const body = JSON.stringify(payload);



        try {
            const res = await fetch(`/api/reservation/custom_setup/create`, {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                return data;

            } else if (res.status === 200) {
                return data;

            } else {
                console.log("customSetupCreate != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("customSetupCreate Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);