import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const ticketChangelogView = createAsyncThunk(
    'company/ticketlogs/',
    async ({ startDate, endDate, company_id }, thunkAPI,) => {

        try {
            const res = await fetch(`/api/company/ticketlogs/${company_id}/${startDate}/${endDate}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("company/ticketlogs != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("company/ticketlogs Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);