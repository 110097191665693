import Layout from "components/Layout";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { permissionsView } from "features/permissionsSys/PermissionsView";
import { permissionsCoworkersView } from "features/permissionsSys/PermissionsCoworkersView";
import PermissionDisplay from "./reservationsSidebar/forms/PermissionDisplay";
import TicketsDisplayTable from "./reservationsSidebar/forms/TicketsDisplayTable";
import Calendar from "./reservationsSidebar/forms/Calendar";

import { roomsView } from "features/rooms/RoomsView";
import { ticketLogView as ticketViewForDateAction } from "features/ticketSys/TicketLogView";
import { refreshAuth } from 'features/user';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Tickets = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const today = new Date().toISOString().split('T')[0];
    const { isAuthenticated, user, loading, company, invalid } = useSelector(state => state.user);
    const { permissions, changesPermissions } = useSelector(state => state.permissions);
    const { ticketChecked, loading: loadTickets } = useSelector(state => state.ticket);
    const [selectedDate, setSelectedDate] = useState(today);

    const [singleton_1, setSingleton_1] = useState(true);
    const [singleton_2, setSingleton_2] = useState(true);

    //useEffect(() => {
    //    if (invalid) {
    //        navigate('/login');
    //    }
    //}, [invalid]);


    useEffect(() => {
        const refreshUser = () => {
            dispatch(refreshAuth());
        };

        const intervalId = setInterval(refreshUser, 10 * 60 * 1000); // 10 minutes in milliseconds
        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        if (permissions && permissions.company) {
            const payload = {
                company_id: permissions.company,
                startDate: selectedDate,
                endDate: selectedDate,
            };
            // Correctly dispatch the async thunk action
            dispatch(ticketViewForDateAction(payload));
        }
    }, [dispatch, permissions, selectedDate]);


    useEffect(() => {
        if (singleton_1 === true) {
            dispatch(permissionsView());
            setSingleton_1(false);
        }
    }, [dispatch, singleton_1]);

    useEffect(() => {

        if (permissions && singleton_2 === true) {

            //console.log("ls: " + JSON.stringify(permissions));

            const payload = { "company_id": permissions.company };
            dispatch(permissionsCoworkersView(payload));
            dispatch(roomsView(payload));
            setSingleton_2(false);
        }
    }, [dispatch, permissions]);

    //Ako ima izmjena u permisissionima
    useEffect(() => {

        if (changesPermissions === true) {
            const payload = { "company_id": permissions.company };
            dispatch(permissionsCoworkersView(payload));
        }

    }, [dispatch, changesPermissions]);


    const handleDateSelect = date => {
        setSelectedDate(date);
    };


    if (!isAuthenticated && !loading && user === null) {
        return <Navigate to='/login' />;

    }


    //kako bi user ostao ulogiran  kod refresha stranice
    return (
        <Layout title='Sonex | Dashboard' content='Dashboard page'>

            {loading || !user ? (
                <div style={{
                    position: 'fixed', // Use fixed to keep it in the center regardless of scrolling
                    top: '0', // Start from the top of the viewport
                    left: '0', // Start from the left of the viewport
                    right: '0', // Extend to the right of the viewport
                    bottom: '0', // Extend to the bottom of the viewport
                    display: 'flex', // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: Adds a white semi-transparent background
                    zIndex: '9999' // Ensure it's above other content
                }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>

            ) : (
                <div>
                    <div style={{ justifyContent: 'center', width: '100%', paddingTop: '0px' }}>
                        <span style={{ display: 'block', height: '10px', alignContent: "center" }}></span>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ fontSize: "14px" }}>

                            <div style={{ backgroundColor: "white", marginBottom: "20px", padding: "15px", borderRadius: '15px 15px 15px 15px', }}>
                                <div className="row" style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <h4 style={{ fontSize: "24px", fontWeight: "bold", marginTop: "10px" }}>{user ? (<p className="title">{t('tickets')} - {user.first_name} {user.last_name}</p>) : (<p className="title">{t('tickets')}</p>)}</h4>
                                    </div>
                                    <div className="input-field col-lg-6 col-md-12" style={{ maxWidth: "350px", marginTop: "-10px", marginLeft: 'auto' }}>
                                        <input
                                            className="input"
                                            type="date"
                                            name="selectedDate"
                                            value={selectedDate}
                                            onChange={e => setSelectedDate(e.target.value)}
                                            required
                                        />
                                        <label className="label" htmlFor="input" style={{ marginLeft: "15px" }}> {t('select_date_label')}</label>
                                    </div>
                                </div>
                                <hr />
                                <PermissionDisplay data={permissions} />
                                <hr />

                                {user ? (<p className="title">{t('checked_tickets')} - {user.first_name} {user.last_name}</p>) : (<p className="title">Ulaznice</p>)}
                                <span style={{ display: 'block', height: '0px' }}></span>


                                {company ?

                                    (<div className="row col-lg-12 col-md-12 col-sm-12" style={{ minHeight: "400px" }}>

                                        {!loadTickets ?
                                            (<TicketsDisplayTable inData={ticketChecked} date={selectedDate} />) :
                                            (<div>
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>)}

                                    </div>)
                                    :
                                    (<></>)}

                            </div></div>

                    </div>
                </div>

            )
            }
        </Layout >
    );
};

export default Tickets;