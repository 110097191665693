import { createAsyncThunk } from '@reduxjs/toolkit';

export const createEvents = createAsyncThunk(
    'reservations/company/event/create',
    async (payload, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('company', payload.company);
        formData.append('eventName', payload.eventName);
        formData.append('eventDescription', payload.eventDescription);
        formData.append('selectedDate', payload.selectedDate);
        formData.append('eventImage', payload.eventImage); // Directly append the file object
        formData.append('company_id', payload.company_id);
        if (payload.listOfTables) formData.append('listOfTables', payload.listOfTables);

        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }

        try {
            const response = await fetch('/api/reservations/company/event/create', {
                method: 'POST',
                body: formData, // Send formData directly
                // Do not set Content-Type header, let the browser set it with the correct boundary
            });

            if (!response.ok) throw new Error('Network response was not ok');
            return await response.json();
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
