//https://redux-toolkit.js.org/api/createSlice
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { updateCompanyInfo } from './reservation';
import { appMenuViewByCompany } from './appMenu/AppMenuViewByCompany';
//import { API_URL } from 'config/index';

//Action creator
//setupaj redux async thuk https://redux-toolkit.js.org/api/createAsyncThunk
//vraca
//'users/requestStatus/pending'
//'users/requestStatus/fulfilled'
//'users/requestStatus/rejected'
// umjesto ovoga { first_name, last_name, email, password } moze bit 'arg' pa arg proslijedis u stringify
export const register = createAsyncThunk(
    'users/register',
    async ({ first_name, last_name, email, password, is_realtor }, thunkAPI,) => {
        const body = JSON.stringify({
            first_name,
            last_name,
            email,
            password,
            is_realtor,
        });

        try {
            //const res = await fetch(`${API_URL}/api/users/register`, {
            const res = await fetch('/api/users/register', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                //ovo ne koristimo, možemo dodat neki hndle za pujnejne usera
                return data;
            } else {
                console.log("USERS != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);


//dohvati listinge test podatke
const getListings = createAsyncThunk('listing/manage', async (_, thunkAPI) => {
    try {
        const res = await fetch('/api/listing/manage', {
            method: 'GET',
            headers: {
                Accept: 'application/JSON',
                'Content-Type': 'application/json'
            }
        });

        const data = await res.json();

        if (res.status === 200) {
            return data;
        } else {
            thunkAPI.rejectWithValue(data);
        }

    } catch (err) {
        thunkAPI.rejectWithValue(err.response.data);
    }

});

//NICIJALIZIRAJ SVE PODATKE O KOMPANIJI
const getCompany = createAsyncThunk(
    'users/user/company', async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/users/user/company', {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                }
            });


            const data = await res.json();
            if (res.status === 200) {

                const { dispatch } = thunkAPI;
                dispatch(updateCompanyInfo(data));

                const payload = { company_id: data.id }
                dispatch(appMenuViewByCompany(payload));

                return data;

            } else {
                thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            thunkAPI.rejectWithValue(err.response.data);
        }

    });

export const registerCompany = createAsyncThunk(
    'users/company/register',
    async (formData, thunkAPI,) => {
        const body = JSON.stringify(formData);

        try {
            const res = await fetch('/api/users/company/register', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body
            });

            const data = await res.json();

            if (res.status === 201) {
                const { dispatch } = thunkAPI;
                dispatch(getCompany());
                return data;
            } else {
                console.log("Company != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("Error -----------------------registerCompany");
            console.log(err);
            // Safely attempt to access err.response.data, defaulting to a generic error message if it's not available
            const errorResponse = err.response ? err.response.data : { message: 'An unknown error occurred' };
            return thunkAPI.rejectWithValue(errorResponse);
        }
    }
);


export const getUser = createAsyncThunk('users/me', async (_, thunkAPI) => {
    //console.log("const getUser = createAsyncThunk('users/me'----------,,,,,,,,,,,,,--------");
    try {
        const res = await fetch('/api/users/me', {
            method: 'GET',
            headers: {
                Accept: 'application/JSON',
                'Content-Type': 'application/json'
            }
        });

        //console.log(res.json());
        const data = await res.json();

        //console.log("////////////////////////");
        //console.log(res);
        //console.log(res.status);

        if (res.status === 200) {
            const { dispatch } = thunkAPI;
            dispatch(getCompany());
            return data;

        } else {
            return thunkAPI.rejectWithValue(data);
        }

    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }

});

//moraš  rutu dodat u index.js od 5000
export const uploadPhoto = createAsyncThunk(
    'users/photo',
    async (blobData, thunkAPI) => {
        const formData = new FormData();
        formData.append('image', blobData.blob, 'cropped_photo.jpg'); // Assuming blobData is an object with a Blob

        try {
            const res = await fetch('/api/users/photo', {
                method: 'PATCH',
                body: formData,
            });

            const data = await res.json();

            if (res.status === 200) {

                const { dispatch } = thunkAPI;
                dispatch(getUser());

                return data;
            } else {
                console.log("PHOTO != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            console.log("Error -----------------------");
            console.error(err);
            return thunkAPI.rejectWithValue(err.message); // Adjusted for potential lack of err.response.data
        }
    }
);


export const login = createAsyncThunk(
    'users/login',
    async ({ email, password }, thunkAPI,) => {
        const body = JSON.stringify({
            email,
            password,
        });

        //routes/auth/login
        try {
            const res = await fetch('/api/users/login', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 200) {
                const { dispatch } = thunkAPI;
                //dohvati podatke o  korisniku
                dispatch(getUser());
                dispatch(getCompany());

                return data;
            } else {
                //console.log("login  thunkAPI.rejectWithValue(data)");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("login Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);


export const checkAuth = createAsyncThunk(
    'users/verify',
    async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/users/verify', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                const { dispatch } = thunkAPI;

                dispatch(getUser());
                //dispatch(getListings());

                return data;
            } else {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const refreshAuth = createAsyncThunk(
    'users/refresh',
    async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/users/refresh', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = await res.json();
            console.log("refresh - Refresham usera");

            if (res.status === 200) {
                console.log("refresh - Success");

                const { dispatch } = thunkAPI;
                dispatch(getUser());

                return data;
            } else {

                console.log("refresh - !Success");
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);



export const logout = createAsyncThunk(
    'users/logout',
    async (_, thunkAPI,) => {

        try {
            const res = await fetch('/api/users/logout', {  //routes/auth/login
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

//ovo  proširi za datashit ako trebaš još  data
const initialState = {
    isAuthenticated: false,
    user: null, //Iz dba od usera
    loading: false,
    registered: false,
    listings: null,
    company: null,
    invalid: false,
    //error: expandaj po potrebi
    invalidUser: false,
    userFound: false,
    loadingUser: false,
    app_menu: null,
};

//sinkroni dispatchi
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        //Synchronus action creator
        resetRegistered: state => {
            state.registered = false;
        },

    },

    //poziv bez dodatnih funkcionalnosti
    extraReducers: builder => {
        builder
            .addCase(register.pending, state => {
                state.loading = true;
            })
            .addCase(register.fulfilled, state => {
                state.loading = false;
                state.registered = true;
            })
            .addCase(register.rejected, state => {
                state.loading = false;
            })
            .addCase(login.pending, state => {
                state.loading = true;
                state.invalid = false;
                state.invalidUser = false;
            })
            .addCase(login.fulfilled, state => {
                state.loading = false;
                state.isAuthenticated = true;
            })
            .addCase(login.rejected, state => {
                state.loading = false;
            })
            .addCase(getUser.pending, state => {
                state.loading = true;
                state.loadingUser = true;
                state.invalidUser = false;
                state.userFound = false;
                state.invalid = false;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingUser = false;
                state.userFound = true;
                state.user = action.payload;
            })
            .addCase(getUser.rejected, state => {
                state.loading = false;
                state.invalidUser = true;
                state.userFound = false;
                state.loadingUser = false;
            })
            .addCase(checkAuth.pending, state => {
                state.loading = true;
            })
            .addCase(checkAuth.fulfilled, state => {
                state.loading = false;
                state.isAuthenticated = true;
            })
            .addCase(checkAuth.rejected, state => {
                state.loading = false;
            })
            .addCase(logout.pending, state => {
                state.loading = true;
            })
            .addCase(logout.fulfilled, state => {
                state.loading = false;
                state.isAuthenticated = false;
                state.user = null;
            })
            .addCase(logout.rejected, state => {
                state.loading = false;
            })
            .addCase(getListings.pending, state => {
                state.loading = true;
            })
            .addCase(getListings.fulfilled, (state, action) => {
                state.loading = false;
                state.listings = action.payload;
            })
            .addCase(getListings.rejected, state => {
                state.loading = false;
            })
            .addCase(uploadPhoto.pending, state => {
                state.loading = true;
            })
            .addCase(uploadPhoto.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(uploadPhoto.rejected, state => {
                state.loading = false;
            })
            .addCase(getCompany.pending, state => {
                state.loading = true;
            })
            .addCase(getCompany.fulfilled, (state, action) => {
                state.loading = false;
                state.company = action.payload;
            })
            .addCase(getCompany.rejected, state => {
                state.loading = false;
            })
            .addCase(refreshAuth.pending, state => {
                state.invalid = false;
            })
            .addCase(refreshAuth.rejected, state => {
                state.invalid = true;
                state.userFound = false;
                state.user = null;
                state.company = null;
                state.isAuthenticated = false;
                console.log("state", JSON.stringify(state));
            })
            .addCase(appMenuViewByCompany.pending, state => {

            })
            .addCase(appMenuViewByCompany.fulfilled, (state, action) => {
                state.app_menu = action.payload;
            })
            .addCase(appMenuViewByCompany.rejected, state => {
                state.app_menu = null;
            });
    }
});

export const { resetRegistered } = userSlice.actions
export default userSlice.reducer



//const counterSlice = createSlice({
//    name: 'counter',
//    initialState,
//    reducers: {
//        increment(state) {
//            state.value++
//        },
//        decrement(state) {
//            state.value--
//        },
//        incrementByAmount(state, action) {
//            state.value += action.payload
//        },
//    },
//})

//async thunk za async
//const myNewActionCreate

//export const { increment, decrement, incrementByAmount } = counterSlice.actions
//export default counterSlice.reducer