import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const reservationSetupPatch = createAsyncThunk(
    'reservation/setup/patch/',
    async (payload, thunkAPI,) => {

        const body = JSON.stringify(payload);
        const { id, company_id } = payload;
        console.log("payload: ", JSON.stringify(payload))

        try {
            const res = await fetch(`/api/reservation/setup/patch/${id}/${company_id}/`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("ReservationSetupPatch != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("ReservationSetupPatch Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);