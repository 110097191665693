import React, { useState, useEffect } from 'react';
import photo1 from "../images/bar1.jpg";

const ImageDjango = ({ className = "avatar", src, alt = "" }) => {
  // State to handle error loading the provided src
  const [imgSrc, setImgSrc] = useState(src ? `${process.env.REACT_APP_MEDIA}${src}` : 'path/to/default/image.png');

  useEffect(() => {
    if (src) {
      setImgSrc(`${process.env.REACT_APP_MEDIA}${src}`);
    }
}, [src]);

  // Handler for the onError event
  const handleError = () => {
    setImgSrc(photo1); // Set your default image path here
  };

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={handleError}
      style={{
        width: "100%",
        height: "auto",
        borderRadius: '10px',
        border: "2 solid black"
      }}
    />
  );
};

export default ImageDjango;