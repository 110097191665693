import Layout from "components/Layout";
import { Navigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProfilePictureForm from "./components/ProfilePictureForm"
import CompanyInfoForm from "./components/CompanyInfoForm"
import ImageDjango from './components/ImageDjango';
import UserDetailForm from './components/UserDetailForm';
import ModalWithChildren from "containers/modals/ModalWithChildren";
import ImageSelector from "./reservationsSidebar/forms/ImageSelector";
import { uploadPhoto } from "features/user";
import { permissionsView } from "features/permissionsSys/PermissionsView";
import { permissionsCoworkersView } from "features/permissionsSys/PermissionsCoworkersView";

import PermissionDisplay from "./reservationsSidebar/forms/PermissionDisplay";
import PermissionsDisplayTable from "./reservationsSidebar/forms/PermissionsDisplayTable";
import UserSearch from "./components/UserSearch";

import { roomsView } from "features/rooms/RoomsView";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const DashboardPage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated, user, loading, company, invalid } = useSelector(state => state.user);
    const { permissions, coworkers, changesPermissions } = useSelector(state => state.permissions)
    const companyA = {};

    const [singleton_1, setSingleton_1] = useState(true);
    const [singleton_2, setSingleton_2] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);

    const [fileForUpload, setFileForUpload] = useState(null);
    const [imageSelector, setImageSelector] = useState(null);

    const [inPermissions, setInPermissions] = useState(coworkers || []);
    const [outPermissions, setOutPermissions] = useState([]);

    //KO je invalid user cert
    useEffect(() => {
        if (invalid) {
            navigate('/login');
        }
    }, [invalid]);


    useEffect(() => {
        if (singleton_1 === true) {
            dispatch(permissionsView());
            setSingleton_1(false);
        }
    }, [dispatch, singleton_1]);

    useEffect(() => {

        if (company) {
            dispatch(permissionsView());
        }

    }, [dispatch, company]);

    useEffect(() => {

        if (permissions && singleton_2 === true) {

            //console.log("ls: " + JSON.stringify(permissions));

            const payload = { "company_id": permissions.company };
            dispatch(permissionsCoworkersView(payload));
            dispatch(roomsView(payload));
            setSingleton_2(false);
        }
    }, [dispatch, permissions]);

    //Ako ima izmjena u permisissionima
    useEffect(() => {

        if (changesPermissions === true) {
            const payload = { "company_id": permissions.company };
            dispatch(permissionsCoworkersView(payload));
        }

    }, [dispatch, changesPermissions]);


    useEffect(() => {
        setInPermissions(coworkers);
        setOutPermissions(coworkers);
    }, [coworkers]);



    var image = '/media/fallback.jpg'
    if (user && user.image) {
        image = user.image;
    }


    const generateRandomString = (length = 6) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };

    const handleUploadCroppedImage = () => {
        if (!fileForUpload) {
            console.log("No cropped image available for upload.");
            return;
        }
        const uniqueFileName = `cropped_photo_${generateRandomString()}.jpg`;
        try {
            const result = dispatch(uploadPhoto({ blob: fileForUpload, fileName: uniqueFileName }));
            setFileForUpload(null);
            setShowModal(false)


        } catch (error) {
            console.error("Upload failed:", error);
        }
    };

    const onAddUser = (userData) => {

    };


    if (!isAuthenticated && !loading && user === null) {
        return <Navigate to='/login' />;

    }


    //kako bi user ostao ulogiran  kod refresha stranice
    return (
        <Layout title='Sonex | Dashboard' content='Dashboard page'>

            {loading || !user ? (
                <div style={{
                    position: 'fixed', // Use fixed to keep it in the center regardless of scrolling
                    top: '0', // Start from the top of the viewport
                    left: '0', // Start from the left of the viewport
                    right: '0', // Extend to the right of the viewport
                    bottom: '0', // Extend to the bottom of the viewport
                    display: 'flex', // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center', // Center vertically
                    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: Adds a white semi-transparent background
                    zIndex: '9999' // Ensure it's above other content
                }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>

            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
                    <div style={{ width: '100%', paddingTop: '0px', marginTop: "30px", maxWidth: "800px" }}>
                        <div style={{ backgroundColor: "white", marginBottom: "20px", padding: "15px", borderRadius: '15px 15px 15px 15px' }}>
                            <span style={{ display: 'block', height: '10px', alignContent: "center" }}></span>
                            <div className="col-lg-12 col-md-12 col-sm-12" style={{ fontSize: "14px" }}>
                                <p className="title">{t('profil')}</p>
                                <span style={{ display: 'block', height: '0px' }}></span>
                                <hr />
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <UserDetailForm user={user} />
                                </div>
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <span style={{ display: 'block', height: '10px', alignContent: "center" }}></span>
                                    <button type="button" className="btn_matrix"
                                        onClick={() => setShowModal(true)} style={{ height: '20px', paddingTop: '0px', float: 'right', fontSize: '12px', width: '200px' }}>
                                        {t('edit_photo')}</button>
                                    {company ? (<></>) : (<>
                                        <button type="button" className="btn_matrix"
                                            onClick={() => setShowModal2(true)} style={{ height: '20px', paddingTop: '0px', float: 'right', fontSize: '12px', width: '200px' }}>
                                            {t('create_baseness_account')}</button></>)}
                                </div>
                                <div className="col-lg-8 col-md-12 col-sm-12">
                                    <span style={{ display: 'block', height: '10px', alignContent: "center" }}></span>
                                </div>
                                <span style={{ display: 'block', height: '40px' }}></span>
                                <PermissionDisplay data={permissions} />
                                <hr />
                                <ModalWithChildren isOpen={showModal} onClose={() => setShowModal(false)} renderSave={false} renderDelete={false} style={{ maxWidth: "600px", }}>
                                    <div style={{ width: "100%" }}>
                                        <h5>{t('-')}</h5>
                                        <hr />
                                    </div>
                                    <ImageSelector setFileForUpload={setFileForUpload} selectedImage={setImageSelector} />
                                    <button type="button" className="btn_matrix"
                                        onClick={handleUploadCroppedImage} style={{ height: "100px", margin: '0px' }}>
                                        {t('save_photo')}</button>
                                </ModalWithChildren>
                                <ModalWithChildren isOpen={showModal2} onClose={() => setShowModal2(false)} renderSave={false} renderDelete={false} style={{ maxWidth: "600px", }}>
                                    <div>
                                        <CompanyInfoForm company={companyA} closeModal={setShowModal2} />
                                    </div>
                                </ModalWithChildren>
                                <ModalWithChildren isOpen={showModal3} onClose={() => setShowModal3(false)} renderSave={false} renderDelete={false} style={{ maxWidth: "600px", }}>
                                    <div>
                                        <UserSearch />
                                    </div>
                                </ModalWithChildren>
                            </div>
                        </div>
                    </div>
                </div>

            )
            }
        </Layout >
    );
};

export default DashboardPage;