//REDUX  https://redux-toolkit.js.org/introduction/getting-started
import { configureStore } from "@reduxjs/toolkit";
import userReducer from 'features/user'
import reservationReducer from 'features/reservation';
import publicReducer from 'features/public';
import ticketReducer from 'features/ticket';
import permissionsReducer from 'features/permissions';
import frontpageReducer from 'features/frontpage';

export const store = configureStore({
    reducer: {
        user: userReducer,
        reservation: reservationReducer,
        public: publicReducer,
        ticket: ticketReducer,
        permissions: permissionsReducer,
        frontpage: frontpageReducer,
    },

    devTools: process.env.NODE_ENV !== 'production'
});

//import { useSelector } from "react-redux";
//const user = useSelector(state => state.user)


//const initialState = {
//
//}