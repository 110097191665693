// Assuming the file path is correct based on your project structure
import React, { useState, Suspense, useEffect } from 'react';
import Sidebar from './reservationsSidebar/sidebar/Sidebar';
import { sidebarConfig } from './reservationsSidebar/sidebar/SidebarConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentView } from '../features/reservation'; // Adjust this import to your file structure
import { showEventsList } from "features/eventSys/ShowEventsList";
import Layout from "components/Layout";
import { useNavigate } from 'react-router-dom';

import { permissionsView } from "features/permissionsSys/PermissionsView";
import { permissionsCoworkersView } from "features/permissionsSys/PermissionsCoworkersView";

import { roomsView } from "features/rooms/RoomsView";
import { refreshAuth } from 'features/user';


const Reservations = () => {

    const { company, invalid } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // Use useSelector to retrieve the current view from the Redux store
    const storedView = useSelector(state => state.reservation.currentView);
    const initialComponent = sidebarConfig[0].items[0].component;
    const { permissions, coworkers, changesPermissions } = useSelector(state => state.permissions)
    const [activeView, setActiveView] = useState(initialComponent);

    const [singleton_1, setSingleton_1] = useState(true);
    const [singleton_2, setSingleton_2] = useState(true);

    const [inPermissions, setInPermissions] = useState(coworkers || []);
    const [outPermissions, setOutPermissions] = useState([]);


    useEffect(() => {
        // Dispatch the action to update currentView in the Redux store when activeView changes
        dispatch(setCurrentView(activeView));
    }, [dispatch, activeView]);

    //KO je invalid user cert
    useEffect(() => {
        if (invalid) {
            navigate('/login');
        }
    }, [invalid]);


    useEffect(() => {
        const refreshUser = () => {
            dispatch(refreshAuth());
            dispatch(permissionsView());
        };

        const intervalId = setInterval(refreshUser, 10 * 60 * 1000); // 10 minutes in milliseconds
        return () => clearInterval(intervalId);
    }, [dispatch]);


    useEffect(() => {
        if (singleton_1 === true) {
            dispatch(permissionsView());
            setSingleton_1(false);
        }
    }, [dispatch, singleton_1]);

    useEffect(() => {

        if (permissions && singleton_2 === true) {

            //console.log("ls: " + JSON.stringify(permissions));

            const payload = { "company_id": permissions.company };
            dispatch(permissionsCoworkersView(payload));
            dispatch(roomsView(payload));
            setSingleton_2(false);
        }
    }, [dispatch, permissions]);

    //Ako ima izmjena u permisissionima
    useEffect(() => {

        if (changesPermissions === true) {
            const payload = { "company_id": permissions.company };
            dispatch(permissionsCoworkersView(payload));
        }

    }, [dispatch, changesPermissions]);


    useEffect(() => {
        setInPermissions(coworkers);
        setOutPermissions(coworkers);
    }, [coworkers]);

    const renderViewComponent = (componentName) => {
        const Component = React.lazy(() => import(`./reservationsSidebar/view/${componentName}`));
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Component />
            </Suspense>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent form submission from reloading the page
    };

    return (


        <div className="container" style={{ paddingTop: '10px' }}>
            <form onSubmit={handleSubmit}>
                <div >
                    <Sidebar onSelect={(componentName) => { setActiveView(componentName); }} />
                    <div style={{ flex: 2, paddingLeft: '0px', paddingRight: '0px' }}>
                        {renderViewComponent(activeView)}
                    </div>
                </div>
            </form>
        </div>

    );
};

export default Reservations;
