// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                    "from_label": "From",
                    "croatia": "Croatia",
                    "terms_of_service": "Terms of Service",
                    "terms_of_payments": "Payment Terms",
                    "payments_provided_by": "Payments Provided By",
                    "privacy_declaration": "Privacy Policy",
                    "payment_safety": "Payment Security",
                    "supported_by": "Supported By",
                    "payment": "Payment",
                    "pay_with": "Pay With",
                    "safety_programs": "Security Programs",
                    "safety_declaration": "Privacy and Data Protection Statement",
                    "safety_declaration_text": "Sonexapp is committed to protecting customers' personal data by collecting only necessary, basic information required to fulfill our obligations; informing customers about the use of collected data, giving customers the choice about the use of their data, including the option to decide whether or not they want their name removed from lists used for marketing campaigns. All customer data is strictly protected and accessible only to employees who need it to perform their job. All Sonexapp employees and business partners are responsible for respecting the principles of privacy protection.",
                    "payment_description": "Purchases made through the web shop can only be paid by card. The reservation paid for is only available for the selected time slot. Ticket prices are shown on their respective cards. All fees are included in the displayed price.",
                    "welcome": "Welcome",
                    "hello": "Hello",
                    "person_count": "Number of People",
                    "address_uppercase": "ADDRESS",
                    "from": "From",
                    "time_of_reservation": "Reservation Time",
                    "reservation_uppercase": "RESERVATION",
                    "name_label": "Name:",
                    "surname_label": "Surname:",
                    "phone_number_label": "Mobile Number:",
                    "choose_menu": "Choose Menu",
                    "no_available_menus": "No Available Menus",
                    "show_menus": "Show Menus",
                    "price": "Price",
                    "name_surname_label": "Name and Surname:",
                    "on_date_label": "On Date:",
                    "comment_label": "Comment:",
                    "send_request": "Send Request",
                    "frontpage": "Homepage",
                    "login": "Login",
                    "register": "Register",
                    "register_success_text": "Reservation request successfully sent. After processing the request, you will receive a confirmation link via SMS or Email.",
                    "register_error_text": "Error sending reservation request.",
                    "language": "Language",
                    "error_name_is_obligatory": "Name field is required!",
                    "error_surname_is_obligatory": "Surname field is required!",
                    "error_email_is_obligatory": "Email field is required!",
                    "error_phone_is_obligatory": "Phone number field is required!",
                    "tickets_available": "Tickets Available",
                    "number_of_tickets": "Number of Tickets",
                    "confirm_reservation": "Confirm Reservation",
                    "cancel_ticket": "Cancel Ticket",
                    "venue_name": "Venue Name",
                    "request_reservation_for": "Request Reservation For:",
                    "search_by_name_and_or_address": "Search by Name or Address",
                    "reservation_confirmed": "Reservation confirmed, save the link and show the QR code at the entrance!",
                    "frontpage_text_1": "Sonex is your ideal application for easy management of reservations and event organization. We offer you a technical solution for managing reservations, tables, and events. By clicking on the venue card, you can see details, image gallery, and send a reservation request or view the calendar of upcoming events.",
                    "name_implied_label": "Name:",
                    "about_label": "About Us:",
                    "address_label": "Address:",
                    "contact": "Contact",
                    "events": "Events:",
                    "overview_disclaimer": "You will receive a reservation confirmation via email or SMS once your request has been processed.",
                    "broj_ulaznica": "Number of Tickets",
                    "card_payments_provided_by": "Card Payments Provided By",
                    "akontacija": "Advance Payment",
                    "naknade": "Transaction Fees",
                    "user_confirmation": "I have read and agree with the terms of service and website terms. *",
                    "za_platiti": "To Pay",
                    "payment_card_text": "Save this link. After payment, this card will contain a QR code which you can show at the entrance as confirmation if needed.",
                    "full_price": "Full Price",
                    "acontation": "Advance Payment",
                    "total": "Total",
                    "pay": "Pay",
                    "minimum": "Min. ",
                    "vrijeme_od": "Time From",
                    "vrijeme_do": "Time To",
                    "error_date_to_is_obligatory": "End Time is required",
                    "error_date_from_is_obligatory": "Start Time is required",
                    "error_date_to_less_than_date_from": "End Time must be after Start Time",
                    "timespan_text": "Select the time range you want to make a reservation for",
                    "u_trajanju_od": "For a Duration of",
                    "extra_options": "Additional Options",
                    "time_span": "Time Range",
                    "extra_text": "",
                    "your_digital_partner_for": "YOUR DIGITAL PARTNER FOR",
                    "managing_reservations_for_hospitality": "Managing Reservations in Hospitality Venues",
                    "optimize_your_bizz": "Optimize your business and increase guest satisfaction.",
                    "search_caps": "SEARCH",
                    "fallow_all_your_reservations": "Track all your reservations in one place. Our application allows you to see the availability of tables, rooms, or other capacities in real-time, manage changes, and automatically notify guests of their reservations.",
                    "effectively_manage_reservations": "Effectively Manage Reservations",
                    "personalization_of_reservation_request": "Personalization of Reservation Requests",
                    "do_you_have_a_special_service_which_you_offer_to_your_guests": "Do you have special services you offer to guests? Our application allows you to personalize reservation requests to provide top-notch service.",
                    "automatize_reminders_and_notifications": "Automated Reminders and Notifications",
                    "forget_about_handwritten_notifications": "Forget about handwritten reminders. Our application automatically sends reminders and notifications to guests about their reservations via email or SMS, reducing the number of missed reservations and increasing capacity utilization.",
                    "profil": "Profile",
                    "edit_photo": "Edit Photo",
                    "create_baseness_account": "Create Business Account",
                    "save_photo": "Save Photo",
                    "select_photo": "Select Photo",
                    "user_details": "User Details",
                    "name_of": "Name of",
                    "status_label": "Status:",
                    "manager": "Manager",
                    "reception": "Reception",
                    "tickets": "Tickets",
                    "select_date_label": "Select Date:",
                    "no_data_to_sohw": "No data to show",
                    "refresh_list": "Refresh List",
                    "overview_business_data": "Overview of Business Data",
                    "public_page_overview": "Public Page Overview",
                    "edit_public_page": "Edit Public Page",
                    "business_pages": "Business Pages",
                    "log_off": "Log Off",
                    "my_profile": "My Profile",
                    "company_name_label": "Company Name:",
                    "company_type_label": "Company Type:",
                    "profession_label": "Industry:",
                    "num_of_employees_label": "Number of Employees:",
                    "year_of_founding_label": "Year of Founding:",
                    "about_us_label": "About Us:",
                    "tax_code_label": "Tax Code:",
                    "email_label": "Email:",
                    "accept": "Accept",
                    "company_data": "Company Information",
                    "llc": "LLC",
                    "corporation": "Corporation",
                    "partnership": "Partnership",
                    "private_person": "Private Person / Sole Proprietorship",
                    "hospitality": "Hospitality",
                    "services": "Services",
                    "other": "Other",
                    "select_year": "Select Year",
                    "search_user_by_id_label": "Search User by ID:",
                    "user_id_label": "User ID:",
                    "search": "Search",
                    "user_not_found": "User Not Found",
                    "id": "ID",
                    "name": "Name",
                    "surname": "Surname",
                    "add_user": "Add User",
                    "user_successfully_added": "User Successfully Added",
                    "user_allready_exist": "User Already Exists",
                    "bussiness_profile_label": "Business Profile:",
                    "services_label": "Service Packages:",
                    "service_name_label": "Service Package Name:",
                    "description_label": "Description:",
                    "create_process_and_manage_reservations": "Create, Process, and Manage Reservations",
                    "active": "Active",
                    "activate": "Activate",
                    "reception_and_client_processing": "Reception and Client Processing",
                    "check_tickets": "Check and Cancel Tickets",
                    "create_bussiness_account": "Create Business Account",
                    "users_and_permissions_label": "Users and Permissions:",
                    "name_and_surname": "Name and Surname",
                    "delete": "Delete",
                    "save_changes": "Save Changes",
                    "public_page_edit": "Edit Public Page",
                    "publicly_available": "Publicly Available",
                    "select_icon": "Select Icon.",
                    "select_icon_and_give_them_description_label": "Select Icons and Add Descriptions:",
                    "reservations": "Reservations",
                    "reservations_overview": "Reservations Overview",
                    "menu_overview": "Menu Overview",
                    "menus": "Menus",
                    "reservation_caps": "RESERVATION",
                    "reservation_details": "Reservation Details:",
                    "number_of_people_label": "Number of People:",
                    "menu_label": "Menu:",
                    "time_label": "Time:",
                    "internal_comment_label": "Internal Comment:",
                    "address_caps_label": "ADDRESS:",
                    "menu_details": "Menu Details",
                    "without_menu": "Without Menu",
                    "confirm_arrival_label": "Confirm Arrival:",
                    "num_of_people_label": "No. of People:",
                    "total_number_of_arrivals_label": "Total Number of Arrivals:",
                    "confirm_arrival": "Confirm Arrival",
                    "reservation_overview_label": "Reservations Overview:",
                    "basic_table_layout": "Basic Table Layout",
                    "tables_layout_label": "Table Layout:",
                    "search_label": "Search:",
                    "number_of_people": "Number of People",
                    "confirm": "Confirm",
                    "confirmed": "Confirmed",
                    "table": "Table",
                    "in_time": "In Time",
                    "edit": "Edit",
                    "confirmed_by_guest": "Confirmed by Guest",
                    "arrived": "Arrived",
                    "name_surname_gsm": "Name, Surname, GSM",
                    "available_menus_overview": "Available Menus Overview",
                    "checked_tickets": "Checked Tickets",
                    "business": "Business",
                    "place_table_for_reservations": "Assign Table for Reservation",
                    "confirm_reservations": "Confirm Reservations",
                    "send_reservation_request": "Send Reservation Request",
                    "events_overview": "Events Overview",
                    "create_menu": "Create Menu",
                    "settings": "Settings",
                    "reservations_settings": "Reservations Settings",
                    "public_card_settings": "Public Card Settings",
                    "events_": "Events",
                    "place_table_for_reservations_label": "Assign Table for Reservation:",
                    "place_table_for_reservations_description_text": "Select the date, reservation, and assign a table for that date. Confirmation of the reservation will save the assigned table for the selected reservation.",
                    "selected_reservation_and_table": "Selected Reservation and Table",
                    "asign_table": "Assign Table",
                    "create_new_reservation": "Create New Reservation",
                    "sittings_placement_on": "Seating Arrangement on",
                    "save_table_layout": "Save Table Layout",
                    "select": "Select",
                    "item_must_be_selected": "Item must be selected!",
                    "selected_menu_label": "Selected Menu:",
                    "available_menus": "Available Menus Overview",
                    "guest_comment_label": "Guest Comment:",
                    "table_and_reservation_must_be_selected": "Table or reservation not selected!",
                    "for_confirmation_of_reservation_select_reservation_and_assign_table": "For reservation confirmation, select the reservation and the table you want to assign to it",
                    "selected_reservation_allready_have_assign_table_on_date_label": "The selected reservation already has an assigned table on date:",
                    "confirmation_will_replace_currently_assign_table_with_selected_table": "Confirming the reservation will replace the currently assigned table with the selected table.",
                    "confirm_reservation_on_label": "Confirm reservation for the day:",
                    "confirmation_will_assign_a_table_for_selected_reservation": "By confirming the reservation, a table will be assigned to the selected reservation.",
                    "send_a_request_for_reservation_to_a_guest_sms": "Send a reservation request to a guest (SMS)",
                    "intern_comment_label": "Internal Comment:",
                    "back_to_reservation_creation": "Back to Reservation Creation",
                    "create_reservation_for_a_table_label": "Create a reservation for the table:",
                    "selected_contact_label": "Selected Contact:",
                    "nothing_selected": "Nothing Selected",
                    "send_request_for_confirmation_of_reservation_sms": "Send client a request for reservation confirmation (SMS)",
                    "create_reservation": "Create Reservation",
                    "error_while_creating_reservation": "Error creating reservation",
                    "edit_reservation_label": "Edit Reservation:",
                    "date_label": "Date:",
                    "time_from_label": "Time From:",
                    "time_to_label": "Time To:",
                    "reservation_confirm_": "Reservation Confirmed",
                    "reservation_executed": "Reservation Executed",
                    "reservation_canceled": "Reservation Canceled",
                    "table_label:": "Table: ",
                    "sitting_places_": "Sitting Places:",
                    "currently_assign_table": "Currently Assigned Table",
                    "duplicate": "Duplicate",
                    "to_duplicate_reservation_please_select_a_table": "Select a table to duplicate the reservation",
                    "press_link_and_confirm_your_arrival_for_reservation": "Click the link to confirm your arrival for the reservation",
                    "contact_list": "Contact List",
                    "search_by_name_or_surname": "Search by Name or Surname",
                    "no_available_contacts": "No Available Contacts",
                    "telephone_label": "Telephone:",
                    "surrname_error": "Surname is required.",
                    "phone_number_error": "Phone number is required.",
                    "time_from_error": "Start time is required.",
                    "time_to_error": "End time is required.",
                    "filter_by_number_of_people_from_request": "Filter by number of people from the request",
                    "list_of_tables": "List of Tables",
                    "name_of_table": "Table Name",

                    "name_surname": "Name and Surname",
                    "details": "Details",
                    "select_table": "Select Table",
                    "show_for_all_dates": "Show for all dates",
                    "show_canceled_requests": "Show Canceled Requests",
                    "reservation_requests": "Reservation Requests",
                    "confirm_reservations_on_a_day": "Confirm Reservations for a Day:",
                    "link_copied": "Link Copied!",
                    "table_label": "Table:",
                    "sittings_label": "Seats:",
                    "reservation_details_label": "Reservation Details:",
                    "on_time": "Time:",
                    "send_request_for_confirmation_of_reservation_on_label": "Send a request for reservation confirmation on date:",
                    "add_link": "Add Link",
                    "copy_link": "Copy Link",
                    "send_message": "Send Message",
                    "edit_reservation": "Edit Reservation",
                    "confirm_reservation_description_text": "Confirm the reservation and/or send the contact a request for reservation confirmation. The confirmation request is automatically sent 1 day before the scheduled time for confirmed reservations. If needed, you can send a reservation confirmation request at any time.",
                    "reservations_label": "Reservations:",
                    "confirmed_label": "Confirmed:",
                    "unconfirmed_label": "Unconfirmed:",
                    "unconfirmed_guest_label": "Guest not confirmed:",
                    "request_guest_confirmation": "Request Guest Confirmation",
                    "send_reservation_request_label": "Send Reservation Request:",
                    "send_reservation_request_description": "Send a reservation request to the contact, select the date, select the contact or add a new contact.",
                    "received_requests": "Received Requests",
                    "email": "Email",
                    "prefix": "Prefix",
                    "number": "Number",
                    "date": "Date",
                    "reservation_request_on_label": "Reservation request on date:",
                    "save_contact": "Save Contact",
                    "send_message_to_selected": "Send Message to Selected",
                    "error_time_from_is_mandatory": "Start time is required!",
                    "error_time_to_is_mandatory": "End time is required!",
                    "list_of_messages": "List of Messages",
                    "message": "Message",
                    "edit_event": "Edit Event",
                    "create_event": "Create Event",
                    "error_name_is_mandatory": "Name is a required field!",
                    "error_description_is_mandatory": "Description is a required field!",
                    "error_date_is_mandatory": "Date is a required field!",
                    "error_image_is_mandatory": "Image is required!",
                    "event_named_label": "Event named:",
                    "_is_created_successful_label": "was successfully created",
                    "all_necessary_fields_are_not_filled": "Not all required fields are filled for creating the event!",
                    "created_events_overview": "Created Events Overview",
                    "create_event_uppercase": "CREATE EVENT",
                    "edit_uppercase": "EDIT",
                    "delete_uppercase": "DELETE",
                    "event_name_label": "Event Name:",
                    "event_description_label": "Event Description:",
                    "save": "Save",
                    "edit_contact": "Edit Contact",
                    "menu_name_label": "Menu Name:",
                    "tittle_label": "Title:",
                    "subtitle_label": "Subtitle:",
                    "subtitle2_label": "Subtitle 2:",
                    "menu_item_label": "Menu Item:",
                    "add_item": "Add Item",
                    "remove_item": "Remove Item",
                    "price_label": "Price:",
                    "date_from_label": "From Date:",
                    "save_menu": "Save Menu",
                    "error_sitting_places": "Number of sitting places is required.",
                    "default_room": "Main Room",
                    "unplaced": "Unassigned",
                    "edit_table_label": "Edit Table:",
                    "table_name": "Table Name:",
                    "number_of_sitting_places": "Number of Sitting Places:",
                    "room_label": "Room:",
                    "select_table_shape_label": "Select Table Shape:",
                    "workdays_and_hours": "Workdays and Hours",
                    "error_flor_plan_photo": "Selecting a floor plan photo is required.",
                    "edit_daily_setup": "Edit Daily Setup",
                    "edit_daily_setup_description": "Select workdays and work hours and create a list of tables. These settings are used for regular workdays, i.e., for days when there is no event. The created table list has a graphical representation whose position can be adjusted by dragging them to the desired location.",
                    "monday": "Mon",
                    "tuesday": "Tue",
                    "wednesday": "Wed",
                    "thursday": "Thu",
                    "friday": "Fri",
                    "saturday": "Sat",
                    "sunday": "Sun",
                    "remove_last_added_table": "Remove Last Table",
                    "room": "Room",
                    "layout": "Layout",
                    "create_new_table_layout": "Create New Table Layout",
                    "select_room_plan": "Select Floor Plan",
                    "add_room": "Add Room",
                    "create_new_table": "Create New Table",
                    "edit_table": "Edit Table",
                    "delete_room": "Delete Room",
                    "room_name_label": "Room Name:",
                    "error_number_of_people": "Number of people is required.",
                    "floor_plan": "Floor Plan",
                    "change_floor_plan": "Change Floor Plan:",
                    "create_new_table_layout_label": "Create New Table Layout:",
                    "table_layout_name_label": "Table Layout Name",
                    "edit_menu": "Edit Menu",
                    "edit_menu_short_descripton": "Make the desired changes and save them",
                    "menu_with_name_label": "Menu named:",
                    "_is_not_created_label": "NOT created!",
                    "create_new_menu": "Create New Menu",
                    "create_new_menu_description": "Create a new menu card, assign it a name, subtitles, descriptions, add menu items, and save them. After saving, you can view them in the menu overview, and they are visible to users as options when making a reservation",
                    "sending_card_info_text": "Review the card that is sent to users from the Send Request option (Sample example, save changes before preview to ensure the card works correctly)",
                    "defined_time_duration_hours_minutes": "Defined Time Duration (Hours: Minutes):",
                    "in_span_from_label": "Time range from:",
                    "to_label": "to:",
                    "reservation_card_setup": "Reservation Card Setup",
                    "save_uppercase": "SAVE",
                    "reservation_card_setup_description": "Settings for steps offered to clients on the card when creating orders and settings for how reservations are conducted. The card is a graphical element that the user sees when they click on the link sent to them via SMS or Email.",
                    "predefined_text": "Predefined SMS - EMAIL Text",
                    "predefined_text_description": "The text that initially fills the message field, if you send the same message to users, so you don't have to write it every time, you can define it here.",
                    "text_for_sms_and_email_label": "Text for SMS and Email:",
                    "time_frames_setup": "Time Frames Setup",
                    "time_frames_setup_description": "Free selection will allow the client to choose the arrival time and reservation duration in the format from - to. Defined duration will allow the user to choose the start time of the reservation, and the reservation duration is set in the field that appears when this option is selected. Defined time range will offer the client time slots created in the corresponding fields from which the client can choose one.",
                    "open_selection_from_to": "Free Selection (From - To)",
                    "defined_duration_from_in_duration": "Defined Duration (From - In Duration)",
                    "defined_time_slots": "Defined Time Range",
                    "guests_setup": "Guests Setup",
                    "guests_setup_description": "Settings for the minimum number of guests and displaying available menus.",
                    "show_menu_selection": "Show Menu Cards",
                    "minimal_guest_count": "Minimum Number of Guests",
                    "specific_items": "Specific Items",
                    "specific_items_descriptions": "Items you want to offer to the guest that are specific to you",
                    "select_1_from_the_list": "Select 1 from the List",
                    "multiple_choice": "Multiple Choice",
                    "menu_item": "Menu Item",
                    "extra_text_to_show_on_reservation_card": "Additional Text to Show on Reservation Card",
                    "extra_text_to_show_on_reservation_card_desc": "Text and/or data you want to display to the user on the card",
                    "show_extra_text": "Show Additional Text",
                    "text_label": "Text:",
                    "login_text_1": "Welcome to our Orders & Reservations system. At the heart of our mission is the desire to simplify the ordering and reservation process, turning it into a worry-free experience. Our platform is designed with you in mind - fast, reliable, and intuitive, ensuring your orders and reservations are processed in record time, without complications.",
                    "login_text_2a": "Speed and Efficiency: In just a few clicks, your order or reservation will be confirmed.",
                    "login_text_2b": "Reliability: Our system uses the latest technologies to ensure the accuracy of your orders and reservations.",
                    "login_text_2c": "Adaptability: Whether you are an individual or a company, our platform offers tailored solutions.",
                    "login_text_2d": "Outstanding Support: Our customer support team is available 24/7.",
                    "login_text_3": "Start your worry-free experience with Orders & Reservations today and discover how we can transform your planning into enjoyment. Welcome to the future of planning!",
                    "password_label": "Password:",
                    "forgotten_password_qm": "Forgotten Password?",
                    "why_sonex_qm": "Why Choose Sonex?",
                    "register_user_account": "Register User Account",
                    "tables": "Tables:",
                    "birth_date": "Birth date",
                    "acca_reg_form_title": "ACCA ODCBE Registration form",

                    "select_exam_date": "Select exam date",
                    "select_exam": "Select exam",
                    "first_name_is_required": "First Name is required",
                    "last_name_is_required": "Last Name is required",
                    "email_is_required": "Email is required",
                    "acca_id_is_required": "ACCA ID is required",
                    "birth_date_is_required": "Birth Date is required",
                    "phone_number_is_required": "Phone Number is required",
                    "custom_phone_prefix_is_required": "Custom Phone Prefix is required",
                    "name_is_required": "Name is required",
                    "address_is_required": "Address is required",
                    "choose_exam_date": "Choose exam date!",
                    "choose_exam": "Choose exam!",
                    "student_details": "STUDENT DETAILS",
                    "birth_date": "Birth Date",
                    "contact_number": "Contact number",
                    "exam_information": "EXAM INFORMATION",
                    "payment_proforma": "PAYMENT / PROFORMA INVOICE DETAILS",
                    "pro_forma_text": "Please insert data for the individual or company responsible for paying the invoice. Please carefully review all details before submitting the form, as after invoices have been issued it is not possible to make any changes.",
                    "company_name_if_applicable": "Company name (if applicable)",
                    "oib_vat_if_applicable": "OIB/VAT number (as applicable)",
                    "tos": "TERMS AND CONDITIONS",
                    "kpmg_1": "By submitting this form you agree to the following:",
                    "exam_registration": "EXAM REGISTRATION",
                    "kpmg_2": "Exam booking must be made at least 7 days prior to the Candidate’s chosen examination date(s) by submitting an ACCA ODCBE registration form (“registration form”) together with proof of payment.",
                    "kpmg_3": "KPMG Croatia d.o.o., Ulica Ivana Lučića 2a, 10000 Zagreb (“the Centre”) will endeavour to provide the selected date for examination, however we reserve the right to reschedule should there be any interruptions or technical issues.",
                    "kpmg_4": "The Centre operates on a first come, first served policy on all computer based examinations, which could mean the session the Candidate wishes to sit for may become full. In this case the registration will be moved to the next scheduled session.",
                    "kpmg_5": "Exams are sat on Thursdays (excluding Croatian public holidays), from 10.00 am to 12.00 pm.",
                    "kpmg_6": "Candidate’s exam booking cannot be confirmed until the Centre has received full payment and a fully completed registration form.",
                    "kpmg_7": "Once exam booking is confirmed (by email) the Candidate forfeits all the fees paid in case of decision to cancel or failure to attend the scheduled exam.",
                    "kpmg_8": "If the Candidate wishes to postpone their exam, they must inform the Centre (by email) 24 hours prior to the scheduled exam, at the latest.",
                    "kpmg_9": "The Centre will not be held responsible should there be any interruptions with electricity and/or natural causes beyond our control.",
                    "kpmg_10": "ACCA reserves the right to cancel a CBE examination for any reason whatsoever. All queries should be directed to ACCA if in any event the examination is cancelled by ACCA.",
                    "kpmg_11": "This exam registration will only be accepted if full fees are paid in advance.",
                    "kpmg_12": "Electronic signatures are accepted.",
                    "exam_capital": "EXAM",
                    "kpmg_13": "Candidates are required to be at the examination site fifteen minutes before the exam starts.",
                    "kpmg_14": "Candidates will not be able to sit for their examination without providing an official valid form of identification such as ID, Passport or Driver’s Licence.",
                    "kpmg_15": "It is the Candidates’ own responsibility to bring own scientific calculator (without text input option). Pencils and paper for rough workings are provided by the Centre.",
                    "kpmg_16": "It is the Candidates' own responsibility to read the “Information sheet for ODCBE students” that will be provided to the Candidate upon exam registration confirmation.",
                    "i_have_read_and_accept_the_terms_of_service": "I have read and accept the Terms of Service",
                    "privacy_notice": "PRIVACY NOTICE",
                    "kpmg_17": "In relation to Candidate’s personal data received hereunder, the Centre acts as a data controller. The purpose of data processing is registration of the Candidate for ACCA exams and collecting fees for the same. The legal bases for processing of Candidate’s data are the following:",
                    "kpmg_17b": "− processing is necessary for performance of a contract to which the Candidate is party or in order to take steps at the request of the Candidate prior to entering into a contract, i.e. registration with ACCA so that the Candidate is able to take ACCA exam (Article 6(1)(b) of GDPR),",
                    "kpmg_17c": "− Processing is necessary for compliance with a legal obligation to which data controller is subject, i.e. obligation to issue an invoice to the Candidate whose mandatory content is prescribed by Croatian legislation (Article 6(1)(c) of GDPR).",
                    "kpmg_18": "As a result, provision of the data requested in this form is mandatory, and failure to provide the same can result in an inability to register the Candidate for ACCA exam.",
                    "kpmg_19": "The received data can be shared with ACCA and Croatian authorities. More information on how ACCA will process the Candidates’ data can be found in ACCA’s privacy notice available on the following link: https://www.accaglobal.com/my/en/footertoolbar/privacy/data-protection.html.",
                    "kpmg_20": "The Centre shall retain the data for as long as required to comply with the statutory requirements for keeping of accounting documentation, following which the data shall be deleted. The processing will not involve automated decision making.",
                    "kpmg_21": "In relation to personal data processing, the Candidate has the following rights: the right of access, restriction of processing, erasure, data portability, right to object to processing, right to lodge a complaint with a supervisory authority (in Croatia, this is the Personal Data Protection Agency).",
                    "kpmg_22": "In case any questions related to personal data processing or to exercise the above rights, please contact the Centre’s data protection officer at dpo@kpmg.hr.",
                    "i_have_read_and_accept_the_privacy_notice": "I have read and accept the Privacy Notice",
                    "candidate_declaration": "CANDIDATE DECLARATION",
                    "kpmg_23": "By submitting this application form, I confirm that I have read, understand and agree to the terms and conditions that are set out in the guidance notes attached to this registration form.",
                    "kpmg_24": "I will abide by ACCA’s general rules for CBE stated in the information sheet I will be provided upon registration confirmation.",
                    "kpmg_25": "I, the undersigned, hereby certify that the information provided in this form is accurate and up to date, and that I have read and understand the terms and conditions outlined in this form.",
                    "i_have_read_and_accept_the_candidate_declaration": "I have read and accept the Candidate Declaration",
                    "kpmg_register": "Register",
                    "educations": "Educations",
                    "custom": "Custom",
                    "custom_desc": "Custom upgrades on user request",
                    "kpmg_tests": "KPMG tests",
                    "time_frame": "Time frame",
                    "phone_num_label": "Phone number",
                    "unconfirmed_guest": "Unconfirmed guest",
                    "employees": "Employees",
                    "employees_and_members": "Employees and members",
                    "schedule": "Schedule",
                    "employee_list": "Employee list",
                    "members": "Members",
                    "products_and_services": "Products and services",

                }
            },
            hr: {
                translation: {
                    "from_label": "Od",
                    "croatia": "Hrvatska",
                    "terms_of_service": "Uvjeti korištenja",
                    "terms_of_payments": "Uvjeti plaćanja",
                    "payments_provided_by": "Plaćanje omogućio",
                    "privacy_declaration": "Izjava o privatnosti",
                    "payment_safety": "Sigurnost plaćanja",
                    "supported_by": "Podržava nas",
                    "payment": "Plaćanje",
                    "pay_with": "Platite sa",
                    "safety_programs": "Sigurnosni programi",
                    "safety_declaration": "Izjavu o zaštiti i prikupljanju osobnih podataka i njihovom korištenju",
                    "safety_declaration_text": "Sonexapp obavezuje se pružati zaštitu osobnim podacima kupaca, na način da prikuplja samo nužne, osnovne podatke o kupcima/korisnicima koji su nužni za ispunjenje naših obveza; informira kupce o načinu korištenja prikupljenih podataka, daje kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s lista koje se koriste za marketinške kampanje. Svi se podaci o korisnicima strogo čuvaju i dostupni su samo djelatnicima kojima su ti podaci nužni za obavljanje posla. Svi djelatnici Sonexapp-a i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti.",
                    "payment_description": "Plaćanje kupovine putem web shopa moguće je isključivo karticom. Plaćena rezervacija dostupna je isključivo u odabranom terminu. Cijena ulaznica/kotizacija istaknuta je na njihovim pripadajućim karticama. U prikazanu cijenu su uključene sve naknade.",
                    "welcome": "Dobrodošli",
                    "hello": "Bok",
                    "person_count": "Broj osoba",
                    "address_uppercase": "ADRESA",
                    "from": "Od",
                    "time_of_reservation": "Vrijeme rezervacije",
                    "reservation_uppercase": "REZERVACIJA",
                    "name_label": "Ime:",
                    "surname_label": "Prezime:",
                    "phone_number_label": "Broj mobitela:",
                    "choose_menu": "Odaberite menu",
                    "no_available_menus": "Nema dostupnih menija",
                    "show_menus": "Prikaži menije",
                    "price": "Cijena",
                    "name_surname_label": "Ime i prezime:",
                    "on_date_label": "Na datum:",
                    "comment_label": "Komentar:",
                    "send_request": "Pošalji zahtjev",
                    "frontpage": "Naslovnica",
                    "login": "Prijava",
                    "register": "Registracija",
                    "register_success_text": "Zahtjev za rezervacijom je uspješno poslan. Po obradi zahtjeva SMS-om ili E-mailom ćete primiti poveznicu za potvrdu rezervacije.",
                    "register_error_text": "Greška kod slanja zahtjeva za rezervaciju.",
                    "language": "Jezik",
                    "error_name_is_obligatory": "Polje Ime je obavezno!",
                    "error_surname_is_obligatory": "Polje Prezime je obavezno!",
                    "error_email_is_obligatory": "Polje Email je obavezno!",
                    "error_phone_is_obligatory": "Polje Broj telefona je obavezno!",
                    "tickets_available": "Ulaznica dostupno",
                    "number_of_tickets": "Broj ulaznica",
                    "confirm_reservation": "Potvrdi rezervaciju",
                    "cancel_ticket": "Poništi ulaznicu",
                    "venue_name": "Naziv lokala",
                    "request_reservation_for": "Zatraži rezervaciju za:",
                    "search_by_name_and_or_address": "Pretraga po nazivu ili adresi",
                    "reservation_confirmed": "Rezervacija potvrđena, sačuvajte poveznicu i pokažite QR kod na ulazu!",
                    "frontpage_text_1": "Sonex je vaša idealna aplikacija za jednostavno upravljanje rezervacijama i organizacijom događaja. Nudimo vam tehničko rješenje za upravljanje rezervacijama, stolovima i događajima. Klikom na karticu lokala, možete vidjeti detalje, galeriju slika, i poslati zahtjev za rezervaciju ili pogledati kalendar nadolazećih događaja.",
                    "name_implied_label": "Naziv:",
                    "about_label": "O nama:",
                    "address_label": "Adresa:",
                    "contact": "Kontakt",
                    "events": "Događaji:",
                    "overview_disclaimer": "Potvrdu o rezervaciji dobiti će te na email ili sms-om nakon što Vaš zahtjev bude obrađen.",
                    "broj_ulaznica": "Broj ulaznica",
                    "card_payments_provided_by": "Kartično plaćanje omogućio",
                    "akontacija": "Akontacija",
                    "naknade": "naknade za provođenje transakcije",
                    "user_confirmation": "Pročitao/la sam i slažem se s uvjetima korištenja i odredbama web-stranice. *",
                    "za_platiti": "Za platiti",
                    "payment_card_text": "Sačuvajte ovu poveznicu(Link). Nakon plaćanja ova će kartica na sebi sadržavati QR kod koji po potrebi možete predočiti na ulazu kao potvrdu.",
                    "full_price": "Puna cijena",
                    "acontation": "Akontacija",
                    "total": "Ukupno",
                    "pay": "Plati",
                    "minimum": "Min. ",
                    "vrijeme_od": "Vrijeme od",
                    "vrijeme_do": "Vrijeme do",
                    "error_date_to_is_obligatory": "Vrijeme od je obavezno",
                    "error_date_from_is_obligatory": "Vrijeme od je obavezno",
                    "error_date_to_less_than_date_from": "Vrijeme do mora  bit poslije vremena od",
                    "timespan_text": "Odaberite vremenski raspon za koji želite napraviti rezervaciju",
                    "u_trajanju_od": "U trajanju od",
                    "extra_options": "Dodatne opcije",
                    "time_span": "Vremensko razdoblje",
                    "extra_text": "",
                    "your_digital_partner_for": "VAŠ DIGITALNI PARTNER ZA",
                    "managing_reservations_for_hospitality": "Upravljanje rezervacijama u ugostiteljskim objektima",
                    "optimize_your_bizz": "Optimizirajte svoje poslovanje i povećajte zadovoljstvo gostiju.",
                    "search_caps": "TRAŽI",
                    "fallow_all_your_reservations": "Pratite sve rezervacije na jednom mjestu. Naša aplikacija omogućuje vam da vidite dostupnost stolova, soba ili drugih kapaciteta u stvarnom vremenu, upravljate promjenama i automatski obavještavate goste o njihovim rezervacijama.",
                    "effectively_manage_reservations": "Efikasno Upravljanje Rezervacijama",
                    "personalization_of_reservation_request": "Personalizacija zahtjeva za rezervacijom",
                    "do_you_have_a_special_service_which_you_offer_to_your_guests": "Imate posebne usluge koje pružate gostima? Naša aplikacija omogućuje vam personalizaciju zahtijeva za rezervacijom kako biste mogli ponuditi vrhunsku uslugu.",
                    "automatize_reminders_and_notifications": "Automatizirani Podsjetnici i Obavijesti",
                    "forget_about_handwritten_notifications": "Zaboravite na ručne podsjetnike. Naša aplikacija automatski šalje podsjetnike i obavijesti gostima o njihovim rezervacijama putem e-pošte ili SMS-a, čime smanjujete broj propuštenih rezervacija i povećavate popunjenost kapaciteta.",
                    "profil": "Profil",
                    "edit_photo": "Uredi fotografiju",
                    "create_baseness_account": "Kreiraj poslovni račun",
                    "save_photo": "Spremi fotografiju",
                    "select_photo": "Odaberi sliku",
                    "user_details": "Korisnički detalji",
                    "name_of": "Naziv",
                    "status_label": "Status:",
                    "manager": "Manager",
                    "reception": "Recepcija",
                    "tickets": "Ulaznice",
                    "select_date_label": "Odaberi datum:",
                    "no_data_to_sohw": "Nema podtaka za prikaz",
                    "refresh_list": "Osviježi Popis",
                    "overview_business_data": "Pregled poslovnih podataka",
                    "public_page_overview": "Pregled Javne stranice",
                    "edit_public_page": "Uredi javnu stranicu",
                    "business_pages": "Poslovne stranice",
                    "log_off": "Odjava",
                    "my_profile": "Moj profil",
                    "company_name_label": "Naziv poduzeća:",
                    "company_type_label": "Tip poduzeća:",
                    "profession_label": "Djelatnost:",
                    "num_of_employees_label": "Broj zaposlenih:",
                    "year_of_founding_label": "Godina osnutka:",
                    "about_us_label": "O nama:",
                    "tax_code_label": "OIB:",
                    "email_label": "Email:",
                    "accept": "Prihvati",
                    "company_data": "Informacije o poduzeću",
                    "llc": "D.o.o.",
                    "corporation": "Korporacija",
                    "partnership": "Partnerstvo",
                    "private_person": "Privatna osoba /Obrt",
                    "hospitality": "Ugostiteljstvo",
                    "services": "Usluge",
                    "other": "Ostalo",
                    "select_year": "Odaberi godinu",
                    "search_user_by_id_label": "Pretraživanje korisnika po ID:",
                    "user_id_label": "ID korisnika:",
                    "search": "Pretraži",
                    "user_not_found": "Korisnik nije pronađen",
                    "id": "ID",
                    "name": "Ime",
                    "surname": "Prezime",
                    "add_user": "Dodaj korisnika",
                    "user_successfully_added": "Korisnik uspješno dodan",
                    "user_allready_exist": "Korisnik već postoji",
                    "bussiness_profile_label": "Poslovni profil:",
                    "services_label": "Paketi usluga:",
                    "service_name_label": "Naziv paketa usluga:",
                    "description_label": "Opis:",
                    "create_process_and_manage_reservations": "Kreiranje, obrada i upravljanje rezervacijama",
                    "active": "Active",
                    "activate": "Aktiviraj",
                    "reception_and_client_processing": "Prihvat i obarada klijenata na recepciji",
                    "check_tickets": "Pregled i poništavanje ulaznica",
                    "create_bussiness_account": "Kreiraj račun poslovnog subjekta",
                    "users_and_permissions_label": "Korisnici i dozvole:",
                    "name_and_surname": "Ime i prezime",
                    "delete": "Obriši",
                    "save_changes": "Spremi izmjene",
                    "public_page_edit": "Uređivanje javne stranice",
                    "publicly_available": "Javno dostupno",
                    "select_icon": "Odaberite ikonu.",
                    "select_icon_and_give_them_description_label": "Odaberite ikone i dodajte im opis:",
                    "reservations": "Rezervacije",
                    "reservations_overview": "Pregled rezervacija",
                    "menu_overview": "Pregled menija",
                    "menus": "Meniji",
                    "reservation_caps": "REZERVACIJA",
                    "reservation_details": "Detalji rezervacije:",
                    "number_of_people_label": "Broj ljudi:",
                    "menu_label": "Menu:",
                    "time_label": "Vrijeme:",
                    "internal_comment_label": "Interni komemntar:",
                    "address_caps_label": "ADRESA:",
                    "menu_details": "Detalji menija",
                    "without_menu": "Bez menija",
                    "confirm_arrival_label": "Potvrdi Dolazak:",
                    "num_of_people_label": "Br. osoba:",
                    "total_number_of_arrivals_label": "Br. pristiglih osoba (ukupno):",
                    "confirm_arrival": "Potvrdi Dolazak",
                    "reservation_overview_label": "Pregled rezervacija:",
                    "basic_table_layout": "Osnovni raspored stolova",
                    "tables_layout_label": "Raspored stolova:",
                    "search_label": "Pretraga:",
                    "number_of_people": "Broj ljudi",
                    "confirm": "Potvrdi",
                    "confirmed": "Potvrđeno",
                    "table": "Stol",
                    "in_time": "U terminu",
                    "edit": "Uredi",
                    "confirmed_by_guest": "Potvrdio gost",
                    "arrived": "Došao",
                    "name_surname_gsm": "Ime, prezime, GSM",
                    "available_menus_overview": "Pregled dostupnih menija",
                    "checked_tickets": "Poništene ulaznice",
                    "business": "Poslovno",
                    "place_table_for_reservations": "Dodijeli stol za rezervaciju",
                    "confirm_reservations": "Potvrdi rezervacije",
                    "send_reservation_request": "Pošalji zahtjev",
                    "events_overview": "Pregled događaja",
                    "create_menu": "Kreiraj menu",
                    "settings": "Postavke",
                    "reservations_settings": "Postavke rezervacija",
                    "public_card_settings": "Postavke Javnih kartica",
                    "events_": "Događaji",
                    "place_table_for_reservations_label": "Dodijeli stol za rezervaciju:",
                    "place_table_for_reservations_description_text": "Odaberite datum, rezervaciju, te joj za taj datum dodijelite stol. Potvrda rezervacije sprema dodijeljeni  stol za odabranu rezervaciju.",
                    "selected_reservation_and_table": "Odabrana rezervacija i stol",
                    "asign_table": "Dodijeli stol",
                    "create_new_reservation": "Kreiraj novu rezervaciju",
                    "sittings_placement_on": "Raspored sjedenja na dan",
                    "save_table_layout": "Spremi raspored stolova",
                    "select": "Odaberi",
                    "item_must_be_selected": "Stavka mora biti odbrana!",
                    "selected_menu_label": "Odabrani menu:",
                    "available_menus": "Pregled dostupnih menija",
                    "guest_comment_label": "Komentar korisnika:",
                    "table_and_reservation_must_be_selected": "Nije odabran stol ili rezervacija!",
                    "for_confirmation_of_reservation_select_reservation_and_assign_table": "Za potvrdu rezervacije odaberite rezervaciju i stol koji joj želite dodijeliti",
                    "selected_reservation_allready_have_assign_table_on_date_label": "Za odabranu rezervaciju već postoji dodijeljeni stol na datum:",
                    "confirmation_will_replace_currently_assign_table_with_selected_table": "Potvrdom rezervacije odabrani stol će zamijeniti trenutno dodijeljeni stol.",
                    "confirm_reservation_on_label": "Potvrdi rezervaciju za dan:",
                    "confirmation_will_assign_a_table_for_selected_reservation": "Potvrdom rezervacije, odabranoj rezervaciji će se dodijeliti stol.",
                    "send_a_request_for_reservation_to_a_guest_sms": "Pošalji gostu zahtjev za potvrdom rezervacije (SMS)",
                    "intern_comment_label": "Interni komentar:",
                    "back_to_reservation_creation": "Natrag na kreiranje rezervacije",
                    "create_reservation_for_a_table_label": "Kreiraj rezervaciju za stol:",
                    "selected_contact_label": "Odabrani Kontakt:",
                    "nothing_selected": "Ništa odabarano",
                    "send_request_for_confirmation_of_reservation_sms": "Pošalji klijentu zahtjev za potvrdom rezervacije (SMS)",
                    "create_reservation": "Kreiraj rezervaciju",
                    "error_while_creating_reservation": "Greška kod kreiranja rezervacije",
                    "edit_reservation_label": "Uredi rezervaciju:",
                    "date_label": "Datum:",
                    "time_from_label": "Vrijeme od:",
                    "time_to_label": "Vrijeme do:",
                    "reservation_confirm_": "Rezervacija potvrđena",
                    "reservation_executed": "Rezervacija izvršena",
                    "reservation_canceled": "Rezervacija poništena",
                    "table_label:": "Stol: ",
                    "sitting_places_": "sjedećih mjesta:",
                    "currently_assign_table": "Trenutno dodijeljeni  stol",
                    "duplicate": "Dupliciraj",
                    "to_duplicate_reservation_please_select_a_table": "Odaberi stol za duplikaciju rezervacije",
                    "press_link_and_confirm_your_arrival_for_reservation": "Pritiskom na poveznicu potvrdite svoj dolazak za rezervaciju",
                    "contact_list": "Kontakt lista",
                    "search_by_name_or_surname": "Pretraži po imenu ili prezimenu",
                    "no_available_contacts": "Nema dostupnih kontakata",
                    "telephone_label": "Telefon:",
                    "surrname_error": "Prezime je obavezno.",
                    "phone_number_error": "Broj telefona je obavezan.",
                    "time_from_error": "Vrijeme od je obavezno.",
                    "time_to_error": "Vrijeme do je obavezno.",
                    "filter_by_number_of_people_from_request": "Filtriraj po broju ljudi iz zahtjeva",
                    "list_of_tables": "Popis Stolova",
                    "name_of_table": "Naziv",

                    "name_surname": "Ime i prezime",
                    "details": "Detalji",
                    "select_table": "Odaberi stol",
                    "show_for_all_dates": "Prikaži za sve datume",
                    "show_canceled_requests": "Prikaži poništene zahtjeve",
                    "reservation_requests": "Zahtjevi za rezervacijama",
                    "confirm_reservations_on_a_day": "Potvrdi rezervacije za dan:",
                    "link_copied": "Poveznica kopirana!",
                    "table_label": "Stol:",
                    "sittings_label": "Sjedišta:",
                    "reservation_details_label": "Detalji rezervacije:",
                    "on_time": "Termin:",
                    "send_request_for_confirmation_of_reservation_on_label": "Pošalji zahtjev za potvrdu rezervacije na datum:",
                    "add_link": "Dodaj poveznicu",
                    "copy_link": "Kopiraj poveznicu",
                    "send_message": "Pošalji poruku",
                    "edit_reservation": "Uredi rezervaciju",
                    "confirm_reservation_description_text": "Potvrdite rezervaciju i/ili pošaljite kontaktu zahtjev za potvrdom rezervacije. Zahtjev za potvrdom se automatski šalje 1 dan prije zakazanog termina za potvrđene rezervacije. Po potrebi zahtjev za potvrdom rezervacije možete poslati u bilo kojem trenutku.",
                    "reservations_label": "Rezervacije:",
                    "confirmed_label": "Potvrđeno:",
                    "unconfirmed_label": "Nepotvrđeno:",
                    "unconfirmed_guest_label": "Gost nije potvrdio:",
                    "request_guest_confirmation": "Zatraži potvrdu gosta",
                    "send_reservation_request_label": "Pošalji zahtjev za rezervaciju:",
                    "send_reservation_request_description": "Pošaljite zahtjev za rezervaciju kontaktu, odaberite datum, odaberite kontakt ili dodajte novi kontakt.",
                    "received_requests": "Pristigli zahtjevi",
                    "email": "Email",
                    "prefix": "Prefix",
                    "number": "Broj",
                    "date": "Datum",
                    "reservation_request_on_label": "Zahtjev za rezervaciju na datum:",
                    "save_contact": "Spremi kontakt",
                    "send_message_to_selected": "Pošalji poruku označenima",
                    "error_time_from_is_mandatory": "Vrijeme od je obavezno!",
                    "error_time_to_is_mandatory": "Vrijeme do je obavezno!",
                    "list_of_messages": "Popis poruka",
                    "message": "Poruka",
                    "edit_event": "Uredi događaj",
                    "create_event": "Kreiraj događaj",
                    "error_name_is_mandatory": "Naziv je obavezno polje!",
                    "error_description_is_mandatory": "Opis je obavezno polje!",
                    "error_date_is_mandatory": "Datum je obavezno polje!",
                    "error_image_is_mandatory": "Slika je obavezna!",
                    "event_named_label": "Događaj pod nazivom:",
                    "_is_created_successful_label": "je uspješno kreiran",
                    "all_necessary_fields_are_not_filled": "Nisu popunjena sva potrebna polja za kreaciju eventa!",
                    "created_events_overview": "Pregled kreiranih događaja",
                    "create_event_uppercase": "KREIRAJ DOGAĐAJ",
                    "edit_uppercase": "UREDI",
                    "delete_uppercase": "OBRIŠI",
                    "event_name_label": "Naziv Događaja:",
                    "event_description_label": "Opis Događaja:",
                    "save": "Spremi",
                    "edit_contact": "Uredi kontakt",
                    "menu_name_label": "Naziv menija:",
                    "tittle_label": "Naslov:",
                    "subtitle_label": "Podnaslov:",
                    "subtitle2_label": "Podnaslov 2:",
                    "menu_item_label": "Stavka menija:",
                    "add_item": "Dodaj stavku",
                    "remove_item": "Ukloni stavku",
                    "price_label": "Cijena:",
                    "date_from_label": "Od datuma:",
                    "save_menu": "Spremi menu",
                    "error_sitting_places": "Broj sjedećih mjesta je obavezan.",
                    "default_room": "Glavna prostorija",
                    "unplaced": "Neraspoređen",
                    "edit_table_label": "Uredi stol:",
                    "table_name": "Naziv stola:",
                    "number_of_sitting_places": "Broj sjedećih mjesta:",
                    "room_label": "Prostor:",
                    "select_table_shape_label": "Odabir oblika stola:",
                    "workdays_and_hours": "Radni dani i sati",
                    "error_flor_plan_photo": "Odabir slike prostora je obavezan.",
                    "edit_daily_setup": "Uređivanje svakodnevnih postavki",
                    "edit_daily_setup_description": "Odaberite radne dane i radno vrijeme te kreirajte listu stolova. Ove postavke se koriste za uobičajene radne dane, odnosno za dane za koje ne postoji događanje. Lista kreiranih stolova ima grafičku reprezentaciju čija se pozicija može prilagoditi tako što ih se povuče na željenu lokaciju.",
                    "monday": "Pon",
                    "tuesday": "Uto",
                    "wednesday": "Sri",
                    "thursday": "Čet",
                    "friday": "Pet",
                    "saturday": "Sub",
                    "sunday": "Ned",
                    "remove_last_added_table": "Ukloni zadnji stol",
                    "room": "Prostorija",
                    "layout": "Raspored",
                    "create_new_table_layout": "Kreiraj novi raspored stolova",
                    "select_room_plan": "Odaberi plan prostora (tlocrt)",
                    "add_room": "Dodaj prostoriju",
                    "create_new_table": "Kreiraj novi stol",
                    "edit_table": "Uredi stol",
                    "delete_room": "Obriši prostoriju",
                    "room_name_label": "Naziv prostorije:",
                    "error_number_of_people": "Broj ljudi je obavezan.",
                    "floor_plan": "Plan prostora (tlocrt)",
                    "change_floor_plan": "Promijeni plan prostora (tlocrt):",
                    "create_new_table_layout_label": "Kreiraj novi raspored stolova:",
                    "table_layout_name_label": "Naziv rasporeda stolova",
                    "edit_menu": "Uredi menu",
                    "edit_menu_short_descripton": "Napravite željene  izmjene i spremite ih",
                    "menu_with_name_label": "Menu pod nazivom:",
                    "_is_not_created_label": "NIJE kreiran!",
                    "create_new_menu": "Kreiraj novi menu",
                    "create_new_menu_description": "Kreirajte novu menu karticu, dodijelit joj naziv podnaziv opise dodajte stavke menija i spremite ih. Po spremanju pregledat ih možete na pregledu menija a korisnicima su vidljive kao opcije kod rezervacije",
                    "sending_card_info_text": "Pregled kartice koja se šalje korisnicima iz opcije Pošalji zahtjev (Ogledni primjer, spremite izmjene prije pregleda kako bi kartica ispravno radila)",
                    "defined_time_duration_hours_minutes": "Definirano vremensko Trajanje (Sati : Minuta):",
                    "in_span_from_label": "Termin u rasponu od:",
                    "to_label": "do:",
                    "reservation_card_setup": "Postavke rezervacijskih kartica",
                    "save_uppercase": "SPREMI",
                    "reservation_card_setup_description": "Postavke koraka ponuđenih klijentima na kartici kod izrade narudžbi i postavke načina provođenja rezervacija. Kartica je grafički element koju korisnik vidi kada pritisne na poveznicu (link) koji mu bude poslan SMS-om ili E-mailom.",
                    "predefined_text": "Predefinirani tekst SMS - EMAIL",
                    "predefined_text_description": "Tekst koji inicijalno napuni polje za slanje poruke, ako korisnicima šaljete istu poruku, kako je ne bi morali svaki puta ponovno pisati, ovdje je možete definirat.",
                    "text_for_sms_and_email_label": "Tekst za SMS i Email:",
                    "time_frames_setup": "Postavke Vremenskih okvira",
                    "time_frames_setup_description": "Slobodni odabir će omogućiti klijentu da sam odabere vrijeme dolaska i trajanja rezervacije u formatu od - do. Definirano vrijeme trajanja će omogućiti korisniku da odabere početno vrijeme rezervacije, a trajanje rezervacije se postavlja u polju koje se pojavi odabirom ove opcije. Definirani vremenski raspon će ponuditi klijentu vremenske termine koji se kreiraju u pripadajućim poljima od koijih klijent može odabrati jedan.",
                    "open_selection_from_to": "Slobodni odabir (Od - Do)",
                    "defined_duration_from_in_duration": "Definirano trajanje (Od - U trajanju)",
                    "defined_time_slots": "Definirani vremenski raspon",
                    "guests_setup": "Postavke gostiju",
                    "guests_setup_description": "Postavke minimalnog broja gostiju i prikaza dostupnih menija.",
                    "show_menu_selection": "Pokaži Menu kartice",
                    "minimal_guest_count": "Minimalan broj gostiju",
                    "specific_items": "Specifične stavke",
                    "specific_items_descriptions": "Stavke koje želite ponuditi gostu a specifične su za vas",
                    "select_1_from_the_list": "Odaberi 1 sa liste",
                    "multiple_choice": "Višestruki izbor",
                    "menu_item": "Stavka menija",
                    "extra_text_to_show_on_reservation_card": "Dodatni tekst korisniku",
                    "extra_text_to_show_on_reservation_card_desc": "Tekst i/ili podaci koje želito prikazati korisniku na kartici",
                    "show_extra_text": "Prikaži dodatni tekst",
                    "text_label": "Tekst:",
                    "login_text_1": "Dobro došli u naš sustav Narudžbe & Rezervacije. U srcu naše misije leži želja da olakšamo proces narudžbi i rezervacija, pretvarajući ga u bezbrižno iskustvo. Naša platforma je dizajnirana s vama na umu - brza, pouzdana i intuitivna, osigurava da vaše narudžbe i rezervacije budu obrađene u rekordnom vremenu, bez komplikacija",
                    "login_text_2a": "Brzina i Efikasnost: U samo nekoliko klikova, vaša narudžba ili rezervacija bit će potvrđena.",
                    "login_text_2b": "Pouzdanost: Naš sustav koristi najnovije tehnologije za osiguravanje točnosti vaših narudžbi i rezervacija.",
                    "login_text_2c": "Prilagodljivost: Bez obzira jeste li pojedinac ili tvrtka, naša platforma pruža prilagođena rješenja.",
                    "login_text_2d": "Izvanredna podrška: Naš tim za korisničku podršku dostupan je 24/7.",
                    "login_text_3": "Započnite svoje bezbrižno iskustvo s Narudžbama & Rezervacijama danas i otkrijte kako možemo transformirati vaše planiranje u uživanje. Dobrodošli u budućnost planiranja!",
                    "password_label": "Lozinka:",
                    "forgotten_password_qm": "Zaboravljena lozinka?",
                    "why_sonex_qm": "Zašto odabrati Sonex?",
                    "register_user_account": "Registracija novog korisničkog računa",
                    "tables": "Stolovi:",
                    "birth_date": "Datum rođenja",

                    "acca_reg_form_title": "Prijavnica za ACCA ODCBE",
                    "select_exam_date": "Odaberite datum ispita",
                    "select_exam": "Odaberite ispit",
                    "first_name_is_required": "Ime je obavezno",
                    "last_name_is_required": "Prezime je obavezno",
                    "email_is_required": "Email je obavezan",
                    "acca_id_is_required": "ACCA ID je obavezan",
                    "birth_date_is_required": "Datum rođenja je obavezan",
                    "phone_number_is_required": "Broj telefona je obavezan",
                    "custom_phone_prefix_is_required": "Prilagođeni telefonski prefiks je obavezan",
                    "name_is_required": "Ime je obavezno",
                    "address_is_required": "Adresa je obavezna",
                    "choose_exam_date": "Odaberite datum ispita!",
                    "choose_exam": "Odaberite ispit!",
                    "student_details": "PODACI O STUDENTU",
                    "birth_date": "Datum rođenja",
                    "contact_number": "Kontakt broj",
                    "exam_information": "INFORMACIJE O ISPITU",
                    "payment_proforma": "PODACI ZA PLAĆANJE / PREDRAČUN",
                    "pro_forma_text": "Molimo unesite podatke za osobu ili tvrtku koja je odgovorna za plaćanje računa. Pažljivo pregledajte sve podatke prije slanja obrasca, jer nakon izdavanja računa nije moguće izvršiti promjene.",
                    "company_name_if_applicable": "Naziv tvrtke (ako je primjenjivo)",
                    "oib_vat_if_applicable": "OIB/PDV broj (ako je primjenjivo)",
                    "tos": "UVJETI I ODREDBE",
                    "kpmg_1": "Podnošenjem ovog obrasca pristajete na sljedeće:",
                    "exam_registration": "REGISTRACIJA ZA ISPIT",
                    "kpmg_2": "Rezervacija ispita mora se obaviti najmanje 7 dana prije odabranog datuma ispita podnošenjem ACCA ODCBE obrasca za registraciju („obrazac za registraciju”) zajedno s dokazom o uplati.",
                    "kpmg_3": "KPMG Croatia d.o.o., Ulica Ivana Lučića 2a, 10000 Zagreb („Centar”) nastojat će osigurati odabrani datum za ispit, no zadržavamo pravo na promjenu termina u slučaju prekida ili tehničkih poteškoća.",
                    "kpmg_4": "Centar djeluje po principu 'prvi došao, prvi poslužen' na svim računalno baziranim ispitima, što znači da sesija za koju kandidat želi polagati ispit može biti popunjena. U tom slučaju, registracija će biti premještena na sljedeću zakazanu sesiju.",
                    "kpmg_5": "Ispiti se održavaju četvrtkom (isključujući hrvatske državne praznike), od 10:00 do 12:00 sati.",
                    "kpmg_6": "Registracija kandidata za ispit ne može biti potvrđena dok Centar ne primi punu uplatu i u potpunosti popunjen obrazac za registraciju.",
                    "kpmg_7": "Jednom kada je rezervacija ispita potvrđena (putem emaila), kandidat gubi pravo na povrat sredstava u slučaju otkazivanja ili nepojavljivanja na zakazani ispit.",
                    "kpmg_8": "Ako kandidat želi odgoditi ispit, mora obavijestiti Centar (putem emaila) najkasnije 24 sata prije zakazanog ispita.",
                    "kpmg_9": "Centar neće biti odgovoran za prekide u opskrbi električnom energijom i/ili prirodne uzroke koji su izvan naše kontrole.",
                    "kpmg_10": "ACCA zadržava pravo otkazati CBE ispit iz bilo kojeg razloga. Sve upite treba uputiti ACCA-i u slučaju da ACCA otkaže ispit.",
                    "kpmg_11": "Ova registracija za ispit bit će prihvaćena samo ako su uplate izvršene unaprijed.",
                    "kpmg_12": "Elektronički potpisi su prihvaćeni.",
                    "exam_capital": "ISPIT",
                    "kpmg_13": "Kandidati su dužni biti na mjestu održavanja ispita petnaest minuta prije početka ispita.",
                    "kpmg_14": "Kandidati neće moći pristupiti ispitu bez pružanja službenog valjanog oblika identifikacije, kao što su osobna iskaznica, putovnica ili vozačka dozvola.",
                    "kpmg_15": "Kandidati su odgovorni za donošenje vlastitog znanstvenog kalkulatora (bez mogućnosti unosa teksta). Olovke i papir za grube radove osigurava Centar.",
                    "kpmg_16": "Kandidati su odgovorni pročitati 'Informacijski list za ODCBE studente' koji će im biti dostavljen nakon potvrde registracije za ispit.",
                    "i_have_read_and_accept_the_terms_of_service": "Pročitao/la sam i prihvaćam Uvjete pružanja usluge",
                    "privacy_notice": "OBAVIJEST O PRIVATNOSTI",
                    "kpmg_17": "U vezi s osobnim podacima kandidata dobivenim ovdje, Centar djeluje kao voditelj obrade podataka. Svrha obrade podataka je registracija kandidata za ACCA ispite i prikupljanje naknada za iste. Pravni osnovi za obradu podataka kandidata su sljedeći:",
                    "kpmg_17b": "− obrada je nužna za izvršenje ugovora u kojem je kandidat stranka ili za poduzimanje radnji na zahtjev kandidata prije sklapanja ugovora, tj. registracija kod ACCA-e kako bi kandidat mogao pristupiti ACCA ispitu (članak 6. stavak 1. točka (b) GDPR-a),",
                    "kpmg_17c": "− obrada je nužna za poštivanje zakonske obveze kojoj je voditelj obrade podložan, tj. obveza izdavanja računa kandidatu čiji je obvezni sadržaj propisan hrvatskim zakonodavstvom (članak 6. stavak 1. točka (c) GDPR-a).",
                    "kpmg_18": "Kao rezultat toga, pružanje podataka traženih u ovom obrascu je obvezno, a neuspjeh u pružanju istih može rezultirati nemogućnošću registracije kandidata za ACCA ispit.",
                    "kpmg_19": "Dobiveni podaci mogu se podijeliti s ACCA-om i hrvatskim vlastima. Više informacija o tome kako ACCA obrađuje podatke kandidata možete pronaći u ACCA-inoj obavijesti o privatnosti dostupnoj na sljedećem linku: https://www.accaglobal.com/my/en/footertoolbar/privacy/data-protection.html.",
                    "kpmg_20": "Centar će zadržati podatke onoliko dugo koliko je potrebno za ispunjenje zakonskih obveza u vezi s vođenjem računovodstvene dokumentacije, nakon čega će podaci biti izbrisani. Obrada neće uključivati automatizirano donošenje odluka.",
                    "kpmg_21": "U vezi s obradom osobnih podataka, kandidat ima sljedeća prava: pravo na pristup, ograničenje obrade, brisanje, prijenos podataka, pravo na prigovor na obradu, pravo na podnošenje pritužbe nadzornom tijelu (u Hrvatskoj je to Agencija za zaštitu osobnih podataka).",
                    "kpmg_22": "U slučaju bilo kakvih pitanja vezanih za obradu osobnih podataka ili za ostvarivanje gore navedenih prava, molimo kontaktirajte službenika za zaštitu podataka Centra na dpo@kpmg.hr.",
                    "i_have_read_and_accept_the_privacy_notice": "Pročitao/la sam i prihvaćam Obavijest o privatnosti",
                    "candidate_declaration": "IZJAVA KANDIDATA",
                    "kpmg_23": "Podnošenjem ovog obrasca potvrđujem da sam pročitao/la, razumijem i slažem se s uvjetima i odredbama navedenim u smjernicama priloženim uz ovaj obrazac za registraciju.",
                    "kpmg_24": "Poštivat ću opća pravila ACCA-e za CBE navedena u informativnom listu koji će mi biti dostavljen nakon potvrde registracije.",
                    "kpmg_25": "Ja, dolje potpisani/a, ovime potvrđujem da su informacije navedene u ovom obrascu točne i ažurirane te da sam pročitao/la i razumijem uvjete i odredbe navedene u ovom obrascu.",
                    "i_have_read_and_accept_the_candidate_declaration": "Pročitao/la sam i prihvaćam Izjavu kandidata",
                    "kpmg_register": "Prijavi se",
                    "educations": "Edukacije",
                    "educations_desc": "Slanje i prijem zahtjeva za edukacijom",
                    "custom": "Individualizirano",
                    "custom_desc": "Individualizirane nadogradnje na zahtjev korisnika",
                    "kpmg_tests": "KPMG Testovi",
                    "time_frame": "Vremenski raspon",
                    "phone_num_label": "Broj telefona",
                    "unconfirmed_guest": "Klijent nije potvrdio",
                    "employees": "Djelatnici",
                    "employees_and_members": "Djelatnici i Članovi",
                    "schedule": "Raspored",
                    "employee_list": "Popis zaposlenika",
                    "members": "Članovi",
                    "products_and_services": "Proizvodi i usluge",

                }
            }

            /* Add more languages here
               
                import { useTranslation } from 'react-i18next';
                const { t } = useTranslation();
            
                {t('refresh_list')}    
            
            
            */
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // React already escapes by default
        }
    });

export default i18n;
