import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const reservationListingDelete = createAsyncThunk(
    'reservations/listing/delete/',
    async (payload, thunkAPI) => {
        const { id, company_id } = payload;

        try {
            const res = await fetch(`/api/reservations/listing/delete/${id}/${company_id}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 204) {
                console.log("reservations/listing/delete successfully");
                return null;

            } else {

                const data = await res.json();
                console.log("reservations/listing/delete Unexpected response", data);
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("reservations/listing/delete Error -----------------------", err);
            return thunkAPI.rejectWithValue(err.response ? err.response.data : { error: err.message });
        }
    }
);
