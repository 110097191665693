import { createAsyncThunk } from '@reduxjs/toolkit';

export const contactViewAll = createAsyncThunk(
    'users/company/contacts/all',
    async ({ company_id }, thunkAPI,) => {
        const body = JSON.stringify({ company_id });

        try {
            const res = await fetch('/api/users/company/contacts/all', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("contacts/all != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("contacts/all Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);