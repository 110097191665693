import React from 'react';

const SVGDisplaySmall = ({ selections }) => {
    const selectedItems = selections.filter(item => item.isSelected);

    return (
        <div style={{backgroundColor: 'white', borderRadius: '10px'}}>
            {selectedItems.length === 0 ? (
                <p style={{ color: 'white' }}>Odaberite ikonu.</p>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', flexWrap: 'wrap' }}>
                    {selectedItems.map((svg, index) => (
                        <div key={index} style={{ margin: '0px', padding: '0px', backgroundColor: '#fff',  borderRadius: '8px', textAlign: 'center' }}>
                            <img src={svg.src} alt={svg.name} style={{ width: '50px', height: '50px', marginBottom: '2px', filter: `hue-rotate(${svg.color})` }} />
                            <p>{svg.description}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SVGDisplaySmall;
