import { createAsyncThunk } from '@reduxjs/toolkit';
import { kpmgTestVievAll } from './KpmgTestVievAll';

export const kpmgTestCreate = createAsyncThunk(
    'kpmg/test/create',
    async (payload, thunkAPI,) => {

        const body = JSON.stringify(payload);
        const { company } = payload;
        const company_id = company


        try {
            const res = await fetch('/api/kpmg/test/create', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {

                const { dispatch } = thunkAPI;
                const payload = {
                    company_id: company_id,
                };

                dispatch(kpmgTestVievAll(payload));
                return data;

            } else {
                console.log("kpmgTestCreate != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("kpmgTestCreate Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);