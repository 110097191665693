import React, { useEffect, useRef } from 'react';
import { XSvg } from 'containers/Icons';
import { useTranslation } from 'react-i18next';


const ModalWithChildren = ({ isOpen, onClose, onDelete, onSave, children, renderSave=true, renderDelete=true, style=null}) => {
    const modalRef = useRef(null);

    const { t } = useTranslation();
    useEffect(() => {
        const body = document.querySelector('body');
        if (isOpen) {
            body.style.overflow = 'hidden';
            modalRef.current?.scrollTo(0, 0); // Reset scroll to top when modal opens
        } else {
            body.style.overflow = 'auto';
        }
    }, [isOpen]);

    const handleBackdropClick = () => {
        onClose(); // This might need a slight delay if you want to play an exit animation
    };

    // Modal and backdrop class control
    const backdropClass = isOpen ? "modal-backdrop show" : "modal-backdrop";
    const modalClass = isOpen ? "modal-content show" : "modal-content";

    return (
        <div className={backdropClass} onClick={handleBackdropClick}>
            <div className={modalClass} onClick={e => e.stopPropagation()} ref={modalRef} style={style}>
            
            <button className="delete-button"  onClick={onClose} style={{ maxWidth: '40px', height: '40px', paddingTop: '6px', marginBottom: '10px', position: 'absolute', top: '10px', right: '10px' }}>
                <label style={{ width: "35px", height: "35px", marginLeft: "-18px" }}><XSvg /></label>
            </button>
            
                {children}
                

                {renderSave || renderDelete ? (<>
                    <span style={{ display: 'block', height: '10px'}}></span>
                    <div>
                    {renderSave && (
                        <button className="edit-button" onClick={onSave} style={{ marginLeft: '0px', paddingTop: '10px', marginRight: '20px' }}>{t('save')}</button>
                    )}

                    {renderDelete && (
                        <button className="delete-button" onClick={onDelete} style={{ marginLeft: '0px', paddingTop: '10px', marginRight: '20px' }}>{t('delete')}</button>
                    )}
                    
                </div>
                </>) : (<></>)}
            </div>
        </div>
    );
};

export default ModalWithChildren;
