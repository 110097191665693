import Layout from "components/Layout";
import React from 'react';
import '../App.css';

import logo from "../2.png";
import { useTranslation } from 'react-i18next';

import corvus from "components/logotypes/3_Logo_CorvusPay/corvus-logo-1x.png"
import master from 'components/logotypes/1_Logo_prihvat/Mastercard/ma_symbol_opt_45_2x.png';
import maestro from 'components/logotypes/1_Logo_prihvat/Maestro/ms_vrt_opt_pos_45_2x.png';
import visa from 'components/logotypes/1_Logo_prihvat/Visa/visa_90_30.png';
import mc_idC from 'components/logotypes/2_Logo_sigurnost/Mastercard Identity Check/Horizontalni/mc_idcheck_hrz_pos_97px.png';
import visa_secure from 'components/logotypes/2_Logo_sigurnost/Visa Secure Logo/VisaSecureLogo-x075.png'





const Safety = () => {
    const { t } = useTranslation();



    return (
        <Layout title="Sonex | Safety" content="Home page">
            <div className="container" style={{ paddingTop: '50px' }}>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img src={logo}
                                        style={{
                                            maxWidth: "220px",
                                            height: "auto",
                                            borderRadius: '10px',
                                        }}
                                        alt="Logo"
                                    />
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <p>
                                        <ul>
                                            <li>{t('frontpage_text_1')}</li>

                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <form className="form-control" style={{ backgroundColor: "lightgray" }}>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <div style={{ textAlign: 'left', marginLeft: "20px" }}>
                                        <a href="https://www.corvuspay.com" target="_blank" rel="noopener noreferrer" style={{ width: "251px", height: "25px" }}>
                                            <img
                                                src={corvus}
                                                alt="CorvusPay logo"
                                                style={{
                                                    width: "251px",
                                                    height: "52px",
                                                }}
                                            />
                                        </a>

                                        <div className='row' style={{ marginTop: "50px", marginBottom: "50px" }}>
                                            <a href="https://www.mastercard.com" target="_blank" rel="noopener noreferrer" style={{ width: "90px", height: "64px" }}>
                                                <img
                                                    src={master}
                                                    alt="Mastercard"
                                                    style={{
                                                        width: "90px",
                                                        height: "64px",
                                                        backgroundColor: "white",
                                                        borderRadius: "10px"
                                                    }}
                                                />
                                            </a>
                                            <a href="https://www.mastercard.hr/hr-hr/consumers/find-cardproducts/debit-cards/maestro-debit.html" target="_blank" rel="noopener noreferrer" style={{ width: "90px", height: "64px", marginLeft: "10px" }}>
                                                <img
                                                    src={maestro}
                                                    alt="Maestro"
                                                    style={{
                                                        width: "90px",
                                                        height: "64px",
                                                        backgroundColor: "white",
                                                        borderRadius: "10px"
                                                    }}
                                                />
                                            </a>
                                            <a href="https://www.visa.com.hr" target="_blank" rel="noopener noreferrer" style={{ display: "flex", width: "90px", height: "64px", marginLeft: "20px", justifyContent: "center", alignItems: "center", backgroundColor: "white", borderRadius: "10px", overflow: "hidden" }}>
                                                <img
                                                    src={visa}
                                                    alt="Visa"
                                                    style={{
                                                        width: "90px",  // Maintain the aspect ratio of the image
                                                        height: "auto",
                                                        backgroundColor: "white",
                                                        borderRadius: "10px",
                                                        display: "flex",  // To use margin auto for centering
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <div className='row'>
                                            <a href="https://www.mastercard.hr/hr-hr/issuers/identitycheck.html" target="_blank" rel="noopener noreferrer" style={{ display: "flex", width: "90px", height: "64px", marginLeft: "20px", justifyContent: "center", alignItems: "center", backgroundColor: "white", borderRadius: "10px", overflow: "hidden" }}>
                                                <img
                                                    src={mc_idC}
                                                    alt="Mastercard"
                                                    style={{
                                                        width: "90px",  // Maintain the aspect ratio of the image
                                                        height: "auto",
                                                        backgroundColor: "white",
                                                        borderRadius: "10px",
                                                        display: "flex",  // To use margin auto for centering
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                />
                                            </a>

                                            <a href="https://www.visa.com.hr/placajte-visakarticom/featured-technologies/verified-by-visa.html" target="_blank" rel="noopener noreferrer" style={{ display: "flex", width: "75px", height: "75px", marginLeft: "20px", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
                                                <img
                                                    src={visa_secure}
                                                    alt="Visa Secure"
                                                    style={{
                                                        width: "75px",  // Maintain the aspect ratio of the image
                                                        height: "75",
                                                        display: "flex",  // To use margin auto for centering
                                                        alignItems: "center",
                                                        justifyContent: "center"
                                                    }}
                                                />
                                            </a>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-6 col-sm-12" style={{ marginTop: "20px" }}>
                                    <h5>Izjava o sigurnosti online plaćanja</h5>
                                    <p style={{ marginTop: "20px" }}>
                                        Pri plaćanju na našoj web trgovini koristite CorvusPay – napredni sustav za siguran prihvat platnih kartica
                                        putem interneta.
                                        CorvusPay sustav osigurava potpunu tajnost Vaših kartičnih i osobnih podataka već od trenutka kada ih
                                        upišete u CorvusPay platni formular. Platni podaci prosljeđuju se šifrirano od Vašeg web preglednika do banke
                                        koja je izdala Vašu karticu. Naša trgovina nikada ne dolazi u kontakt s cjelovitim podacima o Vašoj platnoj
                                        kartici. Također, podaci su nedostupni čak i djelatnicima CorvusPay sustava. Izolirana jezgra samostalno
                                        prenosi i upravlja osjetljivim podacima, čuvajući ih pri tom potpuno sigurnima.</p><p>
                                        Formular za upis platnih podataka osiguran je SSL transportnom šifrom najveće pouzdanosti. Svi skladišteni
                                        podaci dodatno su zaštićeni šifriranjem, korištenjem kriptografskog uređaja certificiranog prema FIPS
                                        140-2 Level 3 standardu. CorvusPay zadovoljava sve zahtjeve vezane uz sigurnost on-line plaćanja propisane
                                        od strane vodećih kartičnih brandova, odnosno posluje sukladno normi – PCI DSS Level 1 – najviši sigurnosni
                                        standard industrije platnih kartica. Pri plaćanju karticama uvrštenim u 3-D Secure program Vaša banka uz
                                        valjanost same kartice dodatno potvrđuje i Vaš identitet pomoću tokena ili lozinke.</p><p>
                                        Corvus Pay sve prikupljene informacije smatra tajnom i tretira ih u skladu s tim. Informacije se koriste
                                        isključivo u svrhe za koje su namijenjene. Vaši osjetljivi podaci u potpunosti su sigurni, a njihova privatnost
                                        zajamčena je najmodernijim zaštitnim mehanizmima. Prikupljaju se samo podaci nužni za obavljanje posla
                                        sukladno propisanim zahtjevnim procedurama za on-line plaćanje.</p><p>
                                        Sigurnosne kontrole i operativne procedure primijenjene na našu infrastrukturu osiguravaju trenutnu
                                        pouzdanost CorvusPay sustava. Uz to održavanjem stroge kontrole pristupa, redovitim praćenjem sigurnosti
                                        i dubinskim provjerama za sprječavanje ranjivosti mreže te planskim provođenjem odredbi o informacijskoj
                                        sigurnosti trajno održavaju i unaprjeđuju stupanj sigurnosti sustava zaštitom Vaših kartičnih podataka.
                                    </p><p>
                                        Hvala što koristite CorvusPay!
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div >
        </Layout >
    );
};

export default Safety;
