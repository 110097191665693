import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';
import { floorplanView } from 'features/floorplanSys/FloorplanView';

export const tableViewDaily = createAsyncThunk(
    'company/tables/show/daily/',
    async ({ company_id }, thunkAPI,) => {
        //console.log("company_id:" + company_id);

        try {
            const res = await fetch(`/api/company/tables/show/daily/${company_id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());

            }

            if (res.status === 200) {
                const { dispatch } = thunkAPI;
                const payload = {
                    company_id: company_id,
                };
                dispatch(floorplanView(payload));


                return data;

            } else {
                console.log("MessagesViewAll != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("MessagesViewAll Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);