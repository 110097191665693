import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const reservationPublicVerify = createAsyncThunk(
    'reservations/public/confirm',
    async (payload, thunkAPI,) => {

        const { id, } = payload;
        console.log("id xxxxx=====>" + JSON.stringify(id));
        try {
            const res = await fetch(`/api/reservations/public/confirm/${id}/`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });
            const data = await res.json();


            if (res.status === 200) {
                return data;

            } else {
                console.log("companyExistenceCheck != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("companyExistenceCheck Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);