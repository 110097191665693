import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';



const ImageSelector = ({ setFileForUpload, selectedImage }) => {

    const [eventImage, setEventImage] = useState(null);
    const { t } = useTranslation();
    
    const generateRandomString = (length = 6) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      }; 

    const handleImageChange = (e) => {
        const originalFile = e.target.files[0];
        if (!originalFile) return;
    
        const reader = new FileReader();
        reader.onloadend = () => {
            // Update the form data with the image preview
            setEventImage(reader.result );
            selectedImage(reader.result );
            // Generate a unique part for the file name
            const uniquePart = generateRandomString(6);
            const fileExtension = originalFile.name.split('.').pop();
            const newFileName = `newName_${uniquePart}_${Date.now()}.${fileExtension}`;
            const fileForUpload = new File([originalFile], newFileName, { type: originalFile.type });
    
            // Call the function provided via props to update the file ready for upload in the parent component
            setFileForUpload(fileForUpload);
        };
    
        reader.readAsDataURL(originalFile);
    };
    


    return (
        <div className="work-days-hours-form" style={{ fontSize: '14px' }}>
            <div className="row">

                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="input-field">
                        <input
                            id="fileInput"
                            type="file"
                            onChange={handleImageChange}
                            accept="image/*"
                            style={{ display: 'none' }}
                        />
                        <span style={{ display: 'block', height: '10px' }}></span>
                        <button className="edit-button" onClick={() => document.getElementById('fileInput').click()} type="button">{t('select_photo')}</button>
                        
                        <span style={{ display: 'block', height: '10px' }}></span>

                    </div>
                    {/*<div style={{ width: '300px', height: '200px', backgroundImage: `url(${eventImage})`, backgroundSize: 'cover', border: '1px solid black', marginBottom: '10px' }}>*/}
                    
                </div>
                {eventImage ? (<img src={eventImage} alt="Event menu Image" style={{ objectFit: 'cover'}}/>) :(<></>)}
            </div>
        </div>
    );
};

export default ImageSelector;
