import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const ReservationMenuSelector = ({selectedMenu, setSelectedMenu, showMenu, okClicked, bckButton,}) => {

  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(selectedMenu? selectedMenu.subtitle2 : `${t('choose_menu')}`);

    // Update inputValue when selectedMenu changes
    useEffect(() => {
      setInputValue(selectedMenu ? selectedMenu.subtitle2 : `${t('choose_menu')}`);
    }, [selectedMenu]);


    const handleButtonClick = () => {
    };  

    const removeLast = () =>{
      setInputValue(`${t('choose_menu')}`);
      setSelectedMenu(null);
    };


  return (
    <div>
      <div className="display">{inputValue}</div>
      <div className="number-pad" >
      <span style={{ display: 'block', width: '260px' }}></span>
          <div style={{maxWidth: "250px", marginLeft: "-85px"}}>
           

          <button
            id="show_menus"
            className="time-frame-button"
            onClick={showMenu}
          >
             {t('show_menus')}
          </button>
          
        <div style={{maxWidth: "250px", marginLeft: "35px"}}>
        <button
            id="C"
            className="number-button-cancel"
            onClick={() =>removeLast()}
          >
            C
        </button>   


        <button
            id="bckButton"
            className="number-button"
            onClick={bckButton}
          >
            {"<"}
        </button>

        <button
            id="OK"
            className="number-button"
            style={{paddingLeft: "15px"}}
            onClick={okClicked}
          >
            OK
        </button>

        </div>
        </div>

        
      </div>
    </div>
  );
};

export default ReservationMenuSelector;
