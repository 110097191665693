import { createAsyncThunk } from '@reduxjs/toolkit';

export const passwordRequestReset = createAsyncThunk(
    'users/password-reset/',
    async (payload, thunkAPI,) => {
        const body = JSON.stringify(payload);

        console.log("payload: " + JSON.stringify(payload));
        console.log("body: " + JSON.stringify(body));

        try {
            const res = await fetch(`/api/users/password-reset/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                return data;

            } else {
                console.log("passwordRequestReset != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("passwordRequestReset Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);