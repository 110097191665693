import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

const ReservationExtraText = ({text, okClicked, bckButton}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [innerText, setInnerText] = useState("");

    useEffect(() => {
        if (!text) return;

        setInnerText(text);
        
    }, [dispatch, text]);

    return (<>
      <div className="display"></div>
        <div className="number-pad" style={{marginLeft: "130px"}}> 
            <span style={{ display: 'block', width: '260px' }}></span>
            <div style={{maxWidth: "250px", marginLeft: "-175px"}}>
                <div>
                    <p style={{marginLeft: "35px", minHeight:"250px"}}> {innerText}</p>
                </div>
                <div>
                    <span style={{ display: 'block', height: '10px' }}></span>
                    <div className="number-pad" style={{maxWidth: "250px", marginLeft: "30px"}}>
                        <button
                            id="C"
                            className="number-button"
                            onClick={bckButton}
                        >
                            {'<'}
                        </button>   

                        <button
                            id="OK"
                            className="number-button-ok"
                            onClick={okClicked}
                            style={{paddingLeft: "15px"}}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>);
};

export default ReservationExtraText;
