import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const ContactFormUpdate = ({contactDataParent, setContactDataParent, handleUpdateContact, pressBack}) => {
    const { t } = useTranslation();
    
    const initialContactData = {
        name: '',
        surname: '',
        email: '',
        phone_prefix: '+38591', // Default prefix
        phone_number: '', // User-entered number
        custom_phone_prefix: '',
    };

    const [contactData, setContactData] = useState(initialContactData);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (contactDataParent && Object.keys(contactDataParent).length > 0) {
            setContactData(contactDataParent);
        } else {
            setContactData(initialContactData);
        }
    }, [contactDataParent]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContactData((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'phone_prefix' && value !== 'other' && { custom_phone_prefix: '' })
        }));

        setContactDataParent((prevData) => ({
            ...prevData,
            [name]: value,
            ...(name === 'phone_prefix' && value !== 'other' && { custom_phone_prefix: '' })
        }));
    };

    const validateForm = () => {
        let tempErrors = {};
        if (!contactData.name.trim()) tempErrors.name = `${t('error_name_is_obligatory')}`;
        if (!contactData.surname.trim()) tempErrors.surname = `${t('error_surname_is_obligatory')}`;
        if (!contactData.phone_number.trim()) tempErrors.phone_number = `${t('error_phone_is_obligatory')}`;

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleCreateContactLocal = () => {
        if (!validateForm()) return;
        handleUpdateContact();
    }

    const options = [
        { value: '+38591', label: '+38591' },
        { value: '+38592', label: '+38592' },
        { value: '+38593', label: '+38593' },
        { value: '+38594', label: '+38594' },
        { value: '+38595', label: '+38595' },
        { value: '+38596', label: '+38596' },
        { value: '+38597', label: '+38597' },
        { value: '+38598', label: '+38598' },
        { value: '+38599', label: '+38599' },
        { value: 'other', label: 'Other' }
    ];

    return (
        <div className="number-pad" style={{marginLeft: "130px"}}> 
            <span style={{ display: 'block', width: '260px' }}></span>
            <div style={{maxWidth: "250px", marginLeft: "-175px"}}>
                <div>
                    <span style={{ display: 'block', height: '10px' }}></span>
                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="name">{t('name_label')}</label>
                        <input
                            className="contact-input"
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Ime"
                            value={contactData.name}
                            onChange={handleChange}
                            required
                        />
                        {errors.name && <div className="contact-validation-message">{errors.name}</div>}
                    </div>
                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="surname">{t('surname_label')}</label>
                        <input
                            className="contact-input"
                            type="text"
                            id="surname"
                            name="surname"
                            placeholder="Prezime"
                            value={contactData.surname}
                            onChange={handleChange}
                            required
                        />
                        {errors.surname && <div className="contact-validation-message">{errors.surname}</div>}
                    </div>
                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="email">Email:</label>
                        <input
                            className="contact-input"
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            value={contactData.email}
                            onChange={handleChange}
                            required
                        />
                        {errors.email && <div className="contact-validation-message">{errors.email}</div>}
                    </div>

                    <div className="contact-input-field">
                        <label className="contact-input-field-label" htmlFor="phone_number">{t('phone_number_label')}</label>
                        <div style={{ display: 'flex' }}>
                            {contactData.phone_prefix !== 'other' && (
                                <select
                                    className="contact-input dropdown-custom"
                                    id="phone_prefix"
                                    name="phone_prefix"
                                    value={contactData.phone_prefix}
                                    onChange={(e) => {
                                        handleChange(e);
                                    }}
                                    required
                                    style={{ marginRight: '8px', width: "30%" }}
                                >
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {contactData.phone_prefix === 'other' && (
                                <input
                                    className="contact-input dropdown-custom"
                                    type="text"
                                    id="custom_phone_prefix"
                                    name="custom_phone_prefix"
                                    placeholder="Prefix"
                                    value={contactData.custom_phone_prefix}
                                    onChange={handleChange}
                                    required
                                    style={{ marginRight: '8px', width: "30%" }}
                                />
                            )}
                            <input
                                className="contact-input"
                                type="text"
                                id="phone_number"
                                name="phone_number"
                                placeholder={t('phone_number_label')}
                                value={contactData.phone_number}
                                onChange={handleChange}
                                style={{ width: "46.5%" }}
                                required
                            />
                        </div>
                        {errors.phone_number && <div className="validation-message">{errors.phone_number}</div>}
                    </div>
                </div>
                <div>
                    <span style={{ display: 'block', height: '10px' }}></span>
                    <div className="number-pad" style={{maxWidth: "250px", marginLeft: "30px"}}>
                        <button
                            id="C"
                            className="number-button"
                            onClick={pressBack}
                        >
                            {'<'}
                        </button>   

                        <button
                            id="OK"
                            className="number-button-ok"
                            onClick={handleCreateContactLocal}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactFormUpdate;
