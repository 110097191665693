import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const getWorkdaysAndTime = createAsyncThunk(
    'reservations/company/workweek',
    async (data, thunkAPI,) => {
        const body = JSON.stringify({
            data
        });

        try {
            const res = await fetch('/api/reservations/company/workweek', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else if (res.status === 401) {

                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());

                //Pokušaj opet

                const res2 = await fetch('/api/reservations/company/workweek', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/JSON',
                        'Content-Type': 'application/json'
                    },
                    body,
                });

                const data2 = await res2.json();

                if (res2.status === 200) {
                } else {
                    console.log("GetWorkdaysAndTime 2 != 200 -----------------------");
                    return thunkAPI.rejectWithValue(data2);
                }
                return data2;

                //END Pokušaj opet


            } else {
                console.log("GetWorkdaysAndTime != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("GetWorkdaysAndTime Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);