import { createSlice } from '@reduxjs/toolkit';
import { ticketChangelogView } from './ticketSys/TicketChangelogView';
import { ticketCreate } from './ticketSys/TicketCreate';
import { ticketDelete } from './ticketSys/TicketDelete';
import { ticketViewForReservation } from './ticketSys/TicketViewForReservation';
import { ticketViewForDate } from './ticketSys/TicketViewForDate';
import { ticketUpdate } from './ticketSys/TicketUpdate';
import { ticketLogView } from './ticketSys/TicketLogView';



const initialState = {
    tickets: null,
    log: null,
    loadingEQ: 0,
    loading: false,
    ticketUpdated: false,
    ticketChecked: null,

};

const ticketSlice = createSlice({
    name: 'ticket', // Adjusted the name for clarity, change as needed
    initialState,
    reducers: {

        resetTicketUpdated: state => {
            state.ticketUpdated = false;
        },
    },

    extraReducers: builder => {
        builder
            .addCase(ticketUpdate.pending, (state) => {
                state.ticketUpdated = false;
            })
            .addCase(ticketUpdate.fulfilled, (state, action) => {
                state.ticketUpdated = true;
            })
            .addCase(ticketUpdate.rejected, (state) => {
                state.ticketUpdated = false;
            })
            //.addCase(ticketChangelogView.pending, (state) => {
            //    state.loading = true;
            //})
            //.addCase(ticketChangelogView.fulfilled, (state, action) => {
            //    state.loading = false;
            //    state.log = action.payload;
            //})
            //.addCase(ticketChangelogView.rejected, (state) => {
            //    state.loading = false;
            //})
            .addCase(ticketCreate.pending, (state) => {
                state.loading = true;
                state.loadingEQ = state.loadingEQ + 1
            })
            .addCase(ticketCreate.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingEQ = state.loadingEQ - 1
            })
            .addCase(ticketCreate.rejected, (state) => {
                state.loading = false;
                state.loadingEQ = state.loadingEQ - 1
            })
            .addCase(ticketDelete.pending, (state) => {
                state.loading = true;
                state.loadingEQ = state.loadingEQ + 1
            })
            .addCase(ticketDelete.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingEQ = state.loadingEQ - 1
            })
            .addCase(ticketDelete.rejected, (state) => {
                state.loading = false;
                state.loadingEQ = state.loadingEQ - 1
            })
            .addCase(ticketViewForReservation.pending, (state) => {
                state.loading = true;
            })
            .addCase(ticketViewForReservation.fulfilled, (state, action) => {
                state.loading = false;
                state.tickets = action.payload;
            })
            .addCase(ticketViewForReservation.rejected, (state) => {
                state.loading = false;
            })
            .addCase(ticketViewForDate.pending, (state) => {
                state.loading = true;
                state.tickets = null;
            })
            .addCase(ticketViewForDate.fulfilled, (state, action) => {
                state.loading = false;
                state.tickets = action.payload;
            })
            .addCase(ticketViewForDate.rejected, (state) => {
                state.loading = false;
            })
            .addCase(ticketLogView.pending, (state) => {
                state.loading = true;
                state.ticketChecked = null;
            })
            .addCase(ticketLogView.fulfilled, (state, action) => {
                state.loading = false;
                state.ticketChecked = action.payload;
            })
            .addCase(ticketLogView.rejected, (state) => {
                state.loading = false;
            })
    }
});

export const { resetTicketUpdated } = ticketSlice.actions;
export default ticketSlice.reducer;
