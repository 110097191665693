import Layout from "components/Layout";
import { useTranslation } from 'react-i18next';

const GlassCard = ({ formData, showAltDate=false, altDate=null }) => {
  const { t } = useTranslation();

    const showDate = () =>{   

      if(showAltDate && altDate){
        return (<>{altDate}</>);

      }else{
        return (<>{formData.selected_date}</>);   

      }
    };


    return (
      <div className="gc_container" style={{ width: "100%", height: "100%", padding: "0", margin: "0", boxSizing: "border-box" }}>
          <div className="gc_box" style={{ width: "100%", height: "100%", boxSizing: "border-box" }}>
              <h1 className="gc_title">{formData.title}</h1>
              <h1 className="gc_sub_title">{formData.subtitle}</h1>
              <h1 className="gc_sub_title" style={{ fontSize: '18px' }}>{formData.subtitle2}</h1>
              <div className="gc_content" style={{ height: '200px', fontSize: '12px', borderTop: "1px solid white", borderBottom: "1px solid white", paddingTop: '0', boxSizing: "border-box" }}>
                  {formData.items.map((item, index) => (
                      <li key={index}>{item.item_name}</li>
                  ))}
              </div>
              <div>
                  <strong>{t('from_label')} {formData.time_from}h - {formData.time_to}h</strong>
                  <p>{t('price')} {formData.price}€</p>
                  <span>Sonexapp</span>
              </div>
          </div>
      </div>
  );
};

export default GlassCard;