import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SidebarContext from './SidebarContext';
import { sidebarConfig } from './SidebarConfig'; // Adjust the path as needed
import './Sidebar.css';
import { SonexLogoSvg, CheckedCalendarSvg, EventStarSvg, CrossedSpoonSvg, SettingGearSvg, ArrowHeadUpSvg, ArrowHeadDownSvg, ProfileSvg, LogOutSvg, LeftArrowSvg, RightArrowSvg } from 'containers/Icons';
import { NavLink } from "react-router-dom";
import { logout } from "features/user";
import { useTranslation } from 'react-i18next';

const SidebarItem = ({ title, items, onSelect, index = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { isExpanded } = useContext(SidebarContext);

    const selectedView = useSelector(state => state.reservation.currentView);
    const dispatch = useDispatch();

    const handleSelect = (component) => {
        dispatch({ type: 'SET_CURRENT_VIEW', payload: component });
        onSelect(component);
    };

    const { t } = useTranslation();

    const showIcon = () => {
        if (Number(index) === 0) return <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><CheckedCalendarSvg /></label>;
        if (Number(index) === 1) return <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><EventStarSvg /></label>;
        if (Number(index) === 2) return <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><CrossedSpoonSvg /></label>;
        if (Number(index) === 3) return <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><SettingGearSvg /></label>;

        return <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><ProfileSvg /></label>;
    };

    const showExpanded = (expanded) => {
        if (expanded) return <label style={{ marginLeft: "20px", width: "25px", height: "25px" }}><ArrowHeadDownSvg /></label>;
        return <label style={{ marginLeft: "20px", width: "25px", height: "25px" }}><ArrowHeadUpSvg /></label>;
    };

    return (
        <div style={{ borderBottom: '0.5px solid gray' }}>
            <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    cursor: 'pointer',
                    padding: '10px',
                    backgroundColor: '#22264B',
                    color: 'white',
                    transition: 'background-color 0.3s ease',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '15px' }}>
                    {showIcon()}
                    {t(title)}
                </div>
                {showExpanded(isExpanded || isOpen)}
            </div>
            {isOpen ? (
                <div style={{ paddingLeft: '0px', backgroundColor: '#22264B', textAlign: "right" }}>
                    {/*{items.map((item, index) => (*/}
                    {items.map((item, index) => (
                        <div
                            key={index}
                            onClick={() => handleSelect(item.component)}
                            style={{
                                padding: '5px',
                                paddingRight: "25px",
                                color: 'white',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                                transition: 'background-color 0.3s ease',
                                backgroundColor: selectedView === item.component ? '#F8F8F8' : 'inherit',
                                color: selectedView === item.component ? 'black' : 'white',
                                transition: 'background-color 0.3s ease'
                            }}
                        >
                            <div style={{ flex: '1' }}>{t(item.name)}</div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

const Sidebar = ({ onSelect }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [isSidebarVisible, setSidebarVisible] = useState(true);
    const [appMenu, setAppMenu] = useState([]);

    const app_menu = useSelector(state => state.user.app_menu);

    useEffect(() => {
        if (app_menu && app_menu[0].sections) {
            setAppMenu(app_menu[0].sections);
        }
    }, [app_menu]);

    const toggleSidebar = () => {
        setIsExpanded(!isExpanded);
        setSidebarVisible(!isSidebarVisible);
    };

    return (
        <SidebarContext.Provider value={{ isExpanded, toggleItem: toggleSidebar }}>
            <div className={`sidebar ${isSidebarVisible ? '' : 'hidden'}`} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <span style={{ display: 'block', height: '15px' }}></span>
                <label style={{ marginLeft: "20px", marginBottom: "20px" }}><SonexLogoSvg /></label>

                {/* {appMenu.map((section, index) => */}
                {sidebarConfig.map((section, index) => (
                    <SidebarItem key={index} title={section.title} items={section.items} onSelect={onSelect} index={index} />
                ))}

                <div style={{ marginTop: 'auto', width: '100%', backgroundColor: '#22264B', borderTop: '0.5px solid gray' }}>
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '10px',
                            backgroundColor: '#22264B',
                            color: 'white',
                            transition: 'background-color 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>
                            <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><ProfileSvg /></label>
                            <NavLink className="nav-link" to="/dashboard">
                                {t("my_profile")}
                            </NavLink>
                        </div>
                    </div>
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '10px',
                            backgroundColor: '#22264B',
                            color: 'white',
                            transition: 'background-color 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderTop: '0.5px solid gray'
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}>
                            <label style={{ marginRight: "20px", width: "25px", height: "25px", marginBottom: "5px" }}><LogOutSvg /></label>
                            <NavLink className="nav-link" to="/" onClick={() => dispatch(logout())}>
                                {t("log_off")}
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="sidebar-toggle" onClick={toggleSidebar} style={{ display: 'block', top: '0px', borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                    {isSidebarVisible ? <label style={{ marginBottom: "20px" }}><LeftArrowSvg /></label> : <label style={{ marginBottom: "20px" }}><RightArrowSvg /></label>}
                </div>
            </div>
        </SidebarContext.Provider>
    );
};

export default Sidebar;
