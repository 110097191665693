import { createAsyncThunk } from '@reduxjs/toolkit';
import { refreshAuth } from 'features/user';

export const cardMenuViewAllPublic = createAsyncThunk(
    'users/company/cardmenu/all/public',
    async ({ company_id }, thunkAPI,) => {
        const body = JSON.stringify({ company_id });

        try {
            const res = await fetch('/api/users/company/cardmenu/all/public', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });


            const data = await res.json();

            if (res.status === 401) {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
            }

            if (res.status === 200) {
                return data;

            } else {
                console.log("cardmenu/all/public != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("cardmenu/all/public Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);