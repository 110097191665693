export const receptionSidebarConfig = [
    {
        title: "reservations",
        items: [
            { name: "reservations_overview", component: "ReservationsOverview" },
        ],
    },

    {
        title: "menus",
        items: [
            { name: "menu_overview", component: "MenusOverviewUneditable" },
        ],
    }
];