import React from 'react';
import { useTranslation } from 'react-i18next';

const PermissionDisplay = ({ data }) => {
  // Check if the data is null or not an object
  const { t } = useTranslation();
  if (!data || typeof data !== 'object') {
    return null; // Do not render anything if the data is invalid
  }

  // Destructure the data object for easier access to each property
  const { manager, company_name, company_type, receptionist,  gatekeeper} = data;

  return (
    <div>
      <h5>{t('status_label')}</h5>
      <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '10px' }}>
          <thead style={{ fontSize: '13px', backgroundColor: '#f2f2f2' }}>
            <tr>
              <th>{t('name_of')}</th>
              <th>{t('manager')}</th>
              <th>{t('reception')}</th>
              <th>{t('tickets')}</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: '13px', backgroundColor: '#ffffff' }}>
            <tr>
              <td>{company_name}.{company_type}</td>
              <td>
                <input
                  type="checkbox"
                  checked={manager}
                  disabled={true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={receptionist}
                  disabled={true}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={gatekeeper}
                  disabled={true}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <span style={{ display: 'block', height: '20px' }}></span>
    </div>
  );
};

export default PermissionDisplay;