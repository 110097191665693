import { createSlice } from '@reduxjs/toolkit';
import { galleryCreate } from './gallery/GalleryCreate';
import { galleryView } from './gallery/GalleryView';
import { galleryDelete } from './gallery/GalleryDelete';
import { galleryPatch } from './gallery/GalleryPatch';
import { frontpageCreate } from './frontpage/FrontpageCreate';
import { frontpageView } from './frontpage/FrontpageView';
import { frontpageUpdate } from './frontpage/FrontpageUpdate';
import { frontpageViewAll } from './frontpage/FrontpageViewAll';
import { userRequestCreate } from './frontpage/UserRequestCreate';
import { userRequestViewForDate } from './frontpage/UserRequestViewForDate';


const initialState = {
    galleryChanges: false,
    galleryLoading: false,
    galleryList: null,
    galleryChangesN: 0,

    frontpageView: null,
    frontpageChanged: false,
    frontpages: null,
    frontpageViewNotExist: false,

    user_request: false,
    user_requests_list: [],
    user_loading: false,
    user_error: false,

};

const frontpageSlice = createSlice({
    name: 'frontpage', // Adjusted the name for clarity, change as needed
    initialState,

    reducers: {

    },

    extraReducers: builder => {
        builder
            .addCase(userRequestViewForDate.pending, (state) => {
                state.user_loading = true;
                state.user_requests_list = [];
            })
            .addCase(userRequestViewForDate.fulfilled, (state, action) => {
                state.user_loading = false;
                state.user_requests_list = action.payload;
            })
            .addCase(userRequestViewForDate.rejected, (state) => {
                state.user_loading = false;
                state.user_error = true;

            })
            .addCase(userRequestCreate.pending, (state) => {
                state.user_loading = true;
            })
            .addCase(userRequestCreate.fulfilled, (state, action) => {
                state.user_loading = false;
                state.user_request = true;
            })
            .addCase(userRequestCreate.rejected, (state) => {
                state.user_loading = false;
                state.user_error = true;
            })

            .addCase(galleryView.pending, (state) => {
                state.galleryLoading = true;
                state.galleryChanges = false;
                state.galleryChangesN = 0;
            })
            .addCase(galleryView.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.galleryList = action.payload;
            })
            .addCase(galleryView.rejected, (state) => {
                state.galleryLoading = false;
                state.reservationError = true;
            })

            .addCase(galleryCreate.pending, (state) => {
                state.galleryLoading = true;
                state.galleryChangesN = state.galleryChangesN + 1;
            })
            .addCase(galleryCreate.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.galleryChanges = true;
                state.galleryChangesN = state.galleryChangesN - 1
            })
            .addCase(galleryCreate.rejected, (state) => {
                state.galleryLoading = false;
                state.reservationError = true;
                state.galleryChangesN = state.galleryChangesN - 1
            })

            .addCase(galleryDelete.pending, (state) => {
                state.galleryLoading = true;
                state.galleryChangesN = state.galleryChangesN + 1;
            })
            .addCase(galleryDelete.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.galleryChanges = true;
                state.galleryChangesN = state.galleryChangesN - 1
            })
            .addCase(galleryDelete.rejected, (state) => {
                state.galleryLoading = false;
                state.reservationError = true;
                state.galleryChangesN = state.galleryChangesN - 1
            })
            .addCase(galleryPatch.pending, (state) => {
                state.galleryLoading = true;
                state.galleryChangesN = state.galleryChangesN + 1;
            })
            .addCase(galleryPatch.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.galleryChanges = true;
                state.galleryChangesN = state.galleryChangesN - 1
            })
            .addCase(galleryPatch.rejected, (state) => {
                state.galleryLoading = false;
                state.reservationError = true;
                state.galleryChangesN = state.galleryChangesN - 1
            })

            .addCase(frontpageView.pending, (state) => {
                state.galleryLoading = true;
                state.frontpageChanged = false;
                state.frontpageViewNotExist = false;
            })
            .addCase(frontpageView.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.frontpageView = action.payload;
            })
            .addCase(frontpageView.rejected, (state) => {
                state.galleryLoading = false;
                state.frontpageViewNotExist = true;
            })

            .addCase(frontpageCreate.pending, (state) => {
                state.galleryLoading = true;
            })
            .addCase(frontpageCreate.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.frontpageChanged = true;

            })
            .addCase(frontpageCreate.rejected, (state) => {
                state.galleryLoading = false;
            })

            .addCase(frontpageUpdate.pending, (state) => {
                state.galleryLoading = true;
            })
            .addCase(frontpageUpdate.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.frontpageChanged = true;

            })
            .addCase(frontpageUpdate.rejected, (state) => {
                state.galleryLoading = false;
            })

            .addCase(frontpageViewAll.pending, (state) => {
                state.galleryLoading = true;
            })
            .addCase(frontpageViewAll.fulfilled, (state, action) => {
                state.galleryLoading = false;
                state.frontpages = action;

            })
            .addCase(frontpageViewAll.rejected, (state) => {
                state.galleryLoading = false;
            })

            ;
    }
});

// Export actions from this slice if you have any. For example:
//export const { resetCreatedReservation, resetContactId } = frontpageSlice.actions;

// Export the reducer
export default frontpageSlice.reducer;
