import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { registerCompany } from "features/user";
import { useTranslation } from 'react-i18next';

const CompanyInfoForm = ({ company, closeModal }) => {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const initializeFormData = (companyData) => ({
        name: companyData?.name ?? '',
        company_type: companyData?.company_type ?? 'LLC',
        industry: companyData?.industry ?? '',
        company_size: companyData?.company_size ?? '',
        year_founded: companyData?.year_founded?.toString() ?? '', // Convert to string if needed
        about_us: companyData?.about_us ?? '',
        tax_id: companyData?.tax_id ?? '',
        address: companyData?.address ?? '',
        email: companyData?.email ?? '',
        gsm: companyData?.gsm ?? '',

  });

      
  const [formData, setFormData] = useState(initializeFormData(company));
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Determine if the form should display the submit button
  const [showSubmitButton, setShowSubmitButton] = useState(Object.keys(company || {}).length === 0);
  useEffect(() => {
    setShowSubmitButton(Object.keys(company || {}).length === 0);
    setFormData(initializeFormData(company));
    
  }, [company]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable form elements
    console.log("formData: " + JSON.stringify(formData));

    dispatch(registerCompany(formData))
      .unwrap()
      .then((response) => {
        // Handle success
        console.log('Company registered successfully', response);
      })
      .catch((error) => {
        // Handle error
        console.error('Failed to register company', error);
      })
      .finally(() => setIsSubmitting(false)); // Re-enable form after submission

      closeModal(false); //Zatvori modal iz sebe
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <p className="title">{t('company_data')}</p>
        
        <div className="input-field">
          <input
            className="input"
            type="text"
            name="name"
            onChange={handleChange}
            value={formData.name}
            disabled={!showSubmitButton || isSubmitting}
            required
          />
          <label className="label" htmlFor="name">{t('company_name_label')}</label>
        </div>

        <div className="input-field">
          <select
            className="input"
            name="company_type"
            onChange={handleChange}
            value={formData.company_type}
            disabled={!showSubmitButton || isSubmitting}
            required
          >
            <option value=""></option> 
            <option value="LLC">{t('llc')}</option>
            <option value="CORP">{t('corporation')}</option>
            <option value="PARTNERSHIP">{t('corporation')}</option>
            <option value="PRIVATE">{t('private_person')}</option>
          </select>
          <label className="label" htmlFor="company_type">{t('company_type_label')} </label>
        </div>

        <div className="input-field">
            <select
            className="input"
            name="industry"
            onChange={handleChange}
            value={formData.industry}
            disabled={!showSubmitButton || isSubmitting}
            required
            >
            <option value=""></option>    
            <option value="UGOSTITELJ">{t('hospitality')}</option>
            <option value="USLUGE">{t('services')}</option>
            <option value="OSTALO">{t('other')}</option>
            disabled={!showSubmitButton || isSubmitting}
          </select>
          <label className="label" htmlFor="industry">{t('profession_label')}</label>
        </div>

        <div className="input-field">
          
          <select
            className="input"
            name="company_size"
            onChange={handleChange}
            value={formData.company_size}
            disabled={!showSubmitButton || isSubmitting}
            required
            >
            <option value=""></option>
            <option value="1-10">1-10</option>
            <option value="11-50">11-50</option>
            <option value="51-200">51-200</option>
          </select>
          <label className="label" htmlFor="company_size">{t('num_of_employees_label')}</label>
        </div>

        <div className="input-field">
    <select
      className="input"
      name="year_founded"
      onChange={handleChange}
      value={formData.year_founded}
      disabled={!showSubmitButton || isSubmitting}
      required
    >
      {/* Generate year options */}
      <option value="">{t('select_year')}</option>
      {[...Array(new Date().getFullYear() - 1899).keys()] // Create an array of years from 1900 to this year
        .map(year => new Date().getFullYear() - year) // Map to actual years
        .map(year => (
          <option key={year} value={year}>{year}</option>
      ))}
    </select>
    <label className="label" htmlFor="year_founded">{t('year_of_founding_label')} </label>
    </div>

        <div className="input-field">
          <textarea
            className="input"
            name="about_us"
            onChange={handleChange}
            value={formData.about_us}
            disabled={!showSubmitButton || isSubmitting}
            required
            style={{ height: '100px', paddingTop: "10px" }} // Adjust height as needed
          />
          <label className="label" htmlFor="about_us">{t('about_us_label')}</label>
        </div>

        <div className="input-field">
          <input
            className="input"
            type="text"
            name="tax_id"
            onChange={handleChange}
            value={formData.tax_id}
            disabled={!showSubmitButton || isSubmitting}
            required
          />
          <label className="label" htmlFor="tax_id">{t('tax_code_label')}</label>
        </div>

        <div className="input-field">
          <textarea
            className="input"
            name="address"
            onChange={handleChange}
            value={formData.address}
            disabled={!showSubmitButton || isSubmitting}
            required
            style={{ height: '100px', paddingTop: "10px" }} // Adjust height as needed
          />
          <label className="label" htmlFor="address">{t('address_label')}</label>
        </div>

        <div className="input-field">
          <input
            className="input"
            type="email"
            name="email"
            onChange={handleChange}
            value={formData.email}
            disabled={!showSubmitButton || isSubmitting}
            required
          />
          <label className="label" htmlFor="tax_id">{t('email_label')}</label>
        </div>

        <div className="input-field">
          <input
            className="input"
            type="tel"
            name="gsm"
            onChange={handleChange}
            value={formData.gsm}
            disabled={!showSubmitButton || isSubmitting}
            required
          />
          <label className="label" htmlFor="tax_id">{t('phone_num_label')}</label>
        </div>

        {showSubmitButton && (
          <button className="submit-btn">{t('accept')}</button>
        )}
      </form>
      <span style={{ display: 'block', height: '40px' }}></span>
    </div>
  );
};

export default CompanyInfoForm;



    